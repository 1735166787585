// import { LineProgressBar } from '@frogress/line'
import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { DivContain, Span, Remaining, Used } from './style'
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import './style.css'

class AddressPower extends Component {
  render() {
    let { tronpowerused, tronpower } = this.props

    return (
      <DivContain>
        <Span>
          Winups Power
          <Remaining>
            Remaining:
            <Used>
              <NumberFormat
                displayType={'text'}
                thousandSeparator={true}
                value={tronpower - tronpowerused}
              />
            </Used>
            /
            <NumberFormat displayType={'text'} thousandSeparator={true} value={tronpower} />
          </Remaining>
        </Span>
        <Progress
          status="success"
          theme={{
            success: {
              symbol: '‍',
              color: '#FCB040',
            },
          }}
          percent={tronpower ? ((tronpower - tronpowerused) / tronpower) * 100 : 0}
        />
        {/* <LineProgressBar
          percent={tronpower ? ((tronpower - tronpowerused) / tronpower) * 100 : 0}
          progressColor="linear-gradient(to right, #78abe9, #74dad8, #ec7cac)"
        /> */}
      </DivContain>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tronpower: state.address.tronpower === undefined ? 0 : state.address.tronpower,
    tronpowerused: state.address.tronpowerused === undefined ? 0 : state.address.tronpowerused,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AddressPower)
