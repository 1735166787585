import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { TableRow, Td } from './style'

class AddressOwnerBalance extends Component {
  render() {
    let { trxbalance } = this.props

    return (
      <TableRow>
        <Td>
          <span>Owner Balance</span>:
        </Td>
        <td>
          <div>
            <p>
              <NumberFormat
                displayType={'text'}
                thousandSeparator={true}
                value={
                  (trxbalance.available === undefined ? 0 : trxbalance.available) /
                    Math.pow(10, 6) +
                  (trxbalance.stake === undefined ? 0 : trxbalance.stake) / Math.pow(10, 6)
                }
              />
              {' WIN'}
            </p>
            <span style={{ display: 'flex' }}>
              <span>
                {'(Available: '}
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={
                    (trxbalance.available === undefined ? 0 : trxbalance.available) /
                    Math.pow(10, 6)
                  }
                />
                {' WIN'}
              </span>
              <span style={{ marginLeft: '20px' }}>
                {'Stake: '}
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={(trxbalance.stake === undefined ? 0 : trxbalance.stake) / Math.pow(10, 6)}
                />
                {' WIN)'}
              </span>
            </span>
          </div>
        </td>
      </TableRow>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    trxbalance: state.address.trxbalance === undefined ? 0 : state.address.trxbalance,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AddressOwnerBalance)
