import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableRow, Td } from './style'
import { format } from 'date-fns-tz'

class AddressCreated extends Component {
  render() {
    let { created } = this.props

    return (
      <TableRow>
        <Td>
          <span>Created</span>:
        </Td>
        <td>
          <div>
            <span>
              {created === 0 ? '–' : format(new Date(created), 'yyyy-MM-dd HH:mm:ss').toString()}
            </span>
          </div>
        </td>
      </TableRow>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    created: state.address.created === undefined ? 0 : Number(state.address.created),
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AddressCreated)
