import React from 'react'
import { Table, Pagination, Badge } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  loadAddresses,
  updatePageAddresses,
  updatePageAddressesLimit,
} from '../../actions/addresses'
import styled from 'styled-components'
import PageHeader from '../partials/pageHeader'
import NumberFormat from 'react-number-format'

import '../../styles/account.less'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'

const StyledLink = styled(Link)``
const Wrapper = styled.div`
  margin: 5px;
`
const Pagin = styled.div`
  margin-top: 15px;
  text-align: right !important;
`
const BadgeGreen = styled(Badge)`
  .ant-badge-count {
    background-color: #e1f3e0;
    color: black;
  }
`

const BadgeRed = styled(Badge)`
  .ant-badge-count {
    background-color: #ff7677;
    color: black;
  }
`

class AddressesTable extends React.Component {
  componentDidMount() {
    this.props.urlAtNotPrivFunc()
    var { pageAddresses } = this.props
    this.props.loadAddresses(pageAddresses.current_page, pageAddresses.page_limit)
  }

  onChange = (pageNumber, pageLimit) => {
    var { pageAddresses } = this.props
    if (pageNumber !== pageAddresses.current_page) {
      this.props.updatePageAddresses(pageNumber)
    }
    if (pageLimit !== pageAddresses.page_limit) {
      this.props.updatePageAddressesLimit(pageLimit)
    }
    this.props.loadAddresses(pageAddresses.current_page, pageLimit)
  }

  render() {
    var { addresses, pageAddresses } = this.props
    const columns = [
      {
        title: 'No.',
        key: 'no',
        render: (value, item, index) =>
          index + 1 + (pageAddresses.current_page - 1) * pageAddresses.page_limit,
        width: '10%',
      },
      {
        title: 'Account',
        key: 'account',
        width: '40%',
        render: (record) => {
          return (
            <StyledLink key={record.address} to={'/accounts/' + record.address}>
              {record.address}
            </StyledLink>
          )
        },
      },
      {
        title: 'Total WIN balance',
        key: 'trxbalance',
        width: '30%',
        render: (record) => (
          <p>
            <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              value={
                (record.trxbalance.available === undefined ? 0 : record.trxbalance.available) /
                  1000000 +
                (record.trxbalance.stake === undefined ? 0 : record.trxbalance.stake) / 1000000
              }
            />
            {' WIN'}
          </p>
        ),
      },
      {
        title: 'Transactions count',
        key: 'transaction',
        width: '20%',
        render: (record) => (
          <p>
            <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              value={
                (record.transaction.from === undefined ? 0 : record.transaction.from) +
                (record.transaction.to === undefined ? 0 : record.transaction.to)
              }
            />
          </p>
        ),
      },
    ]
    return (
      <Wrapper>
        <PageHeader>List of Accounts</PageHeader>
        <Table
          columns={columns}
          dataSource={addresses}
          rowKey="account"
          scroll={{ x: 700 }}
          sticky
          pagination={false}
          loading={addresses && addresses.length === 0 ? true : false}
          locale={{ emptyText: 'Loading' }}
          className="account-list"
        />
        <Pagin>
          <Pagination
            current={pageAddresses.start_page}
            total={pageAddresses.total_items}
            onChange={this.onChange}
            showSizeChanger
            showQuickJumper
            className="account-pagi"
            pageSize={pageAddresses.page_limit}
          />
        </Pagin>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    addresses: state.addresses,
    pageAddresses: state.pageAddresses,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updatePageAddresses: (page) => {
      dispatch(updatePageAddresses(page))
    },
    updatePageAddressesLimit: (limit) => {
      dispatch(updatePageAddressesLimit(limit))
    },
    loadAddresses: (offset, limit) => {
      dispatch(loadAddresses(offset, limit))
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  AddressesTable
)
