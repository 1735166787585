import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import TransTable from './transactionTable'
import TokenBalance from './tokenBalance'
import CodeMenu from './codeMenu'
import TokenHolderTable from '../tokenHolder'

class SwitchMenu extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Switch>
        <Route
          path={
            '/' + window.location.pathname.split('/')[1] + '/' + this.props.address + '/contract'
          }
          render={() => <CodeMenu />}
        />
        <Route
          exact
          path={'/' + window.location.pathname.split('/')[1] + '/' + this.props.address}
          render={() => <TransTable address={this.props.address} />}
        />
        <Route
          path={
            '/' + window.location.pathname.split('/')[1] + '/' + this.props.address + '/holders'
          }
          render={() => (
            <TokenHolderTable address={this.props.address} totalSupply={this.props.totalSupply} />
          )}
        />
      </Switch>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(SwitchMenu)
