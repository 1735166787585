import { Row, Space } from 'antd'
import styled from 'styled-components'

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
`
export const Top = styled.div`
  padding-top: 25px;
  padding-bottom: 10px;
  align-items: top;
  padding-left: 25px;
  display: inline-block;
`
export const Content = styled.div`
  width: 100%;
  padding: 0 25px;
`
export const StyledRow = styled(Row)`
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 8px;
  padding-bottom: 5px;
  &:last-child {
    border-bottom: none;
  }
`
export const InfoConsume = styled.div`
  display: flex;
  margin-bottom: 8px;
`
export const StyledSpace = styled(Space)`
  width: 60%;
  @media (max-width: 768px) {
    width: 55%;
  }
`
export const ConsumeContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfoConsumeContainer = styled.div`
  display: flex;
`
export const TitleContainer = styled.div`
  height: 40px;
  align-items: flex-start;
  display: flex;
  font-size: 14px;
  width: 12%;
  justify-content: flex-start;
  padding-top: 10px;
`
export const FillContainer = styled.div`
  align-items: flex-start;
  font-size: 14px;
  width: 88%;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 3px;
`
export const RowStyled = styled.div`
  display: flex;
  border-bottom: 1px solid #eeeeee;
`
export const DetailTop = styled.h5`
  font-size: 14px;
`
export const ContentLine = styled.div`
  width: 100%;
  display: inline-block;
  padding-bottom: 7px;
`
