import React, { useEffect } from 'react'
import Oidc from 'oidc-client'
import { loginWithIDShare } from '../../actions/loginWithIdShare'
import { Spin } from 'antd'

export default function IDShareLoginCallbackPage() {
  useEffect(() => {
    new Oidc.UserManager({ response_mode: 'query' })
      .signinRedirectCallback()
      .then((res) => {
        loginWithIDShare(res)
      })
      .catch((e) => {})
  }, [])
  return (
    <div
      style={{
        margin: '5px',
        width: '85%',
        background: 'white',
        textAlign: 'center',
        lineHeight: '170px',
        borderRadius: '10px',
        marginBottom: '20px',
      }}
    >
      <Spin size="large" title="Loading..." />
    </div>
  )
}
