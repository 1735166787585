import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { TableRow, Td } from './style'

class AddressFrozenBalance extends Component {
  render() {
    let { trxbalance } = this.props

    return (
      <TableRow>
        <Td>
          <span>Frozen Balance</span>:
        </Td>
        <td>
          <div>
            <span>
              <NumberFormat
                displayType={'text'}
                thousandSeparator={true}
                value={(trxbalance.stake === undefined ? 0 : trxbalance.stake) / 1000000}
              />
              {' WIN'}
            </span>
          </div>
        </td>
      </TableRow>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    trxbalance: state.address.trxbalance === undefined ? 0 : state.address.trxbalance,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AddressFrozenBalance)
