import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableRow, Flex, BadgeGreen, BadgeRed, Th, contentStatus } from './style'
import { Popover } from 'antd'
import { getCurrentBlock } from '../../common/commonTransaction'
class BlockStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      block_height: 0,
    }
  }
  async componentDidMount() {
    var { block_num } = this.props

    var block_height = await getCurrentBlock()
    this.setState({
      block_height: block_height,
    })
    let getBlockHeight = setInterval(
      async function () {
        var block_height = await getCurrentBlock()
        this.setState({
          block_height: block_height,
        })
        if (block_height - block_num >= 21) {
          clearInterval(getBlockHeight)
        }
      }.bind(this),
      2000
    )
  }
  render() {
    var { block_num } = this.props
    var { block_height } = this.state
    return (
      <TableRow>
        <Th>
          <Flex>
            Status&nbsp;
            <Popover content={contentStatus} title=""></Popover>:
          </Flex>
        </Th>
        <td>
          {block_height - block_num >= 21 ? (
            <BadgeGreen count="CONFIRMED" />
          ) : (
            <BadgeRed count="UNCOMFIRMED" />
          )}
        </td>
      </TableRow>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    block_num: state.block.block_num,
    confirm: state.block.confirm,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(BlockStatus)
