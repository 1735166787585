import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FaExchangeAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons'
import {
  Details,
  Top,
  Content,
  StyledRow,
  StyledSpace,
  ConsumeContainer,
  InfoConsumeContainer,
  DetailTop,
} from './style'
import { Col } from 'antd'

class TransferAssetContract extends Component {
  state = {
    copied_owner: false,
    copied_to: false,
  }
  render() {
    const { asset_name, fee_limit, bandwidth_fee, consume_bandwidth, contract } =
      this.props.transaction
    const owner_address = contract.parameter.raw ? contract.parameter.raw.owner_address : '–'
    const to_address = contract.parameter.raw ? contract.parameter.raw.to_address : '–'

    const abbr = contract.parameter.raw ? contract.parameter.raw.abbr : '–'
    const bandwidth_fee_1 = bandwidth_fee / Math.pow(10, 6)
    const bandwidth_fee_2 = bandwidth_fee / Math.pow(10, 3)
    return (
      <Details className="transfer-smart-contracts">
        <Top>
          <DetailTop>
            <FaExchangeAlt />
            &nbsp; Transfer Asset Contract
          </DetailTop>
        </Top>
        <Content>
          <StyledRow>
            <Col md={6} span={24}>
              Owner Address:
            </Col>
            <Col md={18} span={24}>
              {owner_address.length < 34 ? (
                '–'
              ) : (
                <>
                  <Link to={`/accounts/${owner_address}`}>{owner_address}</Link>
                  <CopyToClipboard
                    text={owner_address}
                    onCopy={() => {
                      this.setState({ copied_owner: true })
                      setTimeout(
                        function () {
                          this.setState({ copied_owner: false })
                        }.bind(this),
                        1000
                      )
                    }}
                  >
                    {this.state.copied_owner ? (
                      <span style={{ marginLeft: '10px' }}>
                        <CheckCircleOutlined /> Copied
                      </span>
                    ) : (
                      <CopyOutlined style={{ marginLeft: '10px' }} />
                    )}
                  </CopyToClipboard>
                </>
              )}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              To Address:
            </Col>
            <Col md={18} span={24}>
              {to_address.length < 34 ? (
                '–'
              ) : (
                <>
                  <Link to={`/accounts/${to_address}`}>{to_address}</Link>
                  <CopyToClipboard
                    text={to_address}
                    onCopy={() => {
                      this.setState({ copied_to: true })
                      setTimeout(
                        function () {
                          this.setState({ copied_to: false })
                        }.bind(this),
                        1000
                      )
                    }}
                  >
                    {this.state.copied_to ? (
                      <span style={{ marginLeft: '10px' }}>
                        <CheckCircleOutlined /> Copied
                      </span>
                    ) : (
                      <CopyOutlined style={{ marginLeft: '10px' }} />
                    )}
                  </CopyToClipboard>
                </>
              )}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Fee Limit:
            </Col>
            <Col md={18} span={24}>
              <NumberFormat
                displayType={'text'}
                thousandSeparator={true}
                value={fee_limit ? 0 : fee_limit / Math.pow(10, 6)}
                suffix=" WIN"
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Asset Name:
            </Col>
            <Col md={18} span={24}>
              <Link to={`/token/${asset_name}`}>{abbr}</Link>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Consume Bandwidth:
            </Col>
            <Col md={18} span={24}>
              <ConsumeContainer>
                <InfoConsumeContainer>
                  <NumberFormat
                    displayType={'text'}
                    thousandSeparator={true}
                    value={consume_bandwidth + bandwidth_fee_2}
                    suffix=" Bandwidth"
                  />
                </InfoConsumeContainer>
                <InfoConsumeContainer>
                  <StyledSpace wrap>&#8735; Consumption of staked/free bandwidth:</StyledSpace>
                  <NumberFormat
                    displayType={'text'}
                    thousandSeparator={true}
                    value={consume_bandwidth}
                    suffix=" Bandwidth"
                  />
                </InfoConsumeContainer>
                <InfoConsumeContainer>
                  <StyledSpace wrap>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={bandwidth_fee_1}
                      prefix="&#8735; Burn "
                      suffix=" WIN for bandwidth:"
                    />
                  </StyledSpace>
                  <NumberFormat
                    displayType={'text'}
                    thousandSeparator={true}
                    value={bandwidth_fee_2}
                    suffix=" Bandwidth"
                  />
                </InfoConsumeContainer>
              </ConsumeContainer>
            </Col>
          </StyledRow>
        </Content>
      </Details>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transaction: state.transaction,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TransferAssetContract)
