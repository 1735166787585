import React from 'react'
import { Input, message } from 'antd'
import { connect } from 'react-redux'
import { search, SEARCH_REQUESTING, SEARCH_SUCCESS } from '../actions/home'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { loadAddressApi } from '../actions/address'
import { loadContractApi } from '../actions/contract'
import { getTokenRecord } from '../actions/tokenRecord'
import { loadTransactionDetails } from '../actions/transaction'
import IconSearch from '../assets/images/IconSearch.png'
import { SearchOutlined } from '@ant-design/icons'
const { Search } = Input

const PALCEHOLDER = 'Block Num/ Block Hash/ Transaction Hash/ Address/ Contract'
const SEARCHTITLE = 'Search'

const SearchWrapper = styled(Search)`
  input {
    height: 50px;
  }
  .ant-input-search-button {
    border-radius: 10px 10px 0 0;
    height: 50px;
    background: #ff9c00;
    border-color: #ffde16;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
  }
  .ant-input-affix-wrapper {
    padding: 0px;
  }
  margin-bottom: 30px;
`

class SearchBox extends React.Component {
  onSearch = (key) => {
    if (key === '') {
      message.info('Invalid input', 1)
    } else {
      this.props.search(key)
    }
  }
  render() {
    const searchState = this.props.state
    const objType = this.props.type
    const key = this.props.searchKey
    if (searchState === SEARCH_SUCCESS) {
      switch (objType) {
        case 0:
          return (
            <>
              <Redirect to={`/notfound`} />{' '}
              <SearchWrapper
                placeholder={PALCEHOLDER}
                enterButton={SEARCHTITLE}
                onSearch={this.onSearch}
                loading={SEARCH_REQUESTING === this.props.state}
              />
            </>
          )
        case 1:
          this.props.loadTransactionDetails(key)
          return (
            <>
              <Redirect to={`/transaction/${key}`} />{' '}
              <SearchWrapper
                placeholder={PALCEHOLDER}
                enterButton={SEARCHTITLE}
                onSearch={this.onSearch}
                loading={SEARCH_REQUESTING === this.props.state}
              />
            </>
          )
        case 2:
          return (
            <>
              <Redirect to={`/block/${key}`} />
              <SearchWrapper
                placeholder={PALCEHOLDER}
                enterButton={SEARCHTITLE}
                onSearch={this.onSearch}
                loading={SEARCH_REQUESTING === this.props.state}
              />
            </>
          )
        case 3:
          this.props.getTokenRecord(key)
          this.props.loadContract(key)
          return (
            <>
              <Redirect to={`/token10/${key}`} />
              <SearchWrapper
                placeholder={PALCEHOLDER}
                enterButton={SEARCHTITLE}
                onSearch={this.onSearch}
                loading={SEARCH_REQUESTING === this.props.state}
              />
            </>
          )
        case 4:
          this.props.getTokenRecord(key)
          this.props.loadContract(key)
          return (
            <>
              <Redirect to={`/token20/${key}`} />
              <SearchWrapper
                placeholder={PALCEHOLDER}
                enterButton={SEARCHTITLE}
                onSearch={this.onSearch}
                loading={SEARCH_REQUESTING === this.props.state}
              />
            </>
          )
        case 5:
          this.props.getTokenRecord(key)
          this.props.loadContract(key)
          return (
            <>
              <Redirect to={`/token721/${key}`} />
              <SearchWrapper
                placeholder={PALCEHOLDER}
                enterButton={SEARCHTITLE}
                onSearch={this.onSearch}
                loading={SEARCH_REQUESTING === this.props.state}
              />
            </>
          )
        case 6:
          this.props.loadContract(key)
          return (
            <>
              <Redirect to={`/contract/${key}`} />
              <SearchWrapper
                placeholder={PALCEHOLDER}
                enterButton={SEARCHTITLE}
                onSearch={this.onSearch}
                loading={SEARCH_REQUESTING === this.props.state}
              />
            </>
          )
        case 7:
          this.props.loadAddress(key)
          return (
            <>
              <Redirect to={`/accounts/${key}`} />
              <SearchWrapper
                placeholder={PALCEHOLDER}
                enterButton={SEARCHTITLE}
                onSearch={this.onSearch}
                loading={SEARCH_REQUESTING === this.props.state}
              />
            </>
          )
      }
    }
    return (
      <>
        <SearchWrapper
          prefix={<SearchOutlined style={{ fontSize: '170%', marginLeft: 7 }} />}
          placeholder={PALCEHOLDER}
          enterButton={SEARCHTITLE}
          onSearch={this.onSearch}
          loading={SEARCH_REQUESTING === this.props.state}
        />
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    state: state.search.state,
    type: state.search.type,
    searchKey: state.search.key,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    search: (key) => {
      dispatch(search(key))
    },
    loadAddress: (id) => {
      dispatch(loadAddressApi(id))
    },
    loadContract: (id) => {
      dispatch(loadContractApi(id))
    },
    getTokenRecord: (contract_addr) => {
      dispatch(getTokenRecord(contract_addr))
    },
    loadTransactionDetails: (txHash) => {
      dispatch(loadTransactionDetails(txHash))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SearchBox)
