import React from 'react'
import { Row, Col, Tag, Space, Button, Tooltip, Modal, Spin, notification } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import AssetManagement from '../assetManagement/index'
import NumberFormat from 'react-number-format'
// import { LineProgressBar } from '@frogress/line'
import { InfoCircleOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { getDefaulteyFromToken } from '../../common/commonTransaction'
import { loadAddressApi } from '../../actions/address'
import { getReward, withdrawReward } from '../../actions/reward'
import moment from 'moment'
import { initTransactionDetail, loadTransactionDetails } from '../../actions/transaction'
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import './style.css'
import AccountQrCode from '../accountQrCode'

const AssetsTop = styled(Row)`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #dedede;
`
const AssetsHeader = styled.b``
const AssetsBody = styled.div`
  margin-top: 20px;
`
const AvailableAssets = styled(NumberFormat)`
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
`
const TagWin = styled(Tag)`
  border-radius: 4px;
`
const TotalStakeAssets = styled.div`
  font-size: 13px;
  span {
    display: block;
  }
`
const ButtonAssets = styled(Space)`
  margin-top: 20px;
`
const Remaining = styled(Row)`
  margin-bottom: 10px;
`
const ButtonWinPower = styled(Space)`
  margin-top: 20px;
`

class Assets extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultPriv: getDefaulteyFromToken(),
      showQrCode: false,
      loading: false,
    }
  }

  componentDidMount() {
    const { address } = this.state.defaultPriv
    if (address) {
      this.props.loadAddress(address)
      this.props.getReward({ address })
    }
    this.props.initTransactionDetail()
  }

  componentDidUpdate(prevProps) {
    const { withdrawLoading, message, txid } = this.props.reward
    const { address } = this.state.defaultPriv
    const { hash, loading } = this.props.transaction
    if (txid !== prevProps.reward.txid && !withdrawLoading) {
      this.props.loadTransactionDetails(txid, 5)
    }
    if (hash !== prevProps.transaction.hash && !loading) {
      this.setState({ loading: false })
      notification.success({ message: 'Withdraw rewards successfully' })
      this.props.loadAddress(address)
      this.props.getReward({ address })
    }
    if (message !== prevProps.reward.message && !withdrawLoading) {
      notification.error({
        message,
      })
      this.setState({ loading: false })
    }
  }

  handleWithdrawwReward = () => {
    const { address, priv } = this.state.defaultPriv
    Modal.confirm({
      title: 'Are you sure you want to withdraw rewards?',
      onOk: () => {
        this.setState({ loading: true })
        this.props.withdrawReward({ owner_address: address, private_key: priv })
      },
    })
  }

  render() {
    const {
      trxbalance,
      energy_total,
      energy_used,
      band_width_total,
      band_width_used,
      tronpower,
      tronpowerused,
    } = this.props
    const { latest_withdraw_time } = this.props.address
    const { amount } = this.props.reward
    const withdrawTime = latest_withdraw_time + 86400000

    return (
      <>
        <Spin spinning={this.state.loading}>
          <AssetsTop gutter={[0, 24]}>
            <Col md={6} span={24}>
              <AssetsHeader>Available Assets</AssetsHeader>
              <AssetsBody>
                <Row align="middle">
                  <AvailableAssets
                    displayType="text"
                    thousandSeparator
                    value={(trxbalance.available ? trxbalance.available : 0) / Math.pow(10, 6)}
                  />
                  <TagWin color="#fcb040">WIN</TagWin>
                </Row>
                <TotalStakeAssets>
                  <NumberFormat
                    displayType="text"
                    prefix="Total: "
                    suffix=" WIN"
                    thousandSeparator
                    value={
                      (Number(trxbalance.available ? trxbalance.available : 0) +
                        Number(trxbalance.stake ? trxbalance.stake : 0)) /
                      Math.pow(10, 6)
                    }
                  />
                  <NumberFormat
                    displayType="text"
                    prefix="Stake: "
                    suffix=" WIN"
                    thousandSeparator
                    value={(trxbalance.stake ? trxbalance.stake : 0) / Math.pow(10, 6)}
                  />
                </TotalStakeAssets>
                <ButtonAssets>
                  <Button type="primary">
                    <Link to="/user/transferasset">Send</Link>
                  </Button>
                  <Button
                    disabled={!this.state.defaultPriv.address}
                    onClick={() => this.setState({ showQrCode: true })}
                  >
                    Receive
                  </Button>
                </ButtonAssets>
              </AssetsBody>
            </Col>
            <Col md={10} span={24}>
              <AssetsHeader>Resources</AssetsHeader>
              <AssetsBody>
                <Row align="bottom" gutter={16}>
                  <Col span={16}>
                    <Remaining justify="space-between">
                      <div>
                        <b>
                          <NumberFormat
                            displayType="text"
                            thousandSeparator
                            value={energy_total - energy_used}
                          />
                        </b>
                        /<NumberFormat displayType="text" thousandSeparator value={energy_total} />
                      </div>
                      <div>
                        Energy&nbsp;
                        <Tooltip title="On WIN, the creation and triggering of smart contracts consume Energy. Users can gain Energy through staking WIN.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    </Remaining>
                    <Progress
                      status="success"
                      theme={{
                        success: {
                          symbol: '‍',
                          color: '#FCB040',
                        },
                      }}
                      percent={
                        energy_total ? ((energy_total - energy_used) / energy_total) * 100 : 0
                      }
                    />
                    {/* <LineProgressBar
                      percent={
                        energy_total ? ((energy_total - energy_used) / energy_total) * 100 : 0
                      }
                      rounded={4}
                      height={8}
                      progressColor="#FCB040"
                    /> */}
                  </Col>
                  <Button>
                    <Link to="/user/freeze-balance">Obtain</Link>
                  </Button>
                </Row>
                <br />
                <Row align="bottom" gutter={16}>
                  <Col span={16}>
                    <Remaining justify="space-between">
                      <div>
                        <b>
                          <NumberFormat
                            displayType="text"
                            thousandSeparator
                            value={band_width_total - band_width_used}
                          />
                        </b>
                        <NumberFormat
                          displayType="text"
                          prefix="/"
                          thousandSeparator
                          value={band_width_total}
                        />
                      </div>
                      <div>
                        Bandwidth&nbsp;
                        <Tooltip title="On WIN, bandwidths will be consumed with any transaction but query 1500 bandwidths will be given to each activated account every day by the system. Users can earn bandwidths by staking WIN.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    </Remaining>
                    <Progress
                      status="success"
                      theme={{
                        success: {
                          symbol: '‍',
                          color: '#FCB040',
                        },
                      }}
                      percent={
                        band_width_total
                          ? ((band_width_total - band_width_used) / band_width_total) * 100
                          : 0
                      }
                    />
                    {/* <LineProgressBar
                      percent={
                        band_width_total
                          ? ((band_width_total - band_width_used) / band_width_total) * 100
                          : 0
                      }
                      rounded={4}
                      height={8}
                      progressColor="#FCB040"
                    /> */}
                  </Col>
                  <Button>
                    <Link to="/user/freeze-balance">Rent</Link>
                  </Button>
                </Row>
              </AssetsBody>
            </Col>
            <Col md={8} span={24}>
              <AssetsHeader>Win Power</AssetsHeader>
              <AssetsBody>
                <NumberFormat
                  displayType="text"
                  prefix="Unwithdrawn Rewards: "
                  suffix=" WIN"
                  thousandSeparator
                  value={amount / Math.pow(10, 6)}
                />
              </AssetsBody>
              <AssetsBody>
                <Row align="middle" gutter={16}>
                  <Col span={24}>
                    <Remaining justify="space-between">
                      <div>
                        <b>
                          <NumberFormat
                            displayType="text"
                            thousandSeparator
                            value={tronpower - tronpowerused}
                          />
                        </b>
                        /<NumberFormat displayType="text" thousandSeparator value={tronpower} />
                      </div>
                      <div>
                        Win Power&nbsp;
                        <Tooltip title="Win Power (TP) is used for voting for WIN SRs. Users can gain TP through staking WIN.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    </Remaining>
                    <Progress
                      status="success"
                      theme={{
                        success: {
                          symbol: '‍',
                          color: '#FCB040',
                        },
                      }}
                      percent={tronpower ? ((tronpower - tronpowerused) / tronpower) * 100 : 0}
                    />
                    {/* <LineProgressBar
                      percent={tronpower ? ((tronpower - tronpowerused) / tronpower) * 100 : 0}
                      rounded={4}
                      height={8}
                      progressColor="#FCB040"
                    /> */}
                  </Col>
                </Row>
                <ButtonWinPower wrap>
                  <Button>
                    <Link to="/user/freeze-balance">Get Votes</Link>
                  </Button>
                  <Button>
                    <Link to="votes">Votes</Link>
                  </Button>
                  <Tooltip
                    title={
                      amount <= 0
                        ? 'No rewards available'
                        : withdrawTime > Date.now()
                        ? `Earliest Withdraw Time(Local): ${moment(withdrawTime).format(
                            'YYYY-MM-DD HH:mm:ss'
                          )}`
                        : ''
                    }
                  >
                    <Button
                      type="primary"
                      disabled={amount <= 0 || withdrawTime > Date.now()}
                      onClick={this.handleWithdrawwReward}
                    >
                      Withdraw
                    </Button>
                  </Tooltip>
                </ButtonWinPower>
              </AssetsBody>
            </Col>
          </AssetsTop>
        </Spin>
        <AssetManagement />
        <AccountQrCode
          showQrCode={this.state.showQrCode}
          hideQrCode={() => this.setState({ showQrCode: false })}
          address={this.state.defaultPriv.address}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    address: state.address,
    trxbalance: state.address.trxbalance ? state.address.trxbalance : 0,
    energy_total: state.address.energy_total ? state.address.energy_total : 0,
    energy_used: state.address.energy_used ? state.address.energy_used : 0,
    band_width_total: state.address.band_width_total ? state.address.band_width_total : 0,
    band_width_used: state.address.band_width_used ? state.address.band_width_used : 0,
    tronpower: state.address.tronpower ? state.address.tronpower : 0,
    tronpowerused: state.address.tronpowerused ? state.address.tronpowerused : 0,
    reward: state.reward,
    transaction: state.transaction,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadAddress: (address) => {
      dispatch(loadAddressApi(address))
    },
    getReward: (params) => {
      dispatch(getReward(params))
    },
    withdrawReward: (params) => {
      dispatch(withdrawReward(params))
    },
    initTransactionDetail: () => {
      dispatch(initTransactionDetail())
    },
    loadTransactionDetails: (txid, timeToTry) => {
      dispatch(loadTransactionDetails(txid, timeToTry))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Assets)
