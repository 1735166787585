import {
  TRANSFER_WRC20_UPDATE,
  TRANSFER_WRC20_INIT,
  TRANSFER_WRC20_REQUESTING,
  TRANSFER_WRC20_NONE,
  TRANSFER_WRC20_SUCCESS,
  TRANSFER_WRC20_FAIL,
} from '../actions/transferWRC20'

export function transferWRC20Reducer(state = defaultWRC20, action) {
  switch (action.type) {
    case TRANSFER_WRC20_NONE:
      state = defaultWRC20
      break
    case TRANSFER_WRC20_UPDATE:
      state = action.payload
      break
    case TRANSFER_WRC20_INIT:
      state = defaultWRC20
      break
    case TRANSFER_WRC20_REQUESTING:
      state = {
        ...state,
        status: 'requesting',
      }
      break
    case TRANSFER_WRC20_SUCCESS:
      state = {
        ...state,
        status: 'success',
        message: 'Transfer succeeded',
        tranID: action.payload.tranID,
      }
      break
    case TRANSFER_WRC20_FAIL:
      state = { ...state, status: 'fail', message: action.payload }
      break
    default:
      break
  }
  return state
}

var dataWRC20 = {
  status: '',
  message: '',
  data: {},
}
const defaultWRC20 = dataWRC20
