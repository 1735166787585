import React from 'react'
import { Table, Pagination, Row } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  loadAllTokens,
  updatePageAllTokens,
  updatePageAllTokensLimit,
} from '../../actions/allTokens'
import styled from 'styled-components'
import PageHeader from '../partials/pageHeader'
import NumberFormat from 'react-number-format'

const Wrapper = styled.div`
  margin: 5px;
`
const Pagin = styled.div`
  margin-top: 15px;
  text-align: right !important;
`
const TokenIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border-radius: 50%;
`

class AllTokensTable extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { pageAllTokens } = this.props
    this.props.loadAllTokens(pageAllTokens.current_page, pageAllTokens.page_limit)
  }

  onChange = (pageNumber, pageLimit) => {
    this.props.updatePageAllTokens(pageNumber)
    var { pageAllTokens } = this.props
    if (pageLimit !== pageAllTokens.page_limit) {
      this.props.updatePageAllTokensLimit(pageLimit)
    }
    this.props.loadAllTokens(pageAllTokens.current_page, pageAllTokens.page_limit)
  }

  mapTokenRecordType = (type) => {
    switch (type) {
      case 'WRC10':
        return 'token10'
      case 'WRC20':
        return 'token20'
      case 'WRC721':
        return 'token721'
      default:
        return 'token'
    }
  }

  render() {
    var { allTokens, pageAllTokens } = this.props
    const columns = [
      {
        title: 'No.',
        render: (_, __, index) =>
          index + 1 + (pageAllTokens.current_page - 1) * pageAllTokens.page_limit,
        width: 70,
      },
      {
        title: 'ID',
        render: (record) => (
          <Link to={`${this.mapTokenRecordType(record.token_record_type)}/${record._id}`}>
            {record._id?.length > 10
              ? record._id?.substring(0, 3) +
                '...' +
                record._id?.substring(record._id?.length - 3, record._id?.length)
              : record._id}
          </Link>
        ),
      },
      {
        title: 'Name',
        render: (record) => (
          <Link to={`${this.mapTokenRecordType(record.token_record_type)}/${record._id}`}>
            {record.token_name}
          </Link>
        ),
      },
      {
        title: 'Abbreviation',
        render: (record) => (
          <Link to={`${this.mapTokenRecordType(record.token_record_type)}/${record._id}`}>
            <TokenIcon
              src={record.token_icon ? record.token_icon : '/token-record-default.jpg'}
              onError={(e) => (e.target.src = '/token-record-default.jpg')}
            />
            {record.token_abbreviation}
          </Link>
        ),
      },
      {
        title: 'Decimals',
        dataIndex: 'precision',
      },
      {
        title: 'Total Supply',
        render: (record) => (
          <NumberFormat
            displayType="text"
            thousandSeparator={true}
            value={record.token_supply / Math.pow(10, record.precision)}
          />
        ),
      },
    ]
    return (
      <Wrapper>
        <PageHeader>List of All Tokens</PageHeader>
        <Table
          columns={columns}
          dataSource={allTokens}
          scroll={{ x: 900 }}
          sticky
          pagination={false}
          loading={!allTokens ? true : false}
        />
        <Pagin>
          <Pagination
            current={pageAllTokens.start_page}
            total={pageAllTokens.total_items}
            pageSize={pageAllTokens.page_limit}
            onChange={this.onChange}
            showSizeChanger
            showQuickJumper
          />
        </Pagin>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    allTokens: state.allTokens,
    pageAllTokens: state.pageAllTokens,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadAllTokens: (offset, limit) => {
      dispatch(loadAllTokens(offset, limit))
    },
    updatePageAllTokens: (page) => {
      dispatch(updatePageAllTokens(page))
    },
    updatePageAllTokensLimit: (limit) => {
      dispatch(updatePageAllTokensLimit(limit))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  AllTokensTable
)
