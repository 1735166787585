import React from 'react'
import { Table, Pagination, Badge, Skeleton, Spin, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  loadAddressToken,
  updatePageAddressToken,
  updatePageAddressTokenLimit,
} from '../../actions/addressToken'
import styled from 'styled-components'
import TruncateMiddle from 'react-truncate-string'

import NumberFormat from 'react-number-format'

const TokenCount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Token = styled.p`
  color: #c23631;
  font-size: 14px;
`

const StyledLink = styled(Link)``

const Pagin = styled.div`
  margin-top: 15px;
  text-align: right !important;
`
const BadgeGreen = styled(Badge)`
  .ant-badge-count {
    background-color: #e1f3e0;
    color: black;
  }
`

const BadgeRed = styled(Badge)`
  .ant-badge-count {
    background-color: #ff7677;
    color: black;
  }
`
class TokenTable extends React.Component {
  constructor() {
    super()
    this.onChange = this.onChange.bind(this)
    this.state = { test: 'www', checkTrigger: false }
  }
  async componentDidMount() {
    var { pageAddressToken } = this.props

    this.props.loadData(
      this.props.address,
      parseInt(pageAddressToken.current_page),
      parseInt(pageAddressToken.page_limit)
    )
  }
  onChange = (pageNumber, pageLimit) => {
    var { pageAddressToken } = this.props
    if (pageNumber !== pageAddressToken.current_page) {
      this.props.updatePageAddressToken(pageNumber)
    }
    if (pageLimit !== pageAddressToken.page_limit) {
      this.props.updatePageAddressTokenLimit(pageLimit)
    }
    this.props.loadData(this.props.address, pageAddressToken.current_page, pageLimit)
  }

  render() {
    var { tokens, total, status, pageAddressToken } = this.props
    const columns = [
      {
        title: 'Token',
        key: 'token',
        render: (record) => (
          <Link
            key={record.contract_address}
            from="*"
            to={(record.token_type === 'WRC10' ? '/token/' : '/token20/') + record.contract_address}
          >
            <Token>
              {record.name === undefined
                ? '–'
                : record.name
                    .trim()
                    .replace(/[^\x0-\x7]/g, '')
                    .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                  (record.symbol === undefined
                    ? ''
                    : ' (' +
                      record.symbol
                        .trim()
                        .replace(/[^\x0-\x7]/g, '')
                        .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                      ')')}
            </Token>
          </Link>
        ),
        width: '25%',
      },
      {
        title: 'ID (Contract)',
        key: 'id',
        render: (record) => {
          return (
            <StyledLink
              key={record.contract_address}
              to={
                (record.token_type === 'WRC10' ? '/token/' : '/contract/') + record.contract_address
              }
            >
              <Tooltip placement="top" title={record.contract_address}>
                <TruncateMiddle text={record.contract_address} />
              </Tooltip>
            </StyledLink>
          )
        },
        width: '15%',
      },
      {
        title: 'Token Type',
        key: 'tokentype',
        render: (record) => {
          return record.token_type
        },
        width: '15%',
      },

      {
        title: 'Precision',
        key: 'precision',
        render: (record) => {
          return record.precision === -1 ? '–' : record.precision
        },
        width: '10%',
      },
      {
        title: 'Amount',
        key: 'amount',
        render: (record) => (
          <NumberFormat
            displayType={'text'}
            thousandSeparator={true}
            value={
              record.balanceOf === undefined
                ? record.amount === undefined
                  ? '–'
                  : record.amount / Math.pow(10, record.precision == -1 ? 0 : record.precision)
                : record.balanceOf / (record.precision == -1 ? 1 : record.pow_decimal)
            }
          />
        ),
        width: '20%',
      },
    ]
    return (
      <div style={{ paddingBottom: '55px', textAlign: 'center' }}>
        {status != 'success' ? (
          <div style={{ paddingTop: '20px' }}>
            <Spin size="large" />
            <p>Loading...</p>
          </div>
        ) : (
          <div>
            <TokenCount>
              <p style={{ float: 'left', padding: '10px' }}>A Total of {total} Tokens</p>
            </TokenCount>
            <Table
              columns={columns}
              dataSource={tokens}
              rowKey="token"
              scroll={{ x: 900 }}
              sticky
              pagination={false}
              loading={tokens === undefined ? true : false}
              className="account-list"
            />
            <Pagin>
              <Pagination
                current={pageAddressToken.start_page}
                total={pageAddressToken.total_items}
                onChange={this.onChange}
                showSizeChanger
                showQuickJumper
                className="account-pagi"
                pageSize={pageAddressToken.page_limit}
                pageSizeOptions={[10, 15, 20, 25]}
              />
            </Pagin>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.addressTokens.status,
    tokens: state.addressTokens.result,
    total: state.addressTokens.total,
    pageAddressToken: state.pageAddressTokens,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updatePageAddressToken: (page) => {
      dispatch(updatePageAddressToken(page))
    },
    updatePageAddressTokenLimit: (limit) => {
      dispatch(updatePageAddressTokenLimit(limit))
    },
    loadData: (address, offset, limit) => {
      dispatch(loadAddressToken(address, offset, limit))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TokenTable)
