import React from 'react'
import { Table, Pagination, Badge, Skeleton, Spin, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  loadAddressTransaction,
  updatePageAddressTransaction,
  updatePageAddressTransactionLimit,
} from '../../actions/addressTransaction'
import styled from 'styled-components'
import { toTimeAgo } from '../../utils/utils'
import { DatePicker } from 'antd'
import '../../styles/account.less'
import TruncateMiddle from 'react-truncate-string'
import NumberFormat from 'react-number-format'
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo'

const { RangePicker } = DatePicker

const TranCount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const ContainChooseDate = styled.div`
  display: flex;
  justify-content: flex-end;
  width: -webkit-fill-available;
  position: absolute;
  padding: 9px;
`
const StyledLink = styled(Link)``

const Pagin = styled.div`
  margin-top: 15px;
  text-align: right !important;
`
const BadgeGreen = styled(Badge)`
  .ant-badge-count {
    background-color: #e1f3e0;
    color: black;
  }
`
const RedText = styled.span``
const BadgeRed = styled(Badge)`
  .ant-badge-count {
    background-color: #ff7677 !important;
    color: black;
  }
`
const Token = styled.p`
  color: #c23631;
  font-size: 14px;
`
const ChooseDate = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  position: absolute;
  width: -webkit-fill-available;
`

class TransTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: [],
    }
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    var { pageAddressTransaction } = this.props

    this.props.loadData(
      this.props.address,
      pageAddressTransaction.current_page,
      pageAddressTransaction.page_limit,
      this.state.date
    )
  }
  onChange = (pageNumber, pageLimit) => {
    var { pageAddressTransaction } = this.props
    if (pageNumber !== pageAddressTransaction.current_page) {
      this.props.updatePageAddressTransaction(pageNumber)
    }
    if (pageLimit !== pageAddressTransaction.page_limit) {
      this.props.updatePageAddressTransactionLimit(pageLimit)
    }
    this.props.loadData(
      this.props.address,
      pageAddressTransaction.current_page,
      pageLimit,
      this.state.date
    )
  }
  render() {
    var { transactions, status, total, pageAddressTransaction } = this.props
    const columns = [
      {
        title: 'Hash',
        dataIndex: 'hash',
        key: 'hash',
        width: '12%',
        render: (text) => (
          <Link to={'/transaction/' + text}>
            <RedText>{text.substring(0, 4) + '...' + text.substring(61, 65)}</RedText>
          </Link>
        ),
      },
      {
        title: 'Block',
        dataIndex: 'block_number',
        key: 'blockNumber',
        width: '8%',
        render: (text) => (
          <Link to={'/block/' + text}>
            <RedText>{text}</RedText>
          </Link>
        ),
      },
      {
        title: 'Age',
        key: 'timestamp',
        dataIndex: 'timestamp',
        render: (text) =>
          text ? <ReactTimeAgo date={new Date(text)} locale="en-US" /> : 'unknown',
        width: '10%',
      },
      {
        title: 'From',
        key: 'from',
        width: '12%',
        render: (record) =>
          record.contract.parameter.raw.owner_address === '' ||
          record.contract.parameter.raw.owner_address === undefined ? (
            '–'
          ) : (
            <StyledLink
              key={record.contract.parameter.raw.owner_address}
              to={'/accounts/' + record.contract.parameter.raw.owner_address}
              onClick={(e) =>
                record.contract.parameter.raw.owner_address === this.props.address
                  ? e.preventDefault()
                  : ''
              }
            >
              <Tooltip placement="top" title={record.contract.parameter.raw.owner_address}>
                <TruncateMiddle text={record.contract.parameter.raw.owner_address} />
              </Tooltip>
            </StyledLink>
          ),
      },
      {
        title: 'To',
        key: 'to',
        width: '12%',
        render: (record) =>
          record.contract.parameter.raw.to_address === '' ||
          record.contract.parameter.raw.to_address === undefined ? (
            record.contract.parameter.raw.receive_address === '' ||
            record.contract.parameter.raw.receive_address === undefined ? (
              '–'
            ) : (
              <StyledLink
                key={record.contract.parameter.raw.receive_address}
                to={'/accounts/' + record.contract.parameter.raw.receive_address}
                onClick={(e) =>
                  record.contract.parameter.raw.receive_address === this.props.address
                    ? e.preventDefault()
                    : ''
                }
              >
                <Tooltip placement="top" title={record.contract.parameter.raw.receive_address}>
                  <TruncateMiddle text={record.contract.parameter.raw.receive_address} />
                </Tooltip>
              </StyledLink>
            )
          ) : (
            <StyledLink
              key={record.contract.parameter.raw.to_address}
              to={'/accounts/' + record.contract.parameter.raw.to_address}
              onClick={(e) =>
                record.contract.parameter.raw.to_address === this.props.address
                  ? e.preventDefault()
                  : ''
              }
            >
              <Tooltip placement="top" title={record.contract.parameter.raw.to_address}>
                <TruncateMiddle text={record.contract.parameter.raw.to_address} />
              </Tooltip>
            </StyledLink>
          ),
      },
      {
        title: 'Amount',
        key: 'amount',
        render: (record) =>
          record.contract.type === 'FreezeBalanceContract' ? (
            <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              value={record.contract.parameter.raw.frozen_balance / Math.pow(10, 6)}
            />
          ) : (
            <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              value={
                record.pow_decimal === undefined
                  ? record.amount
                  : record.amount / record.pow_decimal < 1000000000000000
                  ? record.amount / record.pow_decimal
                  : 1000000000000000
              }
            />
          ),
        width: '20%',
      },
      {
        title: 'Result',
        key: 'ret',
        align: 'center',
        dataIndex: 'ret',
        render: (text) => {
          return text === 'SUCESS' ? <BadgeGreen count="SUCCESS" /> : <BadgeRed count="UNSUCCESS" />
        },
        width: '10%',
      },
      {
        title: 'Token',
        key: 'token',
        fixed: 'left',
        render: (record) =>
          record.symbol === '' ? (
            <p style={{ color: '#c23631' }}>–</p>
          ) : (
            <Link key={record.contract_address} from="*" to={'/token20/' + record.contract_address}>
              <Token>
                {record.symbol === undefined
                  ? ''
                  : ' (' +
                    record.symbol
                      .trim()
                      .replace(/[^\x0-\x7]/g, '')
                      .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                    ')'}
              </Token>
            </Link>
          ),
        width: '20%',
      },
    ]

    return (
      <div className="contract-transaction-table">
        <div className="ant-col-sm-24">
          <div className="ant-col-xl-16 ant-col-lg-16 ant-col-md-16 ant-col-sm-24 ant-col-xs-24 contract-transaction-table-date">
            <RangePicker
              value={this.state.date}
              onChange={(val) => {
                this.setState({
                  date: val,
                })
                this.props.loadData(this.props.address, 1, pageAddressTransaction.page_limit, val)
              }}
            />
          </div>
        </div>
        <div style={{ paddingBottom: '55px', textAlign: '-webkit-center' }}>
          {status != 'success' ? (
            <div style={{ width: 'fit-content' }}>
              <Spin size="large" />
              <p>Loading...</p>
            </div>
          ) : (
            <div style={{ padding: '10px', marginTop: '28px' }}>
              <TranCount>
                <p>
                  A Total of {total + ' '}
                  Transactions
                </p>
              </TranCount>
              <Table
                columns={columns}
                dataSource={transactions}
                rowKey="address"
                scroll={{ x: 1300 }}
                sticky
                pagination={false}
                loading={transactions === undefined ? true : false}
                className="account-list"
              />
              <Pagin>
                <Pagination
                  current={pageAddressTransaction.start_page}
                  total={pageAddressTransaction.total_items}
                  onChange={this.onChange}
                  pageSize={pageAddressTransaction.page_limit}
                  showSizeChanger
                  showQuickJumper
                  className="account-pagi"
                  pageSizeOptions={[10, 15, 20, 25]}
                />
              </Pagin>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.addressTransactions.status,
    transactions: state.addressTransactions.result,
    total: state.addressTransactions.total,
    pageAddressTransaction: state.pageAddressTransactions,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updatePageAddressTransaction: (page) => {
      dispatch(updatePageAddressTransaction(page))
    },
    updatePageAddressTransactionLimit: (limit) => {
      dispatch(updatePageAddressTransactionLimit(limit))
    },
    loadData: (address, offset, limit, date) => {
      dispatch(loadAddressTransaction(address, offset, limit, date))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TransTable)
