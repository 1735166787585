import Asset from '../api/asset'
import { notification } from 'antd'
import { API_ADDR } from '../config/config'
import { GLOBAL_SYMBOL } from '../constant'
import { GetPrivateKeyByAddress } from '../common/validate'

export const TRANSFER_NONE = 'TRANSFER_NONE'
export const TRANSFER_REQUESTING = 'TRANSFER_REQUESTING'
export const TRANSFER_SUCCESS = 'TRANSFER_SUCCESS'
export const TRANSFER_FAIL = 'TRANSFER_FAIL'
export function reset() {
  return {
    type: TRANSFER_NONE,
  }
}
export function request() {
  return {
    type: TRANSFER_REQUESTING,
  }
}
export function success(tranID) {
  return {
    type: TRANSFER_SUCCESS,
    payload: {
      tranID: tranID,
    },
  }
}
export function fail(message) {
  return {
    type: TRANSFER_FAIL,
    payload: message,
  }
}

export function transferAsset(privs, owner_address, to, amount, decimal_val, assetName) {
  var fromPrivKey = GetPrivateKeyByAddress(privs, owner_address)

  if (assetName == 'WIN') {
    return async (dispatch) => {
      dispatch(request())

      let precision = decimal_val
      var num_after_split = String(parseFloat(amount)).split('.')

      if (num_after_split.length == 2) {
        precision -= num_after_split[1].length
      }
      var amount_temp = String(parseFloat(amount)).replace('.', '')

      for (let idx = 0; idx < precision; idx++) {
        amount_temp += '0'
      }

      const res1 = await Asset.transfer(fromPrivKey, to, parseInt(amount_temp), assetName)
      if (!res1.result) {
        dispatch(fail())
        notification.error({
          message: 'Failed!',
          description: `Transfer has failed`,
        })
      } else {
        dispatch(success(res1.tranID))
      }
    }
  }

  return async (dispatch) => {
    let precision = decimal_val
    var num_after_split = String(parseFloat(amount)).split('.')

    if (num_after_split.length == 2) {
      precision -= num_after_split[1].length
    }
    var amount_temp = String(parseFloat(amount)).replace('.', '')

    for (let idx = 0; idx < precision; idx++) {
      amount_temp += '0'
    }

    dispatch(request())
    fetch(`${API_ADDR}/transferrc10`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        owner_address: owner_address,
        to_address: to,
        asset_name: assetName,
        amount: amount_temp,
        private_key: fromPrivKey,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status && res.status === 'success') {
          if (res.data.result) {
            dispatch(success(res.data.txid))
          } else {
            dispatch(fail(res.message))
          }
        } else {
          dispatch(fail(res.message))
          notification.error({
            message: res.message,
            description: `Transfer has failed`,
          })
        }
      })
      .catch((err) => {})
  }
}

export const TRANSFER_TOKENS_INIT = 'TRANSFER_TOKENS_INIT'
export const TRANSFER_TOKENS_UPDATE = 'TRANSFER_TOKENS_UPDATE'

export function initTokens() {
  return {
    type: TRANSFER_TOKENS_INIT,
  }
}
export function updateTokens(tokens) {
  return {
    type: TRANSFER_TOKENS_UPDATE,
    payload: tokens,
  }
}

export function loadTokens() {
  const defaultToken = { abbr: GLOBAL_SYMBOL, precision: 6, name: GLOBAL_SYMBOL, id: '' }
  return (dispatch) => {
    fetch(`${API_ADDR}/assets`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updatePageTokensTotal(res.data.length))
        const token10 = res.data.map((item) => {
          return { abbr: item.abbr, precision: item.precision, name: item.name, id: item.id }
        })
        const tokens = [defaultToken, ...token10]
        dispatch(updateTokens(tokens ? tokens : []))
      })
      .catch((err) => {
        dispatch(updateTokens([defaultToken]))
      })
  }
}

export const TRANSFER_PAGE_TOKENS_INIT = 'TRANSFER_PAGE_TOKENS_INIT'
export const TRANSFER_PAGE_TOKENS_UPDATE = 'TRANSFER_PAGE_TOKENS_UPDATE'
export const TRANSFER_PAGE_TOKENS_TOTAL_UPDATE = 'TRANSFER_PAGE_TOKENS_TOTAL_UPDATE'

export function initPageTokens() {
  return {
    type: TRANSFER_PAGE_TOKENS_INIT,
  }
}
export function updatePageTokens() {
  return {
    type: TRANSFER_PAGE_TOKENS_UPDATE,
  }
}

export function updatePageTokensTotal(total) {
  return {
    type: TRANSFER_PAGE_TOKENS_TOTAL_UPDATE,
    payload: total,
  }
}
