export var ValidateAddress = new RegExp('^[W][a-zA-Z0-9]{33}$')
import { API_ADDR } from '../config/config'

export async function CheckAddress(type, address) {
  const result = await fetch(`${API_ADDR}/checkaddressformat/${type}/${address}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 'fail') {
        return {
          status: false,
          msg: 'Wrong format of ' + (type === 'WRC20' ? 'Contract address' : 'To address'),
        }
      }
      return {
        status: true,
        msg: '',
      }
    })
    .catch((err) => {})

  return result
}

export function GetPrivateKeyByAddress(list_private_key, address) {
  for (let index = 0; index < list_private_key.length; index++) {
    const element = list_private_key[index]
    if (element.address == address) {
      return element.prikey
    }
  }
  return ''
}
