import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { Div, Right, TextBox, Wrapper } from './style'
import { SettingOutlined, CopyOutlined, FileOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'

class ContractCode extends Component {
  render() {
    let { contract } = this.props
    return (
      <Wrapper style={{ padding: '10px' }}>
        <Row gutter={[20, 20]}>
          <Col span={24} style={{ marginBottom: '10px' }}>
            <SettingOutlined />
            &nbsp;Contract ABI&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <CopyToClipboard text={contract.abi ? JSON.stringify(contract.abi) : null}>
              <CopyOutlined />
            </CopyToClipboard>
          </Col>
        </Row>
        <TextBox rows={7} value={contract.abi ? JSON.stringify(contract.abi) : null} disabled />
        <Row>
          <Col span={24} style={{ margin: '20px 0px 10px 0px' }}>
            <FileOutlined />
            &nbsp;Byte codes&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <CopyToClipboard text={contract.bytecode}>
              <CopyOutlined />
            </CopyToClipboard>
          </Col>
        </Row>
        <TextBox rows={7} value={contract.bytecode} disabled />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    contract: state.contract,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(ContractCode)
