import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { CopyOutlined, LoadingOutlined } from '@ant-design/icons'
import { Skeleton, Spin } from 'antd'
import { mapTransactionType } from '../../utils/utils'
import NumberFormat from 'react-number-format'

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { format } from 'date-fns-tz'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const CardContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  border-bottom: 1px solid #eeeeee;
  padding-top: 10px;
  padding-bottom: 10px;
`
const Content = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  background-color: #ffffff;
  width: 100%;
`
const CardHeader = styled.div`
  text-align: left;
  min-width: 100px;
  font-weight: 600;
`
const RowTitle = styled.div`
  min-width: 100px;
  text-align: left;
`
const RowValue = styled.div`
  text-align: left;
`
const RowValueExt = styled.div`
  height: auto;
  max-height: 100px;
`
const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const StatusConfirm = styled.div`
  padding-right: 5px;
  margin-right: 15px;
`
const StatusBlockNum = styled.div`
  background-color: #dddddd;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 15px;
`

const RowTitleUpperGreen = styled.div`
  background-color: #e1f3e0;
  min-width: 70px;
  text-align: left;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
`
const RowTitleUpperRed = styled.div`
  background-color: #ff7677;
  min-width: 100px;
  text-align: left;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
`
const Failed = styled.p`
  color: #c23631;
`
const Success = styled.p`
  color: #07cc10;
`
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const Status = (confirmed, numOfBlocks) => (
  <StatusContainer>
    <StatusConfirm>
      {confirmed === true ? (
        <RowTitleUpperGreen>CONFIRMED</RowTitleUpperGreen>
      ) : (
        <RowTitleUpperRed>UNCONFIRMED</RowTitleUpperRed>
      )}
    </StatusConfirm>
    <StatusBlockNum>
      <RowValue>confirmed by {numOfBlocks} blocks</RowValue>
    </StatusBlockNum>
    <Spin size="small" />
  </StatusContainer>
)
const HightLight = styled.span`
  color: '#E50915';
`

const CopyIcon = styled.span`
  margin-left: 5px;
  &:hover {
    cursor: pointer;
  }
`
class Card extends Component {
  state = {
    copied: false,
  }

  render() {
    const {
      hash,
      contract,
      block_number,
      ret,
      timestamp,
      numOfBlocks,
      ref_block_num,
      confirmed,
      symbol,
      name,
      amount,
      pow_decimal,
    } = this.props.transaction
    const block_height = this.props.block_height
    const type = this.props.transaction.contract.type
    const frozen_balance =
      this.props.transaction.contract.parameter.raw != undefined
        ? this.props.transaction.contract.parameter.raw.frozen_balance != undefined
          ? this.props.transaction.contract.parameter.raw.frozen_balance / Math.pow(10, 6)
          : '–'
        : '–'

    const content = [
      {
        title: 'Result',
        value:
          ret === 'SUCCESS' ? (
            <Success>
              <CheckCircleOutlined style={{ color: '#07cc10' }} /> {ret}
            </Success>
          ) : (
            <Failed>
              <CloseCircleOutlined style={{ color: '#c23631' }} /> {ret}
            </Failed>
          ),
      },
      {
        title: 'Status',
        value:
          block_height - block_number == undefined
            ? ref_block_num
            : block_number >= 21
            ? Status(
                true,
                block_height - (block_number == undefined ? ref_block_num : block_number)
              )
            : Status(
                confirmed,
                block_height - (block_number == undefined ? ref_block_num : block_number)
              ),
      },
      {
        title: 'Block',
        value: <HightLight>{block_number == undefined ? ref_block_num : block_number}</HightLight>,
      },
      {
        title: 'Time',
        value:
          timestamp === 0 ? '–' : format(new Date(timestamp), 'yyyy-MM-dd HH:mm:ss').toString(),
      },
      // { title: 'Contract', value: mapTransactionType(contract.type) },
      // {
      //   title: 'Name',
      //   value:
      //     name === undefined ? (
      //       '–'
      //     ) : name === '' ? (
      //       '–'
      //     ) : (
      //       <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      //         {name
      //           .trim()
      //           .replace(/[^\x0-\x7]/g, '')
      //           .replace(/[\u{0080}-\u{FFFF}]/gu, '')}
      //       </p>
      //     ),
      // },
      // {
      //   title: 'Symbol',
      //   value:
      //     symbol === undefined ? (
      //       '–'
      //     ) : symbol === '' ? (
      //       '–'
      //     ) : (
      //       <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      //         {symbol
      //           .trim()
      //           .replace(/[^\x0-\x7]/g, '')
      //           .replace(/[\u{0080}-\u{FFFF}]/gu, '')}
      //       </p>
      //     ),
      // },
      // {
      //   title: 'Amount',
      //   value:
      //     type === 'FreezeBalanceContract' ? (
      //       <NumberFormat displayType={'text'} thousandSeparator={true} value={frozen_balance} />
      //     ) : (
      //       <NumberFormat
      //         displayType={'text'}
      //         thousandSeparator={true}
      //         value={amount / pow_decimal}
      //       />
      //     ),
      // },
    ]
    return (
      <Wrap className="transaction-detail-card">
        <Skeleton loading={hash === ''}>
          <Content>
            <CardContent>
              <CardHeader className="transaction-detail-card-address">
                Hash: {hash}
                <CopyToClipboard
                  text={hash}
                  onCopy={() => {
                    this.setState({ copied: true })
                    setTimeout(
                      function () {
                        this.setState({ copied: false })
                      }.bind(this),
                      1000
                    )
                  }}
                >
                  <CopyIcon>
                    {this.state.copied ? (
                      <>
                        <CheckCircleOutlined /> Copied
                      </>
                    ) : (
                      <CopyOutlined />
                    )}
                  </CopyIcon>
                </CopyToClipboard>
              </CardHeader>
            </CardContent>
            {content.map((item, index) => {
              return (
                <CardContent key={index} style={{ overflow: 'hidden' }}>
                  <RowTitle>{item.title}:</RowTitle>
                  <RowValue>{item.value}</RowValue>
                </CardContent>
              )
            })}
          </Content>
        </Skeleton>
      </Wrap>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transaction: state.transaction,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Card)
