import React from 'react'
import { connect } from 'react-redux'
import { loadAddressApi } from '../../actions/address'
import { Row, Col } from 'antd'
import { Table, Div } from './style'
import { Skeleton } from 'antd'
import CardShadow from './../partials/cardShadow'
import AddressTransfer from './addressTransfer'
import AddressCreated from './addressCreated'
import AddressTransaction from './addressTransaction'
import AddressUnwithdraw from './addressUnwithdraw'
import AddressOwnerBalance from './addressOwnerBalance'
import AddressFrozenBalance from './addressFrozenBalance'
import AddressName from './addressName'
import AddressMyTask from './addressMyTask'
import AddressHeader from './addressHeader'
import AddressPower from './addressPower'
import AddressEnergy from './addressEnergy'
import AddressBandwidth from './addressBandwidth'
import AddressMenu from './addressMenu'
import AddressSwitchMenu from './addressSwitchMenu'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import { getReward } from '../../actions/reward'

class AddressDetail extends React.Component {
  state = {
    copied: false,
  }
  componentDidMount() {
    this.props.urlAtNotPrivFunc()
    var id = this.props.match.params.id
    this.props.loadAddress(id)
    this.props.getReward({ address: id })
  }

  componentDidUpdate(prevProps) {
    const id = this.props.match.params.id
    if (prevProps.match.params.id !== id) {
      this.props.loadAddress(id)
      this.props.getReward({ address: id })
    }
  }

  render() {
    return (
      <Skeleton loading={this.props.address.address === ''}>
        <AddressHeader address={this.props.match.params.id} />
        <CardShadow className="account-detail-content">
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col className="ant-col-xs-24 ant-col-sm-24 ant-col-xl-15 ant-col-md-15">
              <Div>
                <Table>
                  <tbody>
                    <AddressMyTask />
                    <AddressName />
                    <AddressFrozenBalance />
                    <AddressOwnerBalance />
                    <AddressUnwithdraw />
                    <AddressTransaction />
                    <AddressTransfer />
                    <AddressCreated />
                  </tbody>
                </Table>
              </Div>
            </Col>
            <Col
              className="ant-col-xs-24 ant-col-sm-24 ant-col-xl-8 ant-col-md-8 account-detail-power"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
            >
              <AddressPower />
              <AddressEnergy />
              <AddressBandwidth />
            </Col>
          </Row>
        </CardShadow>
        <AddressMenu address={this.props.match.params.id} />
        <AddressSwitchMenu
          address={this.props.match.params.id}
          loadAddress={this.props.loadAddress}
        />
      </Skeleton>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    address: state.address,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadAddress: (id) => {
      dispatch(loadAddressApi(id))
    },
    getReward: (params) => {
      dispatch(getReward(params))
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  AddressDetail
)
