import fetch from 'cross-fetch'

import { API_ADDR } from '../config/config'
export const ADDRESS_TOKEN_INIT = 'ADDRESSES_TOKEN_INIT'
export const ADDRESS_TOKEN_UPDATE = 'ADDRESS_TOKEN_UPDATE'

export function initAddressToken() {
  return {
    type: ADDRESS_TOKEN_INIT,
  }
}

export function updateAddressToken(addressToken, total, status) {
  return {
    type: ADDRESS_TOKEN_UPDATE,
    payload: {
      result: addressToken,
      total: total,
      status: status,
    },
  }
}

export function loadAddressToken(address, offset, limit, date) {
  var start = 0
  var end = 0
  if (date != null) {
    if (date.length == 2) {
      start = date[0].valueOf()
      end = date[1].valueOf()
    }
  }

  return (dispatch) => {
    dispatch(initAddressToken())
    fetch(`${API_ADDR}/address/token/${address}?num=${offset}&limit=${limit}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updatePageAddressTokenTotal(res.data.total + 1))
        dispatch(updatePageAddressTokenStart(res.data.total))
        dispatch(updateAddressToken(res.data.result, res.data.total, res.status))
      })
      .catch((err) => {
        dispatch(updatePageAddressTokenTotal(1))
        dispatch(updatePageAddressTokenStart(0))
        dispatch(updateAddressToken([], 0, 'fail'))
      })
  }
}

export function nullAddressToken() {
  return (dispatch) => {
    dispatch(updatePageAddressTokenTotal(1))
    dispatch(updatePageAddressTokenStart(0))
    dispatch(updateAddressToken([], 0, 'success'))
  }
}

export function loadAddressTokenUser(address, offset, limit, date) {
  var start = 0
  var end = 0
  if (date != null) {
    if (date.length == 2) {
      start = date[0].valueOf()
      end = date[1].valueOf()
    }
  }
  return (dispatch) => {
    dispatch(initAddressToken())
    fetch(`${API_ADDR}/address/token/${address}?num=${offset}&limit=${limit}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        if (
          ((res.data.total + 1) % limit === 0
            ? parseInt((res.data.total + 1) / limit)
            : parseInt((res.data.total + 1) / limit) + 1) === offset
        ) {
          fetch(`${API_ADDR}/getamountwel`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({
              address: address,
            }),
          })
            .then((result) => result.json())
            .then((result) => {
              res.data.result.push({
                amount: (result.data.amount / result.data.decimal) * Math.pow(10, 6),
                contract_address: '–',
                name: 'WIN',
                precision: 6,
                symbol: undefined,
                token_type: '–',
              })
              dispatch(updatePageAddressTokenTotal(res.data.total + 1 + 1))
              dispatch(updatePageAddressTokenStart(res.data.total + 1))
              dispatch(updateAddressToken(res.data.result, res.data.total + 1, res.status))
              return
            })
            .catch((err) => {
              dispatch(updatePageAddressTokenTotal(res.data.total + 1))
              dispatch(updatePageAddressTokenStart(res.data.total))
              dispatch(updateAddressToken(res.data.result, res.data.total, res.status))
              return
            })
        } else {
          dispatch(updatePageAddressTokenTotal(res.data.total + 1 + 1))
          dispatch(updatePageAddressTokenStart(res.data.total + 1))
          dispatch(updateAddressToken(res.data.result, res.data.total + 1, res.status))
        }
      })
      .catch((err) => {})
  }
}

export const PAGE_ADDRESS_TOKEN_INIT = 'PAGE_ADDRESS_TOKEN_INIT'
export const PAGE_ADDRESS_TOKEN_UPDATE = 'PAGE_ADDRESS_TOKEN_UPDATE'
export const PAGE_ADDRESS_TOKEN_TOTAL_UPDATE = 'PAGE_ADDRESS_TOKEN_TOTAL_UPDATE'
export const PAGE_ADDRES_TOKEN_LIMIT_UPDATE = 'PAGE_ADDRES_TOKEN_LIMIT_UPDATE'
export const PAGE_ADDRESS_TOKEN_START_UPDATE = 'PAGE_ADDRESS_TOKEN_START_UPDATE'

export function initPageAddressToken() {
  return {
    type: PAGE_ADDRESS_TOKEN_INIT,
  }
}
export function updatePageAddressToken(page) {
  return {
    type: PAGE_ADDRESS_TOKEN_UPDATE,
    payload: page,
  }
}

export function updatePageAddressTokenTotal(total) {
  return {
    type: PAGE_ADDRESS_TOKEN_TOTAL_UPDATE,
    payload: total,
  }
}

export function updatePageAddressTokenLimit(limit) {
  return {
    type: PAGE_ADDRES_TOKEN_LIMIT_UPDATE,
    payload: limit,
  }
}

export function updatePageAddressTokenStart(start_page) {
  return {
    type: PAGE_ADDRESS_TOKEN_START_UPDATE,
    payload: start_page,
  }
}
