import { API_ADDR } from '../config/config'
import { GetPrivateKeyByAddress } from '../common/validate'
import { notification } from 'antd'

export const TRANSFER_WRC20_UPDATE = 'TRANSFER_WRC20_UPDATE'
export const TRANSFER_WRC20_INIT = 'TRANSFER_WRC20_INIT'
export const TRANSFER_WRC20_REQUESTING = 'TRANSFER_WRC20_REQUESTING'
export const TRANSFER_WRC20_NONE = 'TRANSFER_WRC20_NONE'
export const TRANSFER_WRC20_SUCCESS = 'TRANSFER_WRC20_SUCCESS'
export const TRANSFER_WRC20_FAIL = 'TRANSFER_WRC20_FAIL'

export function reset() {
  return {
    type: TRANSFER_WRC20_NONE,
  }
}
export function request() {
  return {
    type: TRANSFER_WRC20_REQUESTING,
  }
}
export function initTransferWRC20() {
  return {
    type: TRANSFER_WRC20_INIT,
  }
}

export function updateTransferWRC20(data) {
  return {
    type: TRANSFER_WRC20_UPDATE,
    payload: data,
  }
}

export function success(tranID) {
  return {
    type: TRANSFER_WRC20_SUCCESS,
    payload: {
      tranID: tranID,
    },
  }
}

export function fail(message) {
  return {
    type: TRANSFER_WRC20_FAIL,
    payload: message,
  }
}

function splitDecimal(s) {
  s.includes('.') && s.substr(s.lastIndexOf('.') + 1).split(' ')[0]
}
export function transferWRC20(privs, owner_address, to_address, contract_address, amount) {
  return async (dispatch) => {
    dispatch(request())
    var fromPrivKey = GetPrivateKeyByAddress(privs, owner_address)

    var temp = await fetch(`${API_ADDR}/transferrc20/getpowdecimal/` + contract_address, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        return res.data
      })
      .catch((err) => {})

    var powdecimal = temp === undefined ? 1 : temp

    var num_after_split = String(parseFloat(amount)).split('.')

    let precision = 0
    for (let idx = 0; idx < String(powdecimal).length; idx++) {
      if (String(powdecimal)[idx] == '0') {
        precision++
      }
    }

    var wanna_amount = ''

    var temp_split_e_amount = String(amount).split('e-')
    if (temp_split_e_amount.length == 2) {
      var temp = ''
      for (let index = 0; index < precision - parseInt(temp_split_e_amount[1]) - 1; index++) {
        temp += '0'
      }
      wanna_amount = temp_split_e_amount[0] + temp
    }

    if (num_after_split.length == 2) {
      precision -= num_after_split[1].length
    }

    var amount_temp = String(parseFloat(amount)).replace('.', '')

    for (let idx = 0; idx < precision; idx++) {
      amount_temp += '0'
    }
    if (wanna_amount != '') {
      amount_temp = wanna_amount.replace('.', '')
    }

    fetch(`${API_ADDR}/transferrc20`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        contract_address: contract_address,
        to_address: to_address,
        owner_address: owner_address,
        amount: amount_temp,
        private_key: fromPrivKey,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateTransferWRC20(res))
        if (res.status === 'fail') {
          dispatch(fail(res.message))
          notification.error({
            message: res.message,
            description: `Failed`,
          })
        } else if (res.status === 'success') {
          if (res.data.result === true) {
            dispatch(success(res.data.txid))
            notification.success({
              message: 'TxID: ' + res.data.txid,
              description: `Succeeded`,
            })
          } else {
            dispatch(fail('Transfer failed'))
            notification.error({
              message: 'Transfer ' + res.data.txid,
              description: `Failed`,
            })
          }
        }
      })
      .catch((err) => {})
  }
}
