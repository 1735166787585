import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { TableRow, Td } from './style'

class AddressUnwithdraw extends Component {
  render() {
    let { reward } = this.props

    return (
      <TableRow>
        <Td>
          <span>Unwithdrawn voting reward</span>:
        </Td>
        <td>
          <div>
            <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              value={reward.amount / Math.pow(10, 6)}
              suffix=" WIN"
            />
          </div>
        </td>
      </TableRow>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    reward: state.reward,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AddressUnwithdraw)
