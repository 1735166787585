import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableRow, Td } from './style'

class AddressMyTask extends Component {
  render() {
    let { mytask } = this.props

    return (
      <TableRow>
        <Td>
          <span>My Task</span>:
        </Td>
        <td>
          <div>
            <span>{mytask}</span>
          </div>
        </td>
      </TableRow>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    mytask:
      state.address.mytask === '' || state.address.mytask === undefined
        ? '–'
        : state.address.mytask,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AddressMyTask)
