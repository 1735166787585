import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PageHeader from '../partials/pageHeader'
import { Button, Col, Layout, Modal, notification, Row } from 'antd'
import RecordMenu from './recordMenu'
import RecordSwitchMenu from './recordSwitchMenu'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import {
  addressUpdateTokenRecord,
  getTokenRecord,
  initTokenRecord,
  updateTokenRecord,
} from '../../actions/tokenRecord'
import { API_ADDR } from '../../config/config'

const Wrapper = styled.div`
  margin: 5px;
`
const { Header, Footer, Content } = Layout

class TokenRecord extends React.Component {
  async componentDidMount() {
    this.props.initTokenRecord()
    this.props.urlAtNotPrivFunc()
    if (window.location.pathname.split('/')[1] != 'token-record') {
      var id = this.props.match.params.id

      if (id != undefined) {
        this.props.getTokenRecord(id)
      }
    }
  }
  render() {
    return (
      <Wrapper>
        <PageHeader>Token Record</PageHeader>
        <RecordMenu />
        <RecordSwitchMenu />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tokenRecord: state.tokenRecord,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
    initTokenRecord: () => {
      dispatch(initTokenRecord())
    },
    getTokenRecord: (contract_addr) => {
      dispatch(getTokenRecord(contract_addr))
    },
    addressUpdateTokenRecord: (id) => {
      dispatch(addressUpdateTokenRecord(id))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TokenRecord)
