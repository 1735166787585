import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Avatar,
  Upload,
  message,
  InputNumber,
  Modal,
  Checkbox,
  notification,
} from 'antd'
import IconTwitter from '../../assets/images/iconTwitter.png'
import IconTwitterGrey from '../../assets/images/iconTwitterGrey.png'
import IconTelegram from '../../assets/images/iconTelegram.png'
import IconTelegramGrey from '../../assets/images/iconTelegramGrey.png'
import IconFacebook from '../../assets/images/iconFacebook.png'
import IconFacebookGrey from '../../assets/images/iconFacebookGrey.png'
import IconWeibo from '../../assets/images/iconWeibo.png'
import IconWeiboGrey from '../../assets/images/iconWeiboGrey.png'
import IconReddit from '../../assets/images/iconReddit.png'
import IconRedditGrey from '../../assets/images/iconRedditGrey.png'
import IconSteem from '../../assets/images/iconSteem.png'
import IconSteemGrey from '../../assets/images/iconSteemGrey.png'
import IconMedium from '../../assets/images/iconMedium.png'
import IconMediumGrey from '../../assets/images/iconMediumGrey.png'
import IconInstagram from '../../assets/images/iconInstagram.png'
import IconInstagramGrey from '../../assets/images/iconInstagramGrey.png'
import IconWechat from '../../assets/images/iconWechat.png'
import IconWechatGrey from '../../assets/images/iconWechatGrey.png'
import IconGroup from '../../assets/images/iconGroup.png'
import IconGroupGrey from '../../assets/images/iconGroupGrey.png'
import IconDiscord from '../../assets/images/iconDiscord.png'
import IconDiscordGrey from '../../assets/images/iconDiscordGrey.png'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import { loadContractApi } from '../../actions/contract'
import { API_ADDR } from '../../config/config'
import { getDefaulteyFromToken } from '../../common/commonTransaction'
import { Redirect } from 'react-router'
import { format } from 'date-fns-tz'
import { updateTokenRecord } from '../../actions/tokenRecord'
import validator from 'validator'
import { SwapOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import S3FileUpload from 'react-s3'
import Asset from '../../api/asset'
import NumberFormat from 'react-number-format'
import ConfigKey from '../../api/key'

const { TextArea } = Input

const Item = styled(Form.Item)`
  font-weight: bold;
`
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`
const Logo = styled.img`
  height: 66px;
  width: 200px;
  margin-bottom: 20px;
`
const Title = styled.span`
  font-size: 25px;
  font-weight: 600;
`
const ContentTitle = styled.span`
  font-weight: 400;
  margin-left: 7px;
`
const TitleContainer = styled.div`
  text-align: left;
`
const HeaderTitle = styled.div`
  margin-top: 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledForm = styled(Form)``
const IconSocial = styled(Avatar)`
  margin: 5px;
  width: 20px;
  height: 20px;
`
const TitleForm = styled.p`
  margin-top: 1em;
  margin-bottom: 1em;
`
const CustomDivider = styled(Divider)`
  margin-top: 0;
  margin-bottom: 1em;
`
const CustomInput = styled(Input)`
  ::placeholder {
    font-size: 12px;
  }
`
const CustomTextArea = styled(TextArea)`
  ::placeholder {
    font-size: 12px;
  }
`
const CustomInputNumber = styled(InputNumber)`
  ::-webkit-input-placeholder {
    font-size: 12px;
  }
  width: 100%;
`
const { Header, Footer, Content } = Layout

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

class FormTokenRecordRC20RC721 extends React.Component {
  constructor(props) {
    super(props)
    this.inputFile = React.createRef()
    this.state = {
      defaultPriv: getDefaulteyFromToken(),
      imageUrl: '',
      contract_address: '',
      total_precision_temp: '',
      token_abbr: '',
      loadingSubmit: false,
      precision_value: 0,
      timestamp_create: 0,
      price_not_win: 0,
      price_win: 0,
      file: {},
      visible_upload: false,
      visible_error_pixel: false,
      visible_error_size: false,
      visible_error_empty_addr: false,
      visible_error_file_type: false,
      colorIconSwap: '#000',
      addressUpdate: false,
      positionWinFirst: false,
      REACT_APP_API_ADDRESS_ADMIN: '',
      REACT_APP_API_PRIVATE_KEY_ADMIN: '',
      REACT_APP_API_S3_BUCKET_NAME: '',
      REACT_APP_API_S3_DIR_NAME: '',
      REACT_APP_API_S3_REGION: '',
      REACT_APP_API_S3_ACCESS_KEY_ID: '',
      REACT_APP_API_S3_SECRET_ACCESS_KEY: '',
      REACT_APP_API_S3_UPLOAD_FEE: 0,
      REACT_APP_API_CREATE_RC10_FEE: 0,
      REACT_APP_API_CREATE_RC20_FEE: 0,
      REACT_APP_API_CREATE_RC721_FEE: 0,
      REACT_APP_API_UPDATE_RC10_FEE: 0,
      REACT_APP_API_UPDATE_RC20_FEE: 0,
      REACT_APP_API_UPDATE_RC721_FEE: 0,
      socialMedia: [
        {
          name: 'Twitter',
          isOpen: true,
          icon: IconTwitter,
          iconGrey: IconTwitterGrey,
        },
        {
          name: 'Telegram',
          isOpen: true,
          icon: IconTelegram,
          iconGrey: IconTelegramGrey,
        },
        {
          name: 'Facebook',
          isOpen: true,
          icon: IconFacebook,
          iconGrey: IconFacebookGrey,
        },
        {
          name: 'Weibo',
          isOpen: true,
          icon: IconWeibo,
          iconGrey: IconWeiboGrey,
        },
        {
          name: 'Reddit',
          isOpen: false,
          icon: IconReddit,
          iconGrey: IconRedditGrey,
        },
        {
          name: 'Steem',
          isOpen: false,
          icon: IconSteem,
          iconGrey: IconSteemGrey,
        },
        {
          name: 'Medium',
          isOpen: false,
          icon: IconMedium,
          iconGrey: IconMediumGrey,
        },
        {
          name: 'Instagram',
          isOpen: false,
          icon: IconInstagram,
          iconGrey: IconInstagramGrey,
        },
        {
          name: 'Wechat',
          isOpen: false,
          icon: IconWechat,
          iconGrey: IconWechatGrey,
        },
        {
          name: 'Group',
          isOpen: false,
          icon: IconGroup,
          iconGrey: IconGroupGrey,
        },
        {
          name: 'Discord',
          isOpen: false,
          icon: IconDiscord,
          iconGrey: IconDiscordGrey,
        },
      ],
    }
  }

  async componentDidMount() {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', (event) => {
      if (!this.state.addressUpdate) {
        window.location.href = '/token-record/create/Type'
      }
    })
    const { tokenRecord } = this.props
    const { socialMedia } = this.state

    this.setState({
      positionWinFirst: tokenRecord.data.position_win_first,
      imageUrl: tokenRecord.data.token_icon,
      contract_address: tokenRecord.data.contract_address,
      price_not_win: tokenRecord.data.price_usd,
      price_win: tokenRecord.data.price_win,
    })

    if (window.location.pathname.split('/')[1] != 'token-record') {
      this.setState({
        addressUpdate: true,
      })
      if (tokenRecord.data.token_supply_without_precision == undefined) {
        this.formRef.current.setFieldsValue({
          token_supply_without_precision: 0,
        })
      }
    } else {
      this.setState({
        addressUpdate: false,
      })
    }
    Object.keys(tokenRecord.data.social_media_link).map((key, i) =>
      socialMedia.map((val) => {
        if (key == val.name.toLowerCase()) {
          if (tokenRecord.data.social_media_link[key] != '') {
            if (key == 'facebook') {
              this.formRef.current.setFieldsValue({
                facebook: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'twitter') {
              this.formRef.current.setFieldsValue({
                twitter: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'telegram') {
              this.formRef.current.setFieldsValue({
                telegram: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'weibo') {
              this.formRef.current.setFieldsValue({
                weibo: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'reddit') {
              this.formRef.current.setFieldsValue({
                reddit: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'steem') {
              this.formRef.current.setFieldsValue({
                steem: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'medium') {
              this.formRef.current.setFieldsValue({
                medium: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'instagram') {
              this.formRef.current.setFieldsValue({
                instagram: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'wechat') {
              this.formRef.current.setFieldsValue({
                wechat: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'group') {
              this.formRef.current.setFieldsValue({
                group: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'discord') {
              this.formRef.current.setFieldsValue({
                discord: tokenRecord.data.social_media_link[key],
              })
            }
            val.isOpen = true
          } else {
            val.isOpen = false
            if (key == 'facebook') {
              val.isOpen = true
            } else if (key == 'twitter') {
              val.isOpen = true
            } else if (key == 'telegram') {
              val.isOpen = true
            } else if (key == 'weibo') {
              val.isOpen = true
            }
          }
        }
      })
    )
    const config_key = await ConfigKey.getkey()

    this.setState({
      REACT_APP_API_ADDRESS_ADMIN: config_key.REACT_APP_API_ADDRESS_ADMIN,
      REACT_APP_API_PRIVATE_KEY_ADMIN: config_key.REACT_APP_API_PRIVATE_KEY_ADMIN,
      REACT_APP_API_S3_BUCKET_NAME: config_key.REACT_APP_API_S3_BUCKET_NAME,
      REACT_APP_API_S3_DIR_NAME: config_key.REACT_APP_API_S3_DIR_NAME,
      REACT_APP_API_S3_REGION: config_key.REACT_APP_API_S3_REGION,
      REACT_APP_API_S3_ACCESS_KEY_ID: config_key.REACT_APP_API_S3_ACCESS_KEY_ID,
      REACT_APP_API_S3_SECRET_ACCESS_KEY: config_key.REACT_APP_API_S3_SECRET_ACCESS_KEY,
      REACT_APP_API_S3_UPLOAD_FEE: config_key.REACT_APP_API_S3_UPLOAD_FEE,
      REACT_APP_API_CREATE_RC10_FEE: config_key.REACT_APP_API_CREATE_RC10_FEE,
      REACT_APP_API_CREATE_RC20_FEE: config_key.REACT_APP_API_CREATE_RC20_FEE,
      REACT_APP_API_CREATE_RC721_FEE: config_key.REACT_APP_API_CREATE_RC721_FEE,
      REACT_APP_API_UPDATE_RC10_FEE: config_key.REACT_APP_API_UPDATE_RC10_FEE,
      REACT_APP_API_UPDATE_RC20_FEE: config_key.REACT_APP_API_UPDATE_RC20_FEE,
      REACT_APP_API_UPDATE_RC721_FEE: config_key.REACT_APP_API_UPDATE_RC721_FEE,
    })
  }

  formRef = React.createRef()

  onButtonClick() {
    this.inputFile.current.click()
  }

  async handleFileUpload(e) {
    const { files } = e.target
    if (files.length != 0) {
      if (this.state.contract_address == '' && this.props.tokenRecord.type_choose != 3) {
        this.setState({
          visible_error_empty_addr: true,
        })
        return
      }
      if (
        files[0].type != 'image/jpg' &&
        files[0].type != 'image/jpeg' &&
        files[0].type != 'image/png'
      ) {
        this.setState({
          visible_error_file_type: true,
        })
        return
      }

      {
        if (files[0].size < 200000) {
          const promises = []
          const promise = new Promise((resolve, reject) => {
            const image = new Image()
            let url
            image.onload = function () {
              files[0].width = image.width
              files[0].height = image.height
              resolve(files[0])
            }
            url = URL.createObjectURL(files[0])
            image.src = url
          })
          promises.push(promise)

          const promises_file = await Promise.all(promises)

          if (
            promises_file[0].width &&
            promises_file[0].width == 100 &&
            promises_file[0].height &&
            promises_file[0].height == 100
          ) {
            this.setState({
              visible_upload: true,
              file: files[0],
            })
          } else {
            this.setState({
              visible_error_pixel: true,
            })
          }
        } else {
          this.setState({
            visible_error_size: true,
          })
        }
      }
    }
  }

  transfer = async () => {
    const { tokenRecord } = this.props
    const {
      REACT_APP_API_ADDRESS_ADMIN,
      REACT_APP_API_PRIVATE_KEY_ADMIN,
      REACT_APP_API_S3_BUCKET_NAME,
      REACT_APP_API_S3_DIR_NAME,
      REACT_APP_API_S3_REGION,
      REACT_APP_API_S3_ACCESS_KEY_ID,
      REACT_APP_API_S3_SECRET_ACCESS_KEY,
      REACT_APP_API_S3_UPLOAD_FEE,
      REACT_APP_API_CREATE_RC10_FEE,
      REACT_APP_API_CREATE_RC20_FEE,
      REACT_APP_API_CREATE_RC721_FEE,
      REACT_APP_API_UPDATE_RC10_FEE,
      REACT_APP_API_UPDATE_RC20_FEE,
      REACT_APP_API_UPDATE_RC721_FEE,
    } = this.state
    const privatekey = this.state.defaultPriv.priv
    const amount = REACT_APP_API_S3_UPLOAD_FEE
    let temp = ''

    this.setState({
      loadingSubmit: true,
    })
    if (this.state.defaultPriv != undefined) {
      if (this.state.defaultPriv.address != undefined) {
        temp = this.state.defaultPriv.address
      }
    }
    var num_after_split = String(parseFloat(amount)).split('.')

    let precision = 6

    if (num_after_split.length == 2) {
      precision -= num_after_split[1].length
    }
    var amount_temp = String(parseFloat(amount)).replace('.', '')

    for (let idx = 0; idx < precision; idx++) {
      amount_temp += '0'
    }

    const res1 = await Asset.transfer(
      privatekey,
      REACT_APP_API_ADDRESS_ADMIN,
      parseInt(amount_temp),
      'WIN'
    )
    if (!res1.result) {
      notification.error({
        message: 'Failed!',
        description: 'Wallet has insufficient balance',
      })
      this.setState({
        loadingSubmit: false,
      })
    } else {
      var fetchData
      var time = 0
      var status_trans = ''
      var file_temp = this.state.file
      const config = {
        bucketName: REACT_APP_API_S3_BUCKET_NAME,
        dirName: REACT_APP_API_S3_DIR_NAME /* optional */,
        region: REACT_APP_API_S3_REGION,
        accessKeyId: REACT_APP_API_S3_ACCESS_KEY_ID,
        secretAccessKey: REACT_APP_API_S3_SECRET_ACCESS_KEY,
      }

      async function checkTransactionStatus() {
        if (status_trans == 'SUCCESS') {
          const objectUrl = URL.createObjectURL(this.state.file)
          this.setState({
            imageUrl: objectUrl,
            visible_upload: false,
            loadingSubmit: false,
          })
          // S3FileUpload.uploadFile(file_temp, config)
          //   .then((data) => {
          //     this.setState({
          //       imageUrl: data.location,
          //       visible_upload: false,
          //       loadingSubmit: false,
          //     })
          //     this.inputFile.current.value = ''
          //   })
          //   .catch((err) => {
          //     notification.error({
          //       message: 'Failed!',
          //       description: JSON.stringify(err),
          //     })
          //     this.setState({
          //       loadingSubmit: false,
          //     })
          //   })
          clearInterval(fetchData)
        } else {
          if (time === 3) {
            Asset.transfer(
              REACT_APP_API_PRIVATE_KEY_ADMIN,
              tokenRecord.data.issuer,
              parseInt(amount_temp),
              'WIN'
            )
            notification.error({
              message: 'Failed!',
              description: status_trans,
            })
            this.setState({
              loadingSubmit: false,
            })
            clearInterval(fetchData)
          }
        }
        status_trans = await fetch(`${API_ADDR}/transactions/${res1.tranID}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
        })
          .then((res_trans) => res_trans.json())
          .then((res_trans) => {
            if (res_trans.data.ret == 'SUCCESS' || res_trans.data.ret == 'SUCESS') {
              return 'SUCCESS'
            } else {
              return res_trans.message
            }
          })
          .catch((err) => {
            return 'Wallet has insufficient balance'
          })
        time++
      }
      fetchData = setInterval(checkTransactionStatus.bind(this), 3000)
    }
  }

  submitButton = (values) => {
    values.contract_creation_date = this.state.timestamp_create
    values.from_address = this.state.defaultPriv.address
    values.token_icon = this.state.imageUrl

    values.social_media_link = {
      twitter: values.twitter != undefined ? values.twitter : '',
      facebook: values.facebook != undefined ? values.facebook : '',
      telegram: values.telegram != undefined ? values.telegram : '',
      weibo: values.weibo != undefined ? values.weibo : '',
      reddit: values.reddit != undefined ? values.reddit : '',
      medium: values.medium != undefined ? values.medium : '',
      steem: values.steem != undefined ? values.steem : '',
      instagram: values.instagram != undefined ? values.instagram : '',
      wechat: values.wechat != undefined ? values.wechat : '',
      group: values.group != undefined ? values.group : '',
      discord: values.discord != undefined ? values.discord : '',
    }

    Object.keys(values).map((key, i) => (values[key] == undefined ? (values[key] = '') : ''))

    values.file_logo = this.state.file
    values.private_key = this.state.defaultPriv.priv
    values.position_win_first = this.state.positionWinFirst
    values.token_record_type = ''
    this.props.updateTokenRecord(values, 3, this.props.tokenRecord.type_choose)
  }
  showModal = () => {
    this.setState({
      visible_upload: true,
    })
  }

  handleCancel = () => {
    this.setState({ visible_upload: false })
  }

  handleCancelErrorPixel = () => {
    this.setState({ visible_error_pixel: false })
  }

  handleCancelErrorSize = () => {
    this.setState({ visible_error_size: false })
  }

  handleCancelErrorEmptyAdd = () => {
    this.setState({ visible_error_empty_addr: false })
  }

  handleCancelErrorFileType = () => {
    this.setState({ visible_error_file_type: false })
  }
  render() {
    const {
      visible_upload,
      visible_error_pixel,
      visible_error_size,
      visible_error_empty_addr,
      visible_error_file_type,
      imageUrl,
      REACT_APP_API_S3_UPLOAD_FEE,
    } = this.state
    const { tokenRecord } = this.props
    const current = tokenRecord.current_url
    const type_choose =
      tokenRecord.data.token_record_type != ''
        ? tokenRecord.data.token_record_type == 'WRC20'
          ? (tokenRecord.type_choose = 1)
          : tokenRecord.data.token_record_type == 'WRC721'
          ? (tokenRecord.type_choose = 2)
          : (tokenRecord.type_choose = 3)
        : tokenRecord.type_choose

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Token Logo</div>
      </div>
    )

    if (current == 1) {
      return <Redirect to={window.location.pathname.replace('/Record', '') + '/Type'} />
    } else if (current == 3) {
      return <Redirect to={window.location.pathname.replace('/Record', '') + '/Confirm'} />
    } else if (current == 4) {
      return <Redirect to={window.location.pathname.replace('/Record', '') + '/Done'} />
    }
    return (
      <StyledForm layout="vertical" size="large" onFinish={this.submitButton} ref={this.formRef}>
        <Modal
          visible={visible_upload}
          title={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '40px', color: '#DF711B' }} />{' '}
            </div>
          }
          closeIcon={<></>}
          maskClosable={false}
          onCancel={this.handleCancel}
          footer={[
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                key="back"
                onClick={() => {
                  this.inputFile.current.value = ''
                  this.setState({ visible_upload: false })
                }}
                type="default"
                style={{ padding: '0 35px' }}
                disabled={this.state.loadingSubmit}
              >
                Reject
              </Button>

              <Button
                key="back"
                type="primary"
                onClick={async () => await this.transfer()}
                style={{ padding: '0 35px' }}
                loading={this.state.loadingSubmit}
              >
                Confirm
              </Button>
            </div>,
          ]}
        >
          <p style={{ width: '100%', textAlign: 'center' }}>
            Upload token icon fee: {REACT_APP_API_S3_UPLOAD_FEE} WIN
          </p>
        </Modal>

        <Modal
          visible={visible_error_pixel}
          title={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '40px', color: '#DF711B' }} />{' '}
            </div>
          }
          closeIcon={<></>}
          onCancel={this.handleCancelErrorPixel}
          footer={[
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                key="back"
                onClick={() => {
                  this.setState({ visible_error_pixel: false })
                }}
                type="primary"
                style={{ padding: '0 35px' }}
              >
                Confirm
              </Button>
            </div>,
          ]}
        >
          <p style={{ width: '100%', textAlign: 'center' }}>Pixel limit: 100*100 px</p>
        </Modal>

        <Modal
          visible={visible_error_size}
          title={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '40px', color: '#DF711B' }} />{' '}
            </div>
          }
          closeIcon={<></>}
          onCancel={this.handleCancelErrorSize}
          footer={[
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                key="back"
                onClick={() => {
                  this.setState({ visible_error_size: false })
                }}
                type="primary"
                style={{ padding: '0 35px' }}
              >
                Confirm
              </Button>
            </div>,
          ]}
        >
          <p style={{ width: '100%', textAlign: 'center' }}>{'Size: <=200K'}</p>
        </Modal>

        <Modal
          visible={visible_error_empty_addr}
          title={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '40px', color: '#DF711B' }} />{' '}
            </div>
          }
          closeIcon={<></>}
          onCancel={this.handleCancelErrorEmptyAdd}
          footer={[
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                key="back"
                onClick={() => {
                  this.setState({ visible_error_empty_addr: false })
                }}
                type="primary"
                style={{ padding: '0 35px' }}
              >
                Confirm
              </Button>
            </div>,
          ]}
        >
          <p style={{ width: '100%', textAlign: 'center' }}>
            Please fill in the contract address before uploading the logo
          </p>
        </Modal>

        <Modal
          visible={visible_error_file_type}
          title={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '40px', color: '#DF711B' }} />{' '}
            </div>
          }
          closeIcon={<></>}
          onCancel={this.handleCancelErrorFileType}
          footer={[
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                key="back"
                onClick={() => {
                  this.setState({ visible_error_file_type: false })
                }}
                type="primary"
                style={{ padding: '0 35px' }}
              >
                Confirm
              </Button>
            </div>,
          ]}
        >
          <p style={{ width: '100%', textAlign: 'center' }}>Supported formats: png, jpg, jpeg</p>
        </Modal>

        <Layout>
          <Content>
            <>
              <TitleForm>Contract Information</TitleForm>
              <CustomDivider />
              <Row>
                <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                  <TitleContainer>
                    <ContentTitle>
                      <span style={{ color: 'red' }}>* </span>Contract Address:
                    </ContentTitle>
                  </TitleContainer>
                  <Item
                    name="contract_address"
                    rules={[
                      {
                        validator: async (_, value) => {
                          if (value !== undefined && value !== null && value !== '') {
                            var regex = new RegExp('^[W][a-zA-Z0-9]{33}$')
                            if (!regex.test(value)) {
                              return Promise.reject(
                                new Error('Consists of letters and numbers; starts with W')
                              )
                            }

                            if (!this.state.addressUpdate) {
                              const contract_data = await fetch(`${API_ADDR}/contracts/${value}`, {
                                method: 'GET',
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                                mode: 'cors',
                              })
                                .then((res) => res.json())
                                .then((res) => {
                                  return res
                                })
                                .catch((err) => {})
                              if (type_choose == 1) {
                                if (contract_data.data.decimals == undefined) {
                                  return Promise.reject(
                                    new Error('This address is not WRC20 contract address')
                                  )
                                }
                                if (contract_data.data.decimals == -1) {
                                  return Promise.reject(
                                    new Error('This address is not WRC20 contract address')
                                  )
                                }
                              }
                              if (type_choose == 2) {
                                if (contract_data.data.decimals == undefined) {
                                  return Promise.resolve()
                                }
                                if (contract_data.data.decimals != -1) {
                                  return Promise.reject(
                                    new Error('This address is not WRC721 contract address')
                                  )
                                }
                              }

                              const status = await fetch(`${API_ADDR}/tokenrecord/${value}`, {
                                method: 'GET',
                                headers: { 'Content-Type': 'application/json' },
                                mode: 'cors',
                              })
                                .then((res) => res.json())
                                .then((res) => {
                                  return res.status
                                })
                                .catch((err) => {})
                              if (status == 'success') {
                                return Promise.reject(
                                  new Error('The address has recorded tokens before')
                                )
                              }

                              if (contract_data.status == 'success') {
                                this.setState({
                                  timestamp_create: contract_data.data.create_time,
                                })

                                contract_data.data.totalSupply.length > 17
                                  ? this.setState({
                                      total_precision_temp: contract_data.data.totalSupply.slice(
                                        0,
                                        contract_data.data.totalSupply.length -
                                          contract_data.data.decimals
                                      ),
                                    })
                                  : this.setState({
                                      total_precision_temp: '',
                                    })
                                this.formRef.current.setFieldsValue({
                                  contract_name: contract_data.data.name,
                                  contract_creation_date: format(
                                    new Date(contract_data.data.create_time),
                                    'yyyy-MM-dd HH:mm:ss'
                                  ).toString(),
                                  contract_creator: contract_data.data.owner_addr,
                                  token_name:
                                    contract_data.data.name_token === undefined
                                      ? undefined
                                      : contract_data.data.name_token
                                          .trim()
                                          .replace(/[^\x0-\x7]/g, '')
                                          .replace(/[\u{0080}-\u{FFFF}]/gu, ''),
                                  token_abbreviation:
                                    contract_data.data.symbol_token === undefined
                                      ? undefined
                                      : contract_data.data.symbol_token
                                          .trim()
                                          .replace(/[^\x0-\x7]/g, '')
                                          .replace(/[\u{0080}-\u{FFFF}]/gu, ''),
                                  token_supply_without_precision:
                                    this.state.total_precision_temp == ''
                                      ? contract_data.data.totalSupply /
                                        Math.pow(10, contract_data.data.decimals)
                                      : this.state.total_precision_temp,
                                  precision: contract_data.data.decimals,
                                })
                              } else {
                                return Promise.reject(new Error('This contract dose not exist'))
                              }
                            }

                            this.setState({
                              contract_address: value,
                            })
                            return Promise.resolve()
                          } else {
                            return Promise.reject(
                              new Error('Consists of letters and numbers; starts with W')
                            )
                          }
                        },
                      },
                    ]}
                    initialValue={tokenRecord.data.contract_address}
                  >
                    <CustomInput
                      placeholder="Address of the contract for creating the token"
                      disabled={this.state.addressUpdate}
                    />
                  </Item>
                </Col>
                <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                  <TitleContainer>
                    <ContentTitle>Contract Name:</ContentTitle>
                  </TitleContainer>
                  <Item name="contract_name" initialValue={tokenRecord.data.contract_name}>
                    <CustomInput disabled placeholder="Contract name" />
                  </Item>
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                  <TitleContainer>
                    <ContentTitle>Contract creation date:</ContentTitle>
                  </TitleContainer>
                  <Item
                    name="contract_creation_date"
                    initialValue={tokenRecord.data.contract_creation_date}
                  >
                    <CustomInput disabled placeholder="Contract creation date" />
                  </Item>
                </Col>
                <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                  <TitleContainer>
                    <ContentTitle>Contract creator:</ContentTitle>
                  </TitleContainer>
                  <Item name="contract_creator" initialValue={tokenRecord.data.contract_creator}>
                    <CustomInput disabled placeholder="Contract creator" />
                  </Item>
                </Col>
              </Row>
            </>
            <TitleForm>Basic Information</TitleForm>
            <CustomDivider />
            <Row>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Token name:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="token_name"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          if (value.length < 2 || value.length > 30) {
                            return Promise.reject(new Error('2-30 characters for token name'))
                          }

                          return Promise.resolve()
                        } else {
                          return Promise.reject(new Error('Token name is required'))
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.token_name}
                >
                  <CustomInput disabled={true} placeholder="2-30 characters for token name" />
                </Item>
              </Col>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Token abbreviation:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="token_abbreviation"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          if (value.length < 2 || value.length > 10) {
                            this.setState({
                              token_abbr: '',
                            })
                            return Promise.reject(
                              new Error('2-10 characters for token abbreviation')
                            )
                          }
                          var regex = /^[a-zA-Z0-9]+$/
                          if (!regex.test(value)) {
                            return Promise.reject(
                              new Error('Token abbreviation has to contain letters and numbers')
                            )
                          }

                          this.setState({
                            token_abbr: value,
                          })
                          return Promise.resolve()
                        } else {
                          this.setState({
                            token_abbr: '',
                          })
                          return Promise.reject(new Error('Token abbreviation is required'))
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.token_abbreviation}
                >
                  <CustomInput
                    onKeyDown={(e) => e.keyCode === 32 && e.preventDefault()}
                    disabled={true}
                    placeholder="2-10 characters for token abbreviation"
                  />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Token introduction:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="token_introduction"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          if (value.length > 200) {
                            return Promise.reject(
                              new Error('Token introduction is not exceeding 200 characters')
                            )
                          }

                          return Promise.resolve()
                        } else {
                          return Promise.reject(new Error('Token introduction is required'))
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.token_introduction}
                >
                  <CustomTextArea
                    disabled={false}
                    placeholder="Controlled autosize"
                    autoSize={{ minRows: 3, maxRows: 4 }}
                    placeholder="Brief description of the purpose of the token, not exceeding 200 characters"
                  />
                </Item>
              </Col>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Total Supply:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="token_supply_without_precision"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          if (parseInt(value) < 0) {
                            return Promise.reject(
                              new Error('This field must be greater than or equal to 0')
                            )
                          }

                          return Promise.resolve()
                        } else {
                          return Promise.reject(new Error('Total supply is required'))
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.token_supply_without_precision}
                >
                  <CustomInput
                    onPaste={(e) => {
                      e.preventDefault()
                      return false
                    }}
                    onKeyDown={(e) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 190 ||
                        e.keyCode === 110 ||
                        e.keyCode === 189 ||
                        e.keyCode === 187 ||
                        e.keyCode === 107 ||
                        e.keyCode === 109) &&
                      e.preventDefault()
                    }
                    type="text"
                    disabled={true}
                    placeholder="Total token issuance (without precision)"
                  />
                </Item>
              </Col>
            </Row>
            <Row>
              {type_choose == 1 ? (
                <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                  <TitleContainer>
                    <ContentTitle>
                      <span style={{ color: 'red' }}>* </span>Precision:
                    </ContentTitle>
                  </TitleContainer>
                  <Item
                    name="precision"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value !== undefined && value !== null && value !== '') {
                            if (parseInt(value) < 1) {
                              this.setState({
                                precision_value: 1,
                              })
                              return Promise.reject(
                                new Error('This field must be greater than or equal to 1')
                              )
                            } else if (parseInt(value) > 18) {
                              this.setState({
                                precision_value: 18,
                              })
                              return Promise.reject(
                                new Error('This field must be less than or equal to 18')
                              )
                            }

                            this.setState({
                              precision_value: value,
                            })

                            return Promise.resolve()
                          } else {
                            return Promise.reject(new Error('Token precision is required'))
                          }
                        },
                      },
                    ]}
                    initialValue={tokenRecord.data.precision}
                  >
                    <CustomInput
                      onPaste={(e) => {
                        e.preventDefault()
                        return false
                      }}
                      onKeyDown={(e) =>
                        (e.keyCode === 69 ||
                          e.keyCode === 190 ||
                          e.keyCode === 189 ||
                          e.keyCode === 110 ||
                          e.keyCode === 187 ||
                          e.keyCode === 107 ||
                          e.keyCode === 109) &&
                        e.preventDefault()
                      }
                      type="number"
                      disabled={true}
                      placeholder="1-18"
                    />
                  </Item>
                </Col>
              ) : (
                <></>
              )}
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Issuer:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="issuer"
                  rules={[
                    {
                      required: true,
                      message: 'Issuer is required',
                    },
                  ]}
                  initialValue={
                    this.state.defaultPriv.address === undefined
                      ? ''
                      : this.state.defaultPriv.address
                  }
                >
                  <CustomInput disabled />
                </Item>
              </Col>
            </Row>

            <Row style={{ display: 'grid' }}>
              <TitleContainer>
                <ContentTitle>
                  Token logo{' '}
                  <span style={{ color: 'lightgray', fontSize: '12px' }}>
                    (Support format: png，jpg，jpeg ; Size limit: No more than 200K ; Pixel limit:
                    100)
                  </span>
                </ContentTitle>
              </TitleContainer>
              <Item name="tokenLogo">
                <input
                  style={{ display: 'none' }}
                  ref={this.inputFile}
                  onChange={async (e) => await this.handleFileUpload(e)}
                  type="file"
                  accept=".jpg,.jpeg,.png"
                />
                <div
                  class="ant-upload ant-upload-select ant-upload-select-picture-card"
                  onClick={() => this.onButtonClick()}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                  }}
                >
                  {imageUrl != '' && imageUrl != undefined ? (
                    <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                  ) : (
                    uploadButton
                  )}
                </div>
              </Item>
            </Row>

            <TitleForm>Social Media Information</TitleForm>
            <CustomDivider />
            <Row>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Project offical website:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="project_offical_website"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          if (!validator.isURL(value)) {
                            return Promise.reject(new Error('Incorrect format of the url'))
                          }

                          return Promise.resolve()
                        } else {
                          return Promise.reject(new Error('Web URL is required'))
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.project_offical_website}
                >
                  <CustomInput placeholder="Project offical website" />
                </Item>
              </Col>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Email:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="email"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          var regex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
                          if (!regex.test(value)) {
                            return Promise.reject(
                              new Error('Incorrect format of the email address')
                            )
                          }

                          return Promise.resolve()
                        } else {
                          return Promise.reject(new Error('Email is a required information'))
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.email}
                >
                  <CustomInput placeholder="Contact email" />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>Link for Github:</ContentTitle>
                </TitleContainer>
                <Item
                  name="link_for_github"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          if (!validator.isURL(value)) {
                            return Promise.reject(new Error('Incorrect format of the url'))
                          }

                          return Promise.resolve()
                        } else {
                          return Promise.resolve()
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.link_for_github}
                >
                  <CustomInput placeholder="Link for Github" />
                </Item>
              </Col>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>Link for white paper:</ContentTitle>
                </TitleContainer>
                <Item
                  name="link_for_white_paper"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          if (!validator.isURL(value)) {
                            return Promise.reject(new Error('Incorrect format of the url'))
                          }

                          return Promise.resolve()
                        } else {
                          return Promise.resolve()
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.link_for_white_paper}
                >
                  <CustomInput placeholder="Link for white paper" />
                </Item>
              </Col>
            </Row>
            <span>
              Click to add social media link(s){' '}
              {this.state.socialMedia.map((item) => {
                return (
                  <IconSocial
                    src={item.isOpen ? item.icon : item.iconGrey}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      item.isOpen = !item.isOpen
                      this.setState({
                        socialMedia: this.state.socialMedia,
                      })
                    }}
                  />
                )
              })}
            </span>
            <Row>
              {this.state.socialMedia.map((item) =>
                item.isOpen ? (
                  <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                    <TitleContainer>
                      <IconSocial src={item.icon} />
                      <ContentTitle>{item.name}:</ContentTitle>
                    </TitleContainer>
                    <Item
                      name={item.name.toLowerCase()}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value !== undefined && value !== null && value !== '') {
                              if (!validator.isURL(value)) {
                                return Promise.reject(new Error('Incorrect format of the url'))
                              }

                              return Promise.resolve()
                            } else {
                              return Promise.resolve()
                            }
                          },
                        },
                      ]}
                    >
                      <Input />
                    </Item>
                  </Col>
                ) : (
                  ''
                )
              )}
            </Row>
          </Content>
          <Footer style={{ textAlign: 'right' }}>
            {this.state.addressUpdate ? (
              ''
            ) : (
              <Button
                type="default"
                style={{ borderRadius: '0px', marginRight: '5px' }}
                onClick={() => {
                  this.props.updateTokenRecord(tokenRecord.data, 1, type_choose)
                }}
              >
                Previous
              </Button>
            )}

            <Button type="primary" htmlType="submit" style={{ borderRadius: '0px' }}>
              Next
            </Button>
          </Footer>
        </Layout>
      </StyledForm>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    contract: state.contract,
    tokenRecord: state.tokenRecord,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadContract: (addr) => {
      dispatch(loadContractApi(addr))
    },
    updateTokenRecord: (data, current_url, type_choose) => {
      dispatch(updateTokenRecord(data, current_url, type_choose))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  FormTokenRecordRC20RC721
)
