import React from 'react'
import { Table, Pagination, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  loadContracts,
  updatePageContracts,
  updatePageContractsLimit,
} from '../../actions/contracts'
import styled from 'styled-components'
import PageHeader from '../partials/pageHeader'
import TruncateMiddle from 'react-truncate-string'

import '../../styles/account.less'
import NumberFormat from 'react-number-format'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import { getTokenRecord } from '../../actions/tokenRecord'
import { API_ADDR } from '../../config/config'

const StyledLink = styled(Link)``

const Wrapper = styled.div`
  margin: 5px;
`
const Pagin = styled.div`
  margin-top: 15px;
  text-align: right !important;
`
const Token = styled.p`
  color: #c23631;
  font-size: 14px;
`
class ContractTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      listRecord20: [],
      listRecord721: [],
    }
  }
  async componentDidMount() {
    this.props.urlAtNotPrivFunc()
    const { contracts } = this.props
    this.props.loadContracts(contracts.contractPage.start_item, contracts.contractPage.page_limit)

    var result20 = await fetch(`${API_ADDR}/tokenrecord/list/WRC20`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'fail') {
          return []
        } else {
          return res.data
        }
      })
      .catch((err) => {
        return []
      })

    this.setState({
      listRecord20: result20,
    })

    var result721 = await fetch(`${API_ADDR}/tokenrecord/list/WRC721`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'fail') {
          return []
        } else {
          return res.data
        }
      })
      .catch((err) => {
        return []
      })

    this.setState({
      listRecord721: result721,
    })
  }

  onChange = (pageNumber, pageLimit) => {
    this.props.updatePageContracts(pageNumber)
    var { contracts } = this.props
    if (pageLimit !== contracts.contractPage.page_limit) {
      this.props.updatePageContractsLimit(pageLimit)
    }
    this.props.loadContracts(contracts.contractPage.start_item, contracts.contractPage.page_limit)
  }

  render() {
    var { contracts } = this.props
    const columns = [
      {
        title: 'No.',
        key: 'no',
        render: (value, item, index) =>
          index + 1 + (contracts.contractPage.current_page - 1) * contracts.contractPage.page_limit,
        width: '5%',
      },
      {
        title: 'Contract address',
        dataIndex: 'contract_address',
        key: 'contract_address',
        render: (text) => {
          return (
            <StyledLink
              key={text}
              to={
                '/' +
                (this.state.listRecord20.some((item) => text === item)
                  ? 'token20'
                  : this.state.listRecord721.some((item) => text === item)
                  ? 'token721'
                  : 'contract') +
                '/' +
                text
              }
            >
              <Tooltip placement="top" title={text}>
                <TruncateMiddle text={text} />
              </Tooltip>
            </StyledLink>
          )
        },
        width: '15%',
      },
      {
        title: 'Contract Type',
        dataIndex: 'contract_type',
        key: 'contract_type',
        width: '25%',
      },
      {
        title: 'Total Supply',
        key: 'supply',
        render: (record) => (
          <NumberFormat
            displayType={'text'}
            thousandSeparator={true}
            value={record.supply / record.pow_decimal}
          />
        ),
        width: '30%',
      },
      {
        title: 'Decimals',
        dataIndex: 'decimals',
        key: 'decimals',
        render: (text) => (
          <NumberFormat displayType={'text'} thousandSeparator={true} value={text} />
        ),
        width: '15%',
      },
      {
        title: 'Owner Address',
        dataIndex: 'owner_addr',
        render: (text) => (
          <StyledLink key={text} to={`/accounts/${text}`}>
            <Tooltip placement="top" title={text}>
              <TruncateMiddle text={text} />
            </Tooltip>
          </StyledLink>
        ),
        key: 'owner_addr',
        width: '15%',
      },
      {
        title: 'Token',
        key: 'token',
        render: (record) => (
          <Link key={record.contract_address} from="*" to={'/token20/' + record.contract_address}>
            <Token>
              {(record.name === undefined
                ? '–'
                : record.name
                    .trim()
                    .replace(/[^\x0-\x7]/g, '')
                    .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                  (record.symbol === undefined
                    ? ''
                    : ' (' +
                      record.symbol
                        .trim()
                        .replace(/[^\x0-\x7]/g, '')
                        .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                      ')')
              ).length === 3
                ? '–'
                : record.name === undefined
                ? '–'
                : record.name
                    .trim()
                    .replace(/[^\x0-\x7]/g, '')
                    .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                  (record.symbol === undefined
                    ? ''
                    : ' (' +
                      record.symbol
                        .trim()
                        .replace(/[^\x0-\x7]/g, '')
                        .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                      ')')}
            </Token>
          </Link>
        ),
        width: '25%',
      },
    ]
    return (
      <Wrapper>
        <PageHeader>List of Smart Contracts</PageHeader>
        <Table
          columns={columns}
          dataSource={contracts.contracts}
          rowKey="contract_address"
          scroll={{ x: 1300 }}
          sticky
          pagination={false}
          loading={contracts.contracts && contracts.contracts.length === 0 ? true : false}
          className="account-list"
        />
        <Pagin>
          <Pagination
            current={contracts.contractPage.start_page}
            total={contracts.contractPage.total_items}
            pageSize={contracts.contractPage.page_limit}
            onChange={this.onChange}
            showSizeChanger
            showQuickJumper
            className="account-pagi"
          />
        </Pagin>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    contracts: state.contracts,
    tokenRecord: state.tokenRecord,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updatePageContracts: (page) => {
      dispatch(updatePageContracts(page))
    },
    updatePageContractsLimit: (limit) => {
      dispatch(updatePageContractsLimit(limit))
    },
    loadContracts: (offset, limit) => {
      dispatch(loadContracts(offset, limit))
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
    getTokenRecord: (contract_addr) => {
      dispatch(getTokenRecord(contract_addr))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  ContractTable
)
