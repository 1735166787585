import React from 'react'
import {
  Row,
  Space,
  Button,
  Modal,
  notification,
  Form,
  Input,
  Result,
  Skeleton,
  Spin,
  Checkbox,
} from 'antd'
import styled from 'styled-components'
import { LikeTwoTone, BankTwoTone, TrophyTwoTone } from '@ant-design/icons'
import { connect } from 'react-redux'
import { getDefaulteyFromToken } from '../../common/commonTransaction'
import { loadBecomeWitness } from '../../actions/becomeWitness'
import { loadAddressApi } from '../../actions/address'
import IconInfo from '../../assets/images/iconInfo.png'
import { initTransactionDetail, loadTransactionDetails } from '../../actions/transaction'

const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 20px 40px 20px;
  border: 1px solid #dedede;
`
const StyledSpace = styled(Space)`
  margin-bottom: 25px;
  .anticon {
    font-size: 24px;
  }
`
const StyledButton = styled(Button)`
  margin-top: 30px;
`
const StyledSpaceModal = styled(Space)`
  width: 100%;
  justify-content: center;
  margin-top: 15px;
  button {
    width: 200px;
  }
`
const Note = styled.div`
  font-size: 13px;
  border-bottom: 1px solid #dedede;
  padding-bottom: 25px;
  margin-bottom: 20px;
`

class Witness extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultPriv: getDefaulteyFromToken(),
      isModalVisible: false,
      loading: false,
      checkUrl: false,
      checked: false,
    }
  }

  formRef = React.createRef()

  componentDidMount() {
    if (!this.state.defaultPriv.priv) {
      notification.warning({
        message: 'Warning!',
        description:
          'You have no private key, please add somes in private key management to perform transaction!',
      })
    } else {
      this.props.loadAddress(this.state.defaultPriv.address)
    }
    this.props.initTransactionDetail()
  }

  componentDidUpdate(prevProps) {
    const { txid, loading, message } = this.props.becomeWitness
    const { hash } = this.props.transaction
    if (txid !== prevProps.becomeWitness.txid && !loading) {
      this.props.loadTransactionDetails(txid, 5)
      this.handleCancel()
    }
    if (hash !== prevProps.transaction.hash && !this.props.transaction.loading) {
      this.setState({ loading: false })
      notification.success({
        message: 'Successfully',
        description:
          'Applied to be Super Representative Candidate. Your account will be upgraded shortly',
      })
      this.props.loadAddress(this.state.defaultPriv.address)
    }
    if (message !== prevProps.becomeWitness.message && !loading) {
      notification.error({
        message: 'An error has occurred',
        description: message,
      })
      this.setState({ loading: false })
    }
  }

  showInfo = () => {
    Modal.info({
      title: <img src={IconInfo} />,
      content:
        'It costs 9,999 WIN to apply for a Super Representative, and the balance is insufficient',
      okText: 'Understood',
      icon: '',
      className: 'modal-become-witness',
      onOk() {},
    })
  }

  showForm = () => {
    this.setState({ isModalVisible: true, checkUrl: false, checked: false })
  }

  handleCancel = () => {
    this.setState({ isModalVisible: false })
    this.formRef.current.resetFields()
  }

  onFieldsChange = (changedFields) => {
    changedFields[0].errors.length
      ? this.setState({ checkUrl: false })
      : this.setState({ checkUrl: true })
  }

  handleFinish = (values) => {
    this.setState({ loading: true })
    this.props.loadBecomeWitness({
      owner_address: this.state.defaultPriv.address,
      url: values.url,
      private_key: this.state.defaultPriv.priv,
    })
  }

  render() {
    const { is_witness, trxbalance } = this.props.address
    const available = trxbalance ? (Object.keys(trxbalance).length ? trxbalance.available : 0) : 0
    return (
      <Wrapper>
        {is_witness === undefined ? (
          <Skeleton />
        ) : is_witness ? (
          <Result status="success" title="You are already a Witness!" />
        ) : (
          <Spin spinning={this.state.loading}>
            <Modal
              title="Super Representatives"
              visible={this.state.isModalVisible}
              onCancel={this.handleCancel}
              footer={null}
            >
              <Note>
                All WIN-holders can apply to be SR candidates. The 27 most voted candidates will
                become SRs, and the top 28 - 127 candidates will become super partners. SRs are
                responsible for packing transactions and generating blocks. They are also entitled
                to block rewards and voting rewards. Super partners do not pack transaction or
                generate blocks and are only eligible for voting rewards. All SR candidates, super
                partners and SRs can raise proposals to modify parameters on the WINUPS network.
              </Note>
              <Form
                ref={this.formRef}
                labelCol={{ span: 24 }}
                onFieldsChange={this.onFieldsChange}
                onFinish={this.handleFinish}
              >
                <Form.Item
                  label="Please enter your URL"
                  name="url"
                  rules={[
                    { required: true, message: 'URL cannot be empty!' },
                    {
                      pattern:
                        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                      message: 'Invalid URL!',
                    },
                  ]}
                >
                  <Input placeholder="Link to project website or social media page" />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    checked={this.state.checked}
                    onChange={(e) => this.setState({ checked: e.target.checked })}
                  >
                    I understand how to be a WINUPS representative and I confirm to spend a 9,999
                    WIN fee.
                  </Checkbox>
                </Form.Item>
                <StyledSpaceModal>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!this.state.checkUrl || !this.state.checked}
                  >
                    Submit
                  </Button>
                </StyledSpaceModal>
              </Form>
            </Modal>
            <StyledSpace size="large">
              <LikeTwoTone twoToneColor="#FCB040" />
              <span>
                Every token holder has the opportunity to become a Winups Super Representative.
                After spending an application fee of 9,999 WIN, you become a Super Representative
                candidate. The Super Representative candidate can accept the user’s vote.
              </span>
            </StyledSpace>
            <StyledSpace size="large">
              <BankTwoTone twoToneColor="#FCB040" />
              <span>
                The Top 27 most-voted partners will become SRs and be responsible for transaction
                packaging and block generation, with a reward of 16 WIN per block generation.
              </span>
            </StyledSpace>
            <StyledSpace size="large">
              <TrophyTwoTone twoToneColor="#FCB040" />
              <span>
                The 27 SRs and the top 100 SR partners can also receive rewards of nodes based on
                the votes they receive.
              </span>
            </StyledSpace>
            <Row justify="center">
              <StyledButton
                type="primary"
                onClick={Number(available) / Math.pow(10, 6) < 9999 ? this.showInfo : this.showForm}
              >
                Apply to be a Super Representative
              </StyledButton>
            </Row>
          </Spin>
        )}
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    becomeWitness: state.becomeWitness,
    address: state.address,
    transaction: state.transaction,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadAddress: (address) => {
      dispatch(loadAddressApi(address))
    },
    loadBecomeWitness: (params) => {
      dispatch(loadBecomeWitness(params))
    },
    initTransactionDetail: () => {
      dispatch(initTransactionDetail())
    },
    loadTransactionDetails: (txid, timeToTry) => {
      dispatch(loadTransactionDetails(txid, timeToTry))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Witness)
