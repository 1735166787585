import React from 'react'
import { Button, Row, Modal, Typography } from 'antd'
import QRCode from 'qrcode.react'
import styled from 'styled-components'

const { Paragraph } = Typography

const Address = styled.div`
  background-color: #f0f0f0;
  padding: 15px;
  margin: 15px 0 30px 0;
  .ant-typography {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
  }
`
const SaveQrCode = styled(Button)`
  width: 100%;
  margin-top: 30px;
`

class AccountQrCode extends React.Component {
  constructor(props) {
    super(props)
  }

  downloadQrCode = () => {
    const qrCodeUrl = document
      .querySelector('#qrCodeEl')
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    const aEl = document.createElement('a')
    aEl.href = qrCodeUrl
    aEl.download = `${this.props.address}.png`
    document.body.appendChild(aEl)
    aEl.click()
    document.body.removeChild(aEl)
  }

  render() {
    const { showQrCode, hideQrCode, address } = this.props
    return (
      <Modal
        visible={showQrCode}
        title="Account QR Code"
        onCancel={hideQrCode}
        centered
        zIndex={1050}
        footer={null}
      >
        Wallet Address
        <Address>
          <Paragraph copyable={{ tooltips: ['Copy to Clipboard', 'Copied'] }}>{address}</Paragraph>
        </Address>
        <Row align="middle" justify="center" gutter={[8, 8]}>
          <QRCode value={address} size={200} renderAs="canvas" id="qrCodeEl" />
        </Row>
        <SaveQrCode type="primary" size="large" onClick={this.downloadQrCode}>
          Save QR Code
        </SaveQrCode>
      </Modal>
    )
  }
}

export default AccountQrCode
