import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableRow, Th, StyledLink } from './style'
import { decimalFormat, currencyFormat } from '../../utils/utils'
import { GLOBAL_SYMBOL } from '../../constant'

class ContractOverview extends Component {
  render() {
    let { contract, contract_record } = this.props
    return (
      <tbody>
        <TableRow>
          <Th>
            <span>Name:</span>
          </Th>
          <td>
            <span>{contract.name}</span>
          </td>
        </TableRow>
        <TableRow>
          <Th>
            <span>Balance:</span>
          </Th>
          <td>
            <span>
              {currencyFormat(decimalFormat(contract.balance / Math.pow(10, 6)))} {GLOBAL_SYMBOL}
            </span>
          </td>
        </TableRow>
        <TableRow>
          <Th>
            <span>Transactions:</span>
          </Th>
          <td>
            <span>{contract.transactions}</span>
          </td>
        </TableRow>
        {contract.name_token != undefined ? (
          contract.name_token
            .trim()
            .replace(/[^\x0-\x7]/g, '')
            .replace(/[\u{0080}-\u{FFFF}]/gu, '').length <= 30 ? (
            <>
              <TableRow>
                <Th>
                  <span>Token name:</span>
                </Th>
                <td style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  <span>
                    {contract.name_token != undefined
                      ? contract.name_token
                          .trim()
                          .replace(/[^\x0-\x7]/g, '')
                          .replace(/[\u{0080}-\u{FFFF}]/gu, '')
                      : '–'}
                  </span>
                </td>
              </TableRow>
              <TableRow>
                <Th>
                  <span>Symbol:</span>
                </Th>
                <td>
                  <span>
                    {contract.symbol_token != undefined
                      ? contract.symbol_token
                          .trim()
                          .replace(/[^\x0-\x7]/g, '')
                          .replace(/[\u{0080}-\u{FFFF}]/gu, '')
                      : '–'}
                  </span>
                </td>
              </TableRow>
            </>
          ) : contract.decimals == -1 ? (
            ''
          ) : (
            <TableRow>
              <Th>
                <span>Decimals:</span>
              </Th>
              <td>
                <span>{contract.decimals}</span>
              </td>
            </TableRow>
          )
        ) : (
          <TableRow>
            <Th>
              <span>Decimals:</span>
            </Th>
            <td>
              <span>{contract.decimals}</span>
            </td>
          </TableRow>
        )}

        {contract_record.contract_address != undefined ? (
          <TableRow>
            <Th>
              <span>Token Tracker:</span>
            </Th>
            <td>
              <StyledLink
                to={
                  '/' +
                  (contract_record.token_record_type == 'WRC20' ? 'token20' : 'token721') +
                  '/' +
                  contract_record.contract_address
                }
              >
                {contract_record.token_name != undefined
                  ? contract_record.token_name
                      .trim()
                      .replace(/[^\x0-\x7]/g, '')
                      .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                    (contract_record.token_abbreviation != undefined
                      ? ' (' +
                        contract_record.token_abbreviation
                          .trim()
                          .replace(/[^\x0-\x7]/g, '')
                          .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                        ')'
                      : '')
                  : '–'}
              </StyledLink>
            </td>
          </TableRow>
        ) : (
          ''
        )}
      </tbody>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    contract: state.contract,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  ContractOverview
)
