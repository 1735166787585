import { Col, Menu, Row, Tabs } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ArrowRightOutlined } from '@ant-design/icons'
import { updateTokenRecord } from '../../actions/tokenRecord'
class RecordMenu extends Component {
  componentDidMount() {}

  render() {
    const { tokenRecord } = this.props
    const current = tokenRecord.current_url
    return (
      <Row style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
        <Col span={6}>
          <div
            style={{
              lineHeight: '55px',
              textAlign: 'center',
              border: '0.2px solid #333',
              background: current >= 1 ? '#333' : '#fff',
              color: current < 1 ? '#333' : '#fff',
            }}
          >
            Select Type
            <ArrowRightOutlined
              style={{ marginLeft: '10px', transform: current === 1 ? 'rotate(45deg)' : '' }}
            />
          </div>
        </Col>
        <Col span={6}>
          <div
            style={{
              lineHeight: '55px',
              textAlign: 'center',
              border: '0.2px solid #333',
              background: current >= 2 ? '#333' : '#fff',
              color: current < 2 ? '#333' : '#fff',
            }}
          >
            Record Information
            <ArrowRightOutlined
              style={{ marginLeft: '10px', transform: current === 2 ? 'rotate(45deg)' : '' }}
            />
          </div>
        </Col>
        <Col span={6}>
          <div
            style={{
              lineHeight: '55px',
              textAlign: 'center',
              border: '0.2px solid #333',
              background: current >= 3 ? '#333' : '#fff',
              color: current < 3 ? '#333' : '#fff',
            }}
          >
            Confirm Information
            <ArrowRightOutlined
              style={{ marginLeft: '10px', transform: current === 3 ? 'rotate(45deg)' : '' }}
            />
          </div>
        </Col>
        <Col span={6}>
          <div
            style={{
              lineHeight: '55px',
              textAlign: 'center',
              border: '0.2px solid #333',
              background: current >= 4 ? '#333' : '#fff',
              color: current < 4 ? '#333' : '#fff',
            }}
          >
            View Result
            <ArrowRightOutlined
              style={{ marginLeft: '10px', transform: current === 4 ? 'rotate(45deg)' : '' }}
            />
          </div>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tokenRecord: state.tokenRecord,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateTokenRecord: (data, from_addr, current_url, type_choose) => {
      dispatch(updateTokenRecord(data, from_addr, current_url, type_choose))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RecordMenu)
