import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Avatar,
  Upload,
  Modal,
  notification,
} from 'antd'
import IconTwitter from '../../assets/images/iconTwitter.png'
import IconTwitterGrey from '../../assets/images/iconTwitterGrey.png'
import IconTelegram from '../../assets/images/iconTelegram.png'
import IconTelegramGrey from '../../assets/images/iconTelegramGrey.png'
import IconFacebook from '../../assets/images/iconFacebook.png'
import IconFacebookGrey from '../../assets/images/iconFacebookGrey.png'
import IconWeibo from '../../assets/images/iconWeibo.png'
import IconWeiboGrey from '../../assets/images/iconWeiboGrey.png'
import IconReddit from '../../assets/images/iconReddit.png'
import IconRedditGrey from '../../assets/images/iconRedditGrey.png'
import IconSteem from '../../assets/images/iconSteem.png'
import IconSteemGrey from '../../assets/images/iconSteemGrey.png'
import IconMedium from '../../assets/images/iconMedium.png'
import IconMediumGrey from '../../assets/images/iconMediumGrey.png'
import IconInstagram from '../../assets/images/iconInstagram.png'
import IconInstagramGrey from '../../assets/images/iconInstagramGrey.png'
import IconWechat from '../../assets/images/iconWechat.png'
import IconWechatGrey from '../../assets/images/iconWechatGrey.png'
import IconGroup from '../../assets/images/iconGroup.png'
import IconGroupGrey from '../../assets/images/iconGroupGrey.png'
import IconDiscord from '../../assets/images/iconDiscord.png'
import IconDiscordGrey from '../../assets/images/iconDiscordGrey.png'
import IconUploadImage from '../../assets/images/uploadImage.png'
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { API_ADDR } from '../../config/config'
import { getDefaulteyFromToken } from '../../common/commonTransaction'
import { Redirect } from 'react-router'
import { updateTokenRecord } from '../../actions/tokenRecord'
import validator from 'validator'
import { SwapOutlined, InfoCircleOutlined } from '@ant-design/icons'
import Asset from '../../api/asset'
import NumberFormat from 'react-number-format'
import ConfigKey from '../../api/key'
import './style.css'
import BigNumber from 'bignumber.js'

const { TextArea } = Input

const Item = styled(Form.Item)`
  font-weight: bold;
`
const ContentTitle = styled.span`
  font-weight: 400;
  margin-left: 7px;
`
const TitleContainer = styled.div`
  text-align: left;
`
const StyledForm = styled(Form)``
const IconSocial = styled(Avatar)`
  margin: 5px;
  width: 20px;
  height: 20px;
`
const TitleForm = styled.p`
  margin-top: 1em;
  margin-bottom: 1em;
`
const CustomDivider = styled(Divider)`
  margin-top: 0;
  margin-bottom: 1em;
`
const CustomInput = styled(Input)`
  ::placeholder {
    font-size: 12px;
  }
`
const CustomTextArea = styled(TextArea)`
  ::placeholder {
    font-size: 12px;
  }
`

const { Footer, Content } = Layout

class FormTokenRecordRC10 extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.inputFile = React.createRef()
    this.state = {
      defaultPriv: getDefaulteyFromToken(),
      logo_name: '',
      imageUrl: undefined,
      contract_address: '',
      total_precision_temp: '',
      token_abbr: '',
      loadingSubmit: false,
      precision_value: undefined,
      token_supply_without_precision: 0,
      error_token_supply_without_precision: undefined,
      timestamp_create: 0,
      price_not_win: -1,
      price_win: -1,
      file: null,
      visible_upload: false,
      visible_error_pixel: false,
      visible_error_size: false,
      visible_error_empty_addr: false,
      visible_error_file_type: false,
      colorIconSwap: '#000',
      addressUpdate: false,
      positionWinFirst: false,

      previewVisible: false,
      previewImage: '',
      fileList: [],

      REACT_APP_API_ADDRESS_ADMIN: '',
      REACT_APP_API_S3_UPLOAD_FEE: 0,
      socialMedia: [
        {
          name: 'Twitter',
          isOpen: true,
          icon: IconTwitter,
          iconGrey: IconTwitterGrey,
        },
        {
          name: 'Telegram',
          isOpen: true,
          icon: IconTelegram,
          iconGrey: IconTelegramGrey,
        },
        {
          name: 'Facebook',
          isOpen: true,
          icon: IconFacebook,
          iconGrey: IconFacebookGrey,
        },
        {
          name: 'Weibo',
          isOpen: true,
          icon: IconWeibo,
          iconGrey: IconWeiboGrey,
        },
        {
          name: 'Reddit',
          isOpen: false,
          icon: IconReddit,
          iconGrey: IconRedditGrey,
        },
        {
          name: 'Steem',
          isOpen: false,
          icon: IconSteem,
          iconGrey: IconSteemGrey,
        },
        {
          name: 'Medium',
          isOpen: false,
          icon: IconMedium,
          iconGrey: IconMediumGrey,
        },
        {
          name: 'Instagram',
          isOpen: false,
          icon: IconInstagram,
          iconGrey: IconInstagramGrey,
        },
        {
          name: 'Wechat',
          isOpen: false,
          icon: IconWechat,
          iconGrey: IconWechatGrey,
        },
        {
          name: 'Group',
          isOpen: false,
          icon: IconGroup,
          iconGrey: IconGroupGrey,
        },
        {
          name: 'Discord',
          isOpen: false,
          icon: IconDiscord,
          iconGrey: IconDiscordGrey,
        },
      ],
    }
  }

  async componentDidMount() {
    BigNumber.config({ EXPONENTIAL_AT: 1e9 })

    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', (event) => {
      if (!this.state.addressUpdate) {
        window.location.href = '/token-record/create/Type'
      }
    })
    const { tokenRecord } = this.props
    const { socialMedia } = this.state

    var precision_temp = ''
    for (let index = 0; index < parseInt(tokenRecord.data.precision); index++) {
      precision_temp += '0'
    }

    this.setState({
      positionWinFirst: tokenRecord.data.position_win_first,
      imageUrl: tokenRecord.data.token_icon,
      contract_address: tokenRecord.data.contract_address,
      price_not_win: tokenRecord.data.price_usd,
      price_win: tokenRecord.data.price_win,
      token_supply_without_precision: tokenRecord.data.token_supply_without_precision,
      precision_value: tokenRecord.data.precision,
      logo_name:
        this.state.defaultPriv.address === undefined
          ? ''
          : this.state.defaultPriv.address + String(new Date().getTime()),
    })

    if (window.location.pathname.split('/')[1] != 'token-record') {
      this.setState({
        addressUpdate: true,
      })
      if (tokenRecord.data.token_supply_without_precision == undefined) {
        this.formRef.current.setFieldsValue({
          token_supply_without_precision: 0,
        })
      }
    } else {
      this.setState({
        addressUpdate: false,
      })
    }
    Object.keys(tokenRecord.data.social_media_link).map((key, i) =>
      socialMedia.map((val) => {
        if (key == val.name.toLowerCase()) {
          if (tokenRecord.data.social_media_link[key] != '') {
            if (key == 'facebook') {
              this.formRef.current.setFieldsValue({
                facebook: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'twitter') {
              this.formRef.current.setFieldsValue({
                twitter: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'telegram') {
              this.formRef.current.setFieldsValue({
                telegram: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'weibo') {
              this.formRef.current.setFieldsValue({
                weibo: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'reddit') {
              this.formRef.current.setFieldsValue({
                reddit: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'steem') {
              this.formRef.current.setFieldsValue({
                steem: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'medium') {
              this.formRef.current.setFieldsValue({
                medium: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'instagram') {
              this.formRef.current.setFieldsValue({
                instagram: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'wechat') {
              this.formRef.current.setFieldsValue({
                wechat: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'group') {
              this.formRef.current.setFieldsValue({
                group: tokenRecord.data.social_media_link[key],
              })
            } else if (key == 'discord') {
              this.formRef.current.setFieldsValue({
                discord: tokenRecord.data.social_media_link[key],
              })
            }
            val.isOpen = true
          } else {
            val.isOpen = false
            if (key == 'facebook') {
              val.isOpen = true
            } else if (key == 'twitter') {
              val.isOpen = true
            } else if (key == 'telegram') {
              val.isOpen = true
            } else if (key == 'weibo') {
              val.isOpen = true
            }
          }
        }
      })
    )
    const config_key = await ConfigKey.getkey()

    this.setState({
      REACT_APP_API_ADDRESS_ADMIN: config_key.REACT_APP_API_ADDRESS_ADMIN,
      REACT_APP_API_S3_UPLOAD_FEE: config_key.REACT_APP_API_S3_UPLOAD_FEE,
    })
  }

  onButtonClick() {
    this.inputFile.current.click()
  }

  handlePreview = (file) => {
    this.setState({
      previewImage: file.thumbUrl,
      previewVisible: true,
    })
  }
  handleUpload = async (file_update) => {
    const file = file_update.file
    if (file.type != 'image/jpg' && file.type != 'image/jpeg' && file.type != 'image/png') {
      this.setState({
        visible_error_file_type: true,
      })
      return
    }

    {
      if (file.size < 200000) {
        const promises = []
        const promise = new Promise((resolve, reject) => {
          const image = new Image()
          let url
          image.onload = function () {
            file.width = image.width
            file.height = image.height
            resolve(file)
          }
          url = URL.createObjectURL(file)
          image.src = url
        })
        promises.push(promise)

        const promises_file = await Promise.all(promises)
        if (
          promises_file[0].width &&
          promises_file[0].width == 100 &&
          promises_file[0].height &&
          promises_file[0].height == 100
        ) {
          this.setState({
            visible_upload: true,
            file: file,
          })
        } else {
          this.setState({
            visible_error_pixel: true,
          })
        }
      } else {
        this.setState({
          visible_error_size: true,
        })
      }
    }
  }
  confirmUploadLogoToS3 = async () => {
    const { REACT_APP_API_ADDRESS_ADMIN, REACT_APP_API_S3_UPLOAD_FEE } = this.state
    const privatekey = this.state.defaultPriv.priv
    const amount = REACT_APP_API_S3_UPLOAD_FEE
    let temp = ''

    this.setState({
      loadingSubmit: true,
    })
    if (this.state.defaultPriv != undefined) {
      if (this.state.defaultPriv.address != undefined) {
        temp = this.state.defaultPriv.address
      }
    }
    var num_after_split = String(parseFloat(amount)).split('.')

    let precision = 6

    if (num_after_split.length == 2) {
      precision -= num_after_split[1].length
    }
    var amount_temp = String(parseFloat(amount)).replace('.', '')

    for (let idx = 0; idx < precision; idx++) {
      amount_temp += '0'
    }

    const res1 = await Asset.transfer(
      privatekey,
      REACT_APP_API_ADDRESS_ADMIN,
      parseInt(amount_temp),
      'WIN'
    )
    if (!res1.result) {
      notification.error({
        message: 'Failed!',
        description: 'Wallet has insufficient balance',
      })
      this.setState({
        loadingSubmit: false,
      })
    } else {
      var fetchData
      var time = 0
      var status_trans = ''

      async function checkTransactionStatus() {
        if (status_trans == 'SUCCESS') {
          const objectUrl = URL.createObjectURL(this.state.file)
          this.setState({
            imageUrl: objectUrl,
            visible_upload: false,
            loadingSubmit: false,
          })
          clearInterval(fetchData)
        } else {
          if (time === 3) {
            notification.error({
              message: 'Failed!',
              description: status_trans,
            })
            this.setState({
              loadingSubmit: false,
              imageUrl: undefined,
            })
            clearInterval(fetchData)
          }
        }
        status_trans = await fetch(`${API_ADDR}/transactions/${res1.tranID}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
        })
          .then((res_trans) => res_trans.json())
          .then((res_trans) => {
            if (res_trans.data.ret == 'SUCESS' || res_trans.data.ret == 'SUCCESS') {
              return 'SUCCESS'
            } else {
              return res_trans.message
            }
          })
          .catch((err) => {
            return 'Wallet has insufficient balance'
          })
        time++
      }
      fetchData = setInterval(checkTransactionStatus.bind(this), 3000)
    }
  }

  submitButton = (values) => {
    if (
      values.token_supply_without_precision == undefined ||
      values.token_supply_without_precision == ''
    ) {
      this.setState({
        error_token_supply_without_precision: 'Total supply is required',
      })
      return
    }
    values.contract_creation_date = this.state.timestamp_create
    values.from_address = this.state.defaultPriv.address
    values.token_icon = this.state.imageUrl

    values.social_media_link = {
      twitter: values.twitter != undefined ? values.twitter : '',
      facebook: values.facebook != undefined ? values.facebook : '',
      telegram: values.telegram != undefined ? values.telegram : '',
      weibo: values.weibo != undefined ? values.weibo : '',
      reddit: values.reddit != undefined ? values.reddit : '',
      medium: values.medium != undefined ? values.medium : '',
      steem: values.steem != undefined ? values.steem : '',
      instagram: values.instagram != undefined ? values.instagram : '',
      wechat: values.wechat != undefined ? values.wechat : '',
      group: values.group != undefined ? values.group : '',
      discord: values.discord != undefined ? values.discord : '',
    }

    Object.keys(values).map((key, i) => (values[key] == undefined ? (values[key] = '') : ''))

    values.file_logo = this.state.file
    values.private_key = this.state.defaultPriv.priv
    values.position_win_first = this.state.positionWinFirst
    values.token_record_type = ''
    this.props.updateTokenRecord(values, 3)
  }
  showModal = () => {
    this.setState({
      visible_upload: true,
    })
  }

  handleCancel = () => {
    this.setState({ visible_upload: false })
  }

  handleCancelErrorPixel = () => {
    this.setState({ visible_error_pixel: false })
  }

  handleCancelErrorSize = () => {
    this.setState({ visible_error_size: false })
  }

  handleCancelErrorEmptyAdd = () => {
    this.setState({ visible_error_empty_addr: false })
  }

  handleCancelErrorFileType = () => {
    this.setState({ visible_error_file_type: false })
  }
  onFinishFailed = () => {
    const { token_supply_without_precision } = this.state
    if (
      token_supply_without_precision == undefined ||
      token_supply_without_precision == null ||
      token_supply_without_precision == ''
    ) {
      this.setState({
        error_token_supply_without_precision: `Total supply is requied.`,
      })
    }
  }

  render() {
    const {
      visible_upload,
      visible_error_pixel,
      visible_error_size,
      visible_error_empty_addr,
      visible_error_file_type,
      imageUrl,
      error_token_supply_without_precision,
      token_supply_without_precision,
      precision_value,
      REACT_APP_API_S3_UPLOAD_FEE,
    } = this.state
    const { tokenRecord } = this.props
    const current = tokenRecord.current_url

    const { fileList } = this.state

    if (current == 1) {
      return <Redirect to={window.location.pathname.replace('/Record', '') + '/Type'} />
    } else if (current == 3) {
      return <Redirect to={window.location.pathname.replace('/Record', '') + '/Confirm'} />
    } else if (current == 4) {
      return <Redirect to={window.location.pathname.replace('/Record', '') + '/Done'} />
    }
    return (
      <StyledForm
        layout="vertical"
        size="large"
        onFinish={this.submitButton}
        ref={this.formRef}
        onFinishFailed={this.onFinishFailed}
      >
        <Modal
          visible={visible_upload}
          title={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '40px', color: '#DF711B' }} />{' '}
            </div>
          }
          closeIcon={<></>}
          maskClosable={false}
          onCancel={this.handleCancel}
          footer={[
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                key="back"
                onClick={() => {
                  this.setState({ visible_upload: false })
                }}
                type="default"
                style={{ padding: '0 35px' }}
                disabled={this.state.loadingSubmit}
              >
                Reject
              </Button>

              <Button
                key="back"
                type="primary"
                onClick={async () => await this.confirmUploadLogoToS3()}
                style={{ padding: '0 35px' }}
                loading={this.state.loadingSubmit}
              >
                Confirm
              </Button>
            </div>,
          ]}
        >
          <p style={{ width: '100%', textAlign: 'center' }}>
            Upload token icon fee: {REACT_APP_API_S3_UPLOAD_FEE} WIN
          </p>
        </Modal>

        <Modal
          visible={visible_error_pixel}
          title={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '40px', color: '#DF711B' }} />{' '}
            </div>
          }
          closeIcon={<></>}
          onCancel={this.handleCancelErrorPixel}
          footer={[
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                key="back"
                onClick={() => {
                  this.setState({ visible_error_pixel: false })
                }}
                type="primary"
                style={{ padding: '0 35px' }}
              >
                Confirm
              </Button>
            </div>,
          ]}
        >
          <p style={{ width: '100%', textAlign: 'center' }}>Pixel limit: 100*100 px</p>
        </Modal>

        <Modal
          visible={visible_error_size}
          title={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '40px', color: '#DF711B' }} />{' '}
            </div>
          }
          closeIcon={<></>}
          onCancel={this.handleCancelErrorSize}
          footer={[
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                key="back"
                onClick={() => {
                  this.setState({ visible_error_size: false })
                }}
                type="primary"
                style={{ padding: '0 35px' }}
              >
                Confirm
              </Button>
            </div>,
          ]}
        >
          <p style={{ width: '100%', textAlign: 'center' }}>{'Size: <=200K'}</p>
        </Modal>

        <Modal
          visible={visible_error_empty_addr}
          title={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '40px', color: '#DF711B' }} />{' '}
            </div>
          }
          closeIcon={<></>}
          onCancel={this.handleCancelErrorEmptyAdd}
          footer={[
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                key="back"
                onClick={() => {
                  this.setState({ visible_error_empty_addr: false })
                }}
                type="primary"
                style={{ padding: '0 35px' }}
              >
                Confirm
              </Button>
            </div>,
          ]}
        >
          <p style={{ width: '100%', textAlign: 'center' }}>
            Please fill in the contract address before uploading the logo
          </p>
        </Modal>

        <Modal
          visible={visible_error_file_type}
          title={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '40px', color: '#DF711B' }} />{' '}
            </div>
          }
          closeIcon={<></>}
          onCancel={this.handleCancelErrorFileType}
          footer={[
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                key="back"
                onClick={() => {
                  this.setState({ visible_error_file_type: false })
                }}
                type="primary"
                style={{ padding: '0 35px' }}
              >
                Confirm
              </Button>
            </div>,
          ]}
        >
          <p style={{ width: '100%', textAlign: 'center' }}>Supported formats: png, jpg, jpeg</p>
        </Modal>

        <Layout>
          <Content>
            <TitleForm>Basic Information</TitleForm>
            <CustomDivider />
            <Row>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Token name:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="token_name"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          if (value.length < 2 || value.length > 30) {
                            return Promise.reject(new Error('2-30 characters for token name'))
                          }

                          return Promise.resolve()
                        } else {
                          return Promise.reject(new Error('Token name is required'))
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.token_name}
                >
                  <CustomInput
                    disabled={this.state.addressUpdate ? true : false}
                    placeholder="2-30 characters for token name"
                  />
                </Item>
              </Col>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Token abbreviation:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="token_abbreviation"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          if (value.length < 2 || value.length > 10) {
                            this.setState({
                              token_abbr: '',
                            })
                            return Promise.reject(
                              new Error('2-10 characters for token abbreviation')
                            )
                          }
                          var regex = /^[a-zA-Z0-9]+$/
                          if (!regex.test(value)) {
                            return Promise.reject(
                              new Error('Token abbreviation has to contain letters and numbers')
                            )
                          }

                          this.setState({
                            token_abbr: value,
                          })
                          return Promise.resolve()
                        } else {
                          this.setState({
                            token_abbr: '',
                          })
                          return Promise.reject(new Error('Token abbreviation is required'))
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.token_abbreviation}
                >
                  <CustomInput
                    onKeyDown={(e) => e.keyCode === 32 && e.preventDefault()}
                    disabled={this.state.addressUpdate ? true : false}
                    placeholder="2-10 characters for token abbreviation"
                  />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Token introduction:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="token_introduction"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          if (value.length > 200) {
                            return Promise.reject(
                              new Error('Token introduction is not exceeding 200 characters')
                            )
                          }

                          return Promise.resolve()
                        } else {
                          return Promise.reject(new Error('Token introduction is required'))
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.token_introduction}
                >
                  <CustomTextArea
                    disabled={this.state.addressUpdate ? true : false}
                    placeholder="Controlled autosize"
                    autoSize={{ minRows: 3, maxRows: 4 }}
                    placeholder="Brief description of the purpose of the token, not exceeding 200 characters"
                  />
                </Item>
              </Col>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Issuer:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="issuer"
                  rules={[
                    {
                      required: true,
                      message: 'Issuer is required',
                    },
                  ]}
                  initialValue={
                    this.state.defaultPriv.address === undefined
                      ? ''
                      : this.state.defaultPriv.address
                  }
                >
                  <CustomInput disabled />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Total Supply:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="token_supply_without_precision"
                  validateStatus={
                    error_token_supply_without_precision == undefined ? 'success' : 'error'
                  }
                  help={
                    error_token_supply_without_precision == undefined
                      ? ''
                      : error_token_supply_without_precision
                  }
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          var total_supply = String(new BigNumber(value))

                          if (total_supply.length === 1 && parseInt(total_supply) === 0) {
                            this.setState({
                              error_token_supply_without_precision:
                                'Total supply must be greater than 0.',
                              token_supply_without_precision: '',
                            })
                            return Promise.reject()
                          }

                          if (String(value).length - total_supply.length === 1) {
                            this.setState({
                              error_token_supply_without_precision:
                                'Numbers must not have leading 0.',
                              token_supply_without_precision: '',
                            })
                            return Promise.reject()
                          } else if (String(value).length - total_supply.length > 1) {
                            this.setState({
                              error_token_supply_without_precision:
                                'Total supply does not contain multiple leading 0.',
                              token_supply_without_precision: '',
                            })
                            return Promise.reject()
                          }

                          if (
                            new BigNumber(value)
                              .multipliedBy(Math.pow(10, this.state.precision_value))
                              .isGreaterThanOrEqualTo(Number.MAX_SAFE_INTEGER)
                          ) {
                            this.setState({
                              error_token_supply_without_precision: `Exceeded the maximum value. Total Supply must be less than ${String(
                                new BigNumber(value)
                              )}.`,
                              token_supply_without_precision: String(value),
                            })
                            return Promise.reject()
                          }

                          this.setState({
                            error_token_supply_without_precision: undefined,
                            token_supply_without_precision: String(value),
                          })
                          return Promise.resolve()
                        } else {
                          this.setState({
                            error_token_supply_without_precision: 'Total supply is required',
                            token_supply_without_precision: undefined,
                          })
                          return Promise.reject()
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.token_supply_without_precision}
                >
                  <CustomInput
                    onPaste={(e) => {
                      e.preventDefault()
                      return false
                    }}
                    onKeyDown={(e) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 190 ||
                        e.keyCode === 110 ||
                        e.keyCode === 189 ||
                        e.keyCode === 188 ||
                        e.keyCode === 107 ||
                        e.keyCode === 187 ||
                        e.keyCode === 109 ||
                        e.keyCode === 231 ||
                        (e.keyCode != 8 &&
                        e.keyCode != 46 &&
                        e.keyCode != 37 &&
                        e.keyCode != 39 &&
                        e.target.value.length >= 26
                          ? e.preventDefault()
                          : '')) &&
                      e.preventDefault()
                    }
                    type="number"
                    disabled={this.state.addressUpdate ? true : false}
                    placeholder="Total token issuance (without precision)"
                    style={{ marginBottom: '5px' }}
                  />
                </Item>
              </Col>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Precision:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="precision"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          var regex = /^(?:0|[1-9][0-9]+)$/
                          if (!regex.test(value)) {
                            if (String(value).length == 1) {
                            } else {
                              if (parseInt(value) == 0) {
                                this.setState({
                                  precision_value: undefined,
                                })
                                return Promise.reject(
                                  new Error('Precision does not contain multiple leading 0.')
                                )
                              } else {
                                this.setState({
                                  precision_value: undefined,
                                })
                                return Promise.reject(new Error('Numbers must not have leading 0.'))
                              }
                            }
                          }

                          if (parseInt(value) > 6) {
                            this.setState({
                              precision_value: undefined,
                            })
                            return Promise.reject(
                              new Error('Exceeded the maximum value. Precision must be 0 - 6.')
                            )
                          }
                          if (parseInt(value) < 0) {
                            this.setState({
                              precision_value: 0,
                            })
                            this.formRef.current.setFieldsValue({
                              precision: 0,
                            })
                            return Promise.reject()
                          }

                          if (
                            new BigNumber(
                              this.state.token_supply_without_precision
                            ).isGreaterThanOrEqualTo(Number.MAX_SAFE_INTEGER)
                          ) {
                            this.setState({
                              error_token_supply_without_precision: `Exceeded the maximum value. Total Supply must be less than ${new BigNumber(
                                this.state.token_supply_without_precision
                              ).toString()}.`,
                            })
                          }
                          this.setState({
                            precision_value: value,
                          })

                          return Promise.resolve()
                        } else {
                          this.setState({
                            precision_value: undefined,
                          })
                          return Promise.reject(new Error('Token precision is required'))
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.precision}
                >
                  <CustomInput
                    onPaste={(e) => {
                      e.preventDefault()
                      return false
                    }}
                    onKeyDown={(e) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 190 ||
                        e.keyCode === 110 ||
                        e.keyCode === 187 ||
                        e.keyCode === 188 ||
                        e.keyCode === 107 ||
                        e.keyCode === 189 ||
                        e.keyCode === 109 ||
                        e.keyCode === 231) &&
                      e.preventDefault()
                    }
                    type="number"
                    disabled={this.state.addressUpdate ? true : false}
                    placeholder="0-6"
                  />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col
                span={12}
                style={{ paddingRight: '10px', paddingLeft: '10px' }}
                className="image-upload"
              >
                <TitleContainer>
                  <ContentTitle>Estimated amount:</ContentTitle>
                  <ContentTitle
                    style={{
                      float: 'right',
                      color:
                        error_token_supply_without_precision == undefined ? '#03A66D' : '#CF304A',
                    }}
                  >
                    {precision_value ? (
                      <NumberFormat
                        displayType={'text'}
                        thousandSeparator={true}
                        value={String(
                          new BigNumber(token_supply_without_precision).multipliedBy(
                            Math.pow(10, precision_value)
                          )
                        )}
                      />
                    ) : (
                      ''
                    )}
                  </ContentTitle>
                </TitleContainer>
                <p
                  style={{
                    padding: '10px',
                    background: '#FEF6D8',
                    border: '1px solid #FCD535',
                    borderRadius: '5px',
                    display: 'flex',
                  }}
                >
                  <div className="ant-row ant-col-2">
                    <ExclamationCircleOutlined style={{ color: '#ef8920', fontSize: '36px' }} />
                  </div>
                  <div className="ant-row ant-col-22">
                    <ContentTitle style={{ width: '90%', display: 'block' }}>
                      The Maximum amount is {Number.MAX_SAFE_INTEGER}. Please check your Total
                      supply and precision before approving contract.
                    </ContentTitle>
                  </div>
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                span={12}
                style={{ paddingRight: '10px', paddingLeft: '10px' }}
                className="image-upload"
              >
                <ContentTitle>Token Logo</ContentTitle>
                <Item name="tokenLogo" style={{ marginTop: '10px' }}>
                  <Upload.Dragger
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={async (e) => await this.handleUpload(e)}
                    beforeUpload={() => false}
                    accept=".jpg,.jpeg,.png"
                  >
                    <p className="ant-upload-drag-icon">
                      {imageUrl != undefined ? (
                        <img src={imageUrl} alt="avatar" style={{ width: '50px' }} />
                      ) : (
                        <div
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                            width: '97px',
                            height: '110px',
                            margin: '0 auto',
                            backgroundImage: `url(${IconUploadImage})`,
                          }}
                        />
                      )}
                    </p>
                    <p className="ant-upload-text">
                      Drop your Token logo here, <span style={{ color: '#319BFD' }}>or browse</span>
                    </p>
                    <span style={{ fontSize: '12px', color: '#A39F9B' }}>
                      Support: PNG, JPG, JPEG. Size limit: No more than 200K. Pixel limit: 100.
                    </span>
                  </Upload.Dragger>
                </Item>
              </Col>
            </Row>
            <TitleForm>Price Information</TitleForm>
            <CustomDivider />
            <TitleContainer>
              <ContentTitle>
                <span style={{ color: 'red' }}>* </span>Token price:
              </ContentTitle>
            </TitleContainer>
            <div style={{ display: 'flex' }}>
              <Item
                name="price_usd"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value !== undefined && value !== null && value !== '') {
                        if (parseInt(value) <= 0) {
                          this.formRef.current.setFieldsValue({
                            price_usd: 1,
                          })
                          this.setState({
                            price_not_win: 1,
                          })
                          return Promise.reject(new Error('This field must be greater than 0'))
                        }
                        ;-this.setState({
                          price_not_win: value,
                        })
                        if (this.state.positionWinFirst && value > 2147) {
                          return Promise.reject(new Error('No more than 2147'))
                        }
                        return Promise.resolve()
                      } else {
                        return Promise.reject(new Error('Token price cannot be empty'))
                      }
                    },
                  },
                ]}
                style={{ margin: 0, width: '25%' }}
                initialValue={tokenRecord.data.price_usd}
              >
                <CustomInput
                  onPaste={(e) => {
                    e.preventDefault()
                    return false
                  }}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 ||
                      e.keyCode === 190 ||
                      e.keyCode === 110 ||
                      e.keyCode === 189 ||
                      e.keyCode === 187 ||
                      e.keyCode === 107 ||
                      e.keyCode === 109) &&
                    e.preventDefault()
                  }
                  type="number"
                  placeholder="Must be greater than zero"
                  disabled={this.state.addressUpdate ? true : false}
                />
              </Item>
              {!this.state.positionWinFirst ? (
                this.state.token_abbr != '' || tokenRecord.data.token_abbreviation != '' ? (
                  <p style={{ margin: '8px 0 0 10px' }}>
                    {this.state.token_abbr == ''
                      ? tokenRecord.data.token_abbreviation
                      : this.state.token_abbr}
                  </p>
                ) : (
                  ''
                )
              ) : (
                <p style={{ margin: '8px 0 0 10px' }}>WIN</p>
              )}
              <SwapOutlined
                style={{
                  padding: '0px 10px',
                  color: this.state.colorIconSwap,
                  cursor: 'pointer',
                  marginTop: '12px',
                }}
                onClick={() => {
                  this.setState({ positionWinFirst: !this.state.positionWinFirst })
                }}
                onMouseEnter={() => {
                  this.setState({
                    colorIconSwap: 'rgb(130 145 255)',
                  })
                }}
                onMouseLeave={() => {
                  this.setState({
                    colorIconSwap: '#000',
                  })
                }}
              />
              <Item
                name="price_win"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value !== undefined && value !== null && value !== '') {
                        if (parseInt(value) <= 0) {
                          this.formRef.current.setFieldsValue({
                            price_win: 1,
                          })
                          this.setState({
                            price_win: 1,
                          })
                          return Promise.reject(new Error('This field must be greater than 0'))
                        }
                        this.setState({
                          price_win: value,
                        })

                        if (!this.state.positionWinFirst && value > 2147) {
                          return Promise.reject(new Error('No more than 2147'))
                        }

                        return Promise.resolve()
                      } else {
                        return Promise.reject(new Error('Token price cannot be empty'))
                      }
                    },
                  },
                ]}
                style={{ margin: 0, width: '25%' }}
                initialValue={tokenRecord.data.price_win}
              >
                <CustomInput
                  onPaste={(e) => {
                    e.preventDefault()
                    return false
                  }}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 ||
                      e.keyCode === 190 ||
                      e.keyCode === 110 ||
                      e.keyCode === 189 ||
                      e.keyCode === 187 ||
                      e.keyCode === 107 ||
                      e.keyCode === 109) &&
                    e.preventDefault()
                  }
                  type="number"
                  placeholder="Must be greater than zero"
                  disabled={this.state.addressUpdate ? true : false}
                />
              </Item>
              {this.state.positionWinFirst ? (
                this.state.token_abbr != '' || tokenRecord.data.token_abbreviation != '' ? (
                  <p style={{ margin: '8px 0 0 10px' }}>
                    {this.state.token_abbr == ''
                      ? tokenRecord.data.token_abbreviation
                      : this.state.token_abbr}
                  </p>
                ) : (
                  ''
                )
              ) : (
                <p style={{ margin: '8px 0 0 10px' }}>WIN</p>
              )}
              <span style={{ margin: '8px 0 0 20px', color: 'rgb(158, 158, 158)' }}>
                {this.state.price_win !== '' && this.state.price_not_win !== ''
                  ? `(1 
                  ${
                    this.state.positionWinFirst
                      ? 'WIN'
                      : this.state.token_abbr == ''
                      ? tokenRecord.data.token_abbreviation
                      : this.state.token_abbr
                  }
                   = 
                  ${String(
                    new BigNumber(
                      this.state.positionWinFirst ? this.state.price_not_win : this.state.price_win
                    ).div(
                      !this.state.positionWinFirst ? this.state.price_not_win : this.state.price_win
                    )
                  )} 
                  ${
                    !this.state.positionWinFirst
                      ? 'WIN'
                      : this.state.token_abbr == ''
                      ? tokenRecord.data.token_abbreviation
                      : this.state.token_abbr
                  })`
                  : ''}
              </span>
            </div>
            <TitleForm>Social Media Information</TitleForm>
            <CustomDivider />
            <Row>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Project offical website:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="project_offical_website"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          if (!validator.isURL(value)) {
                            return Promise.reject(new Error('Incorrect format of the url'))
                          }
                          return Promise.resolve()
                        } else {
                          return Promise.reject(new Error('Web URL is required'))
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.project_offical_website}
                >
                  <CustomInput placeholder="Project offical website" />
                </Item>
              </Col>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Email:
                  </ContentTitle>
                </TitleContainer>
                <Item
                  name="email"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          var regex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
                          if (!regex.test(value)) {
                            return Promise.reject(
                              new Error('Incorrect format of the email address')
                            )
                          }
                          return Promise.resolve()
                        } else {
                          return Promise.reject(new Error('Email is a required information'))
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.email}
                >
                  <CustomInput placeholder="Contact email" />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>Link for Github:</ContentTitle>
                </TitleContainer>
                <Item
                  name="link_for_github"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          if (!validator.isURL(value)) {
                            return Promise.reject(new Error('Incorrect format of the url'))
                          }
                          return Promise.resolve()
                        } else {
                          return Promise.resolve()
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.link_for_github}
                >
                  <CustomInput placeholder="Link for Github" />
                </Item>
              </Col>
              <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TitleContainer>
                  <ContentTitle>Link for white paper:</ContentTitle>
                </TitleContainer>
                <Item
                  name="link_for_white_paper"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value !== null && value !== '') {
                          if (!validator.isURL(value)) {
                            return Promise.reject(new Error('Incorrect format of the url'))
                          }
                          return Promise.resolve()
                        } else {
                          return Promise.resolve()
                        }
                      },
                    },
                  ]}
                  initialValue={tokenRecord.data.link_for_white_paper}
                >
                  <CustomInput placeholder="Link for white paper" />
                </Item>
              </Col>
            </Row>
            <span>
              Click to add social media link(s){' '}
              {this.state.socialMedia.map((item) => {
                return (
                  <IconSocial
                    src={item.isOpen ? item.icon : item.iconGrey}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      item.isOpen = !item.isOpen
                      this.setState({
                        socialMedia: this.state.socialMedia,
                      })
                    }}
                  />
                )
              })}
            </span>
            <Row>
              {this.state.socialMedia.map((item) =>
                item.isOpen ? (
                  <Col span={12} style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                    <TitleContainer>
                      <IconSocial src={item.icon} />
                      <ContentTitle>{item.name}:</ContentTitle>
                    </TitleContainer>
                    <Item
                      name={item.name.toLowerCase()}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value !== undefined && value !== null && value !== '') {
                              if (!validator.isURL(value)) {
                                return Promise.reject(new Error('Incorrect format of the url'))
                              }

                              return Promise.resolve()
                            } else {
                              return Promise.resolve()
                            }
                          },
                        },
                      ]}
                    >
                      <Input />
                    </Item>
                  </Col>
                ) : (
                  ''
                )
              )}
            </Row>
          </Content>
          <Footer style={{ textAlign: 'right' }}>
            {this.state.addressUpdate ? (
              ''
            ) : (
              <Button
                type="default"
                style={{ borderRadius: '0px', marginRight: '5px' }}
                onClick={() => {
                  this.props.updateTokenRecord(tokenRecord.data, 1)
                }}
              >
                Previous
              </Button>
            )}

            <Button type="primary" htmlType="submit" style={{ borderRadius: '0px' }}>
              Next
            </Button>
          </Footer>
        </Layout>
      </StyledForm>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    contract: state.contract,
    tokenRecord: state.tokenRecord,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateTokenRecord: (data, current_url) => {
      dispatch(updateTokenRecord(data, current_url, 3))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  FormTokenRecordRC10
)
