import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableRow, Th } from './style'
import { GLOBAL_SYMBOL } from '../../constant'
import NumberFormat from 'react-number-format'
import { REACT_APP_API_ADDRESS_ADMIN } from '../../config/config'
import { getDefaulteyFromToken } from '../../common/commonTransaction'
import { EditOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Modal, Select, Button } from 'antd'
import TruncateMiddle from 'react-truncate-string'
import { adminUpdateTokenRecordAPI } from '../../actions/tokenRecord'
import ConfigKey from '../../api/key'

const { confirm } = Modal
const { Option } = Select

const DivRepuration = styled.td`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
`
class Overview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultPriv: getDefaulteyFromToken(),
      colorEditIcon: '#000',
      reputationType: ['Unknown', 'Neutral', 'OK', 'Suspicious', 'Scam'],
      selectedReputition: '',
      visible: false,
      updateLoading: false,
      REACT_APP_API_ADDRESS_ADMIN: '',
    }
  }
  async componentDidMount() {
    const config_key = await ConfigKey.getkey()

    this.setState({
      REACT_APP_API_ADDRESS_ADMIN: config_key.REACT_APP_API_ADDRESS_ADMIN,
    })
  }
  showModalEditReputation() {
    this.setState({
      visible: true,
    })
  }

  hideModal() {
    this.setState({
      visible: false,
    })
  }

  updateData() {
    this.setState({
      updateLoading: true,
    })
    if (
      this.state.selectedReputition != '' &&
      this.state.selectedReputition != this.props.tokenRecord.reputation
    ) {
      this.props.adminUpdateTokenRecord(
        this.props.tokenRecord.data,
        this.state.defaultPriv.priv,
        this.props.tokenRecord.data.contract_address,
        this.state.selectedReputition
      )
      this.setState({
        visible: false,
        updateLoading: false,
      })
    }
  }
  render() {
    let { tokenRecord } = this.props
    let { reputationType, REACT_APP_API_ADDRESS_ADMIN } = this.state
    return (
      <>
        <Modal
          title="Choose a Reputation:"
          visible={this.state.visible}
          okText="Update"
          cancelText="Cancel"
          maskClosable={false}
          footer={[
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                key="back"
                onClick={() => {
                  this.setState({ visible: false })
                }}
                type="default"
                style={{ padding: '0 35px' }}
                disabled={this.state.updateLoading}
              >
                Cancel
              </Button>

              <Button
                key="back"
                onClick={() => this.updateData()}
                type="primary"
                style={{ padding: '0 35px' }}
                loading={this.state.updateLoading}
              >
                Update
              </Button>
            </div>,
          ]}
        >
          <Select
            placeholder="Select a Reputation"
            onChange={(val) =>
              this.setState({
                selectedReputition: val,
              })
            }
            defaultValue={
              tokenRecord.data.reputation === undefined ? '' : tokenRecord.data.reputation
            }
            style={{ width: '100%' }}
          >
            {reputationType.map((value, index) => (
              <Option value={value} key={index}>
                {value}
              </Option>
            ))}
          </Select>
        </Modal>

        <tbody>
          {tokenRecord.data.contract_name == undefined ? (
            ''
          ) : (
            <TableRow>
              <Th>
                <span>Contract Name:</span>
              </Th>
              <td>
                <span>{tokenRecord.data.contract_name}</span>
              </td>
            </TableRow>
          )}
          <TableRow>
            <Th>
              <span>Total Supply:</span>
            </Th>
            <td>
              <span>
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={
                    tokenRecord.data.token_supply_without_precision === undefined
                      ? 0
                      : tokenRecord.data.token_supply_without_precision
                  }
                />
              </span>
            </td>
          </TableRow>
          <TableRow>
            <Th>
              <span>White Paper:</span>
            </Th>
            <td>
              {tokenRecord.data.link_for_white_paper === undefined ? (
                <span>–</span>
              ) : (
                <a
                  style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  href={
                    tokenRecord.data.link_for_white_paper.split('www.').length == 2
                      ? 'http://' + tokenRecord.data.link_for_white_paper.split('www.')[1]
                      : tokenRecord.data.link_for_white_paper.split('www.')
                  }
                  target="_blank"
                >
                  <TruncateMiddle text={tokenRecord.data.link_for_white_paper} />
                </a>
              )}
            </td>
          </TableRow>
          <TableRow>
            <Th>
              <span>Price:</span>
            </Th>
            <td>
              <span>
                {tokenRecord.data.token_record_type === 'WRC10'
                  ? tokenRecord.data.price_usd +
                    (tokenRecord.data.token_abbreviation === undefined
                      ? ''
                      : ' ' +
                        tokenRecord.data.token_abbreviation
                          .trim()
                          .replace(/[^\x0-\x7]/g, '')
                          .replace(/[\u{0080}-\u{FFFF}]/gu, '')) +
                    ' ≈ ' +
                    tokenRecord.data.price_win +
                    ' WIN'
                  : '–'}
              </span>
            </td>
          </TableRow>
          <TableRow>
            <Th>
              <span>Reputation:</span>
            </Th>
            <DivRepuration>
              <span>{tokenRecord.data.reputation}</span>
              {this.state.defaultPriv.address != undefined ? (
                REACT_APP_API_ADDRESS_ADMIN == this.state.defaultPriv.address ? (
                  <EditOutlined
                    style={{ color: this.state.colorEditIcon, cursor: 'pointer' }}
                    onMouseEnter={() => this.setState({ colorEditIcon: '#ff9800' })}
                    onMouseLeave={() => this.setState({ colorEditIcon: '#000' })}
                    onClick={() => this.showModalEditReputation()}
                  />
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </DivRepuration>
          </TableRow>
        </tbody>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tokenRecord: state.tokenRecord,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    adminUpdateTokenRecord: (data, priv, contrac_addr, repuration) => {
      dispatch(adminUpdateTokenRecordAPI(data, priv, contrac_addr, repuration))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Overview)
