import { LoadingOutlined } from '@ant-design/icons'
import { Button, Form, Input, InputNumber, notification, Result, Select, Spin } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import { loadFromStorage } from '../../actions/login'
import {
  reset,
  transferAsset,
  TRANSFER_FAIL,
  TRANSFER_REQUESTING,
  TRANSFER_SUCCESS,
  updatePageTokens,
} from '../../actions/transferasset'
import { loadTransferRCApi } from '../../actions/transferLoadRC10RC20'
import { getDefaulteyFromToken, GetFromPrivateKey } from '../../common/commonTransaction'
import { ValidateAddress } from '../../common/validate'
import { API_ADDR } from '../../config/config'
import { GLOBAL_SYMBOL } from '../../constant'

const { TextArea } = Input
const ButtonSubmit = styled(Button)`
  width: 100%;
  margin-bottom: 40px;
  color: #ffffff;
`
const Item = styled(Form.Item)`
  font-weight: bold;
`
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`
const Logo = styled.img`
  height: 66px;
  width: 200px;
  margin-bottom: 20px;
`
const Title = styled.span`
  font-size: 25px;
  font-weight: 600;
`
const ContentTitle = styled.span`
  font-weight: 400;
  margin-left: 7px;
`
const TitleContainer = styled.div`
  text-align: left;
`
const HeaderTitle = styled.div`
  margin-top: 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledForm = styled(Form)`
  @media (min-width: 540px) {
    width: 450px;
  }
  @media (max-width: 450px) {
    width: 250px;
  }
`

const { Option } = Select
const defaultToken = { abbr: GLOBAL_SYMBOL, precision: 6, name: GLOBAL_SYMBOL, id: '' }
class TransferAsset extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      owner_address: '',
      to: '',
      selectedToken: defaultToken,
      amount: '',
      loading: false,
      error: {
        status: false,
        msg: '',
      },
      powdecimal: 1,
      maxamount: undefined,
      loadingmaxamount: false,
      defaultPriv: getDefaulteyFromToken(),
      countFirstTime: true,
    }
  }
  formRef = React.createRef()

  async componentDidMount() {
    this.props.urlAtNotPrivFunc()
    var { prikeys, login } = this.props
    if (
      (!prikeys.prikeys && login.token !== '') ||
      (prikeys.prikeys.length === 0 && login.token !== '')
    ) {
      notification.warning({
        message: 'Warning!',
        description:
          'You have no private key, please add somes in private key management to perform transaction!',
      })
    }
    this.props.resetTransferAsset()

    if (this.state.defaultPriv.address !== undefined) {
      this.props.loadTokens(this.state.defaultPriv.address)
      await this.changeMaxAmount(defaultToken, this.state.defaultPriv.address)
    }
    if (login.token === '') {
      this.props.loadFromStorage()
    }
  }

  componentWillUnmount() {
    this.props.resetTransferAsset()
  }
  transfer = (values) => {
    var asset_name =
      this.state.selectedToken.name === 'WIN'
        ? this.state.selectedToken.name
        : this.state.selectedToken.id
    this.props.transferAsset(
      this.props.prikeys.prikeys,
      values.privatekey,
      values.toAddress,
      parseFloat(values.amount),
      this.state.selectedToken.precision,
      asset_name
    )
  }
  changeToAddress = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      to: e.target.value,
    }))
  }
  changeAsset = async (assetIndex) => {
    let selectedToken = defaultToken

    if (this.props.transferLoadRC10RC20.data.length > 0) {
      selectedToken = this.props.transferLoadRC10RC20.data[assetIndex]
      if (assetIndex > this.props.transferLoadRC10RC20.data.length) {
        selectedToken = this.props.transferLoadRC10RC20.data[0]
      }
    }

    if (this.state.countFirstTime) {
      if (this.state.defaultPriv.priv != undefined) {
        this.changeMaxAmount(selectedToken, this.state.defaultPriv.address)
      }
    } else {
      this.changeMaxAmount(selectedToken, this.state.owner_address)
    }

    this.setState((prevState) => ({
      ...prevState,
      selectedToken: selectedToken,
    }))
  }
  changeAmount = (e) => {
    var regex = RegExp(`^[?!0\\d]{0,1}\\d*(\.\\d{0,${this.state.selectedToken.precision}})?$`)
    if (regex.test(e.target.value)) {
      this.setState((prevState) => ({
        ...prevState,
        amount: e.target.value,
      }))
    }
  }
  changeMaxAmount = async (selectedToken, owner_address) => {
    if (owner_address != '') {
      if (owner_address.length === 34 && selectedToken != undefined) {
        this.setState((prevState) => ({
          ...prevState,
          loadingmaxamount: true,
        }))
        if (selectedToken.name === 'WIN') {
          var temp_getamountwin = await fetch(`${API_ADDR}/getamountwel`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({
              address: owner_address,
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              return res.data
            })
            .catch((err) => {
              this.setState((prevState) => ({
                ...prevState,
                powdecimal: 1,
                maxamount: undefined,
                loadingmaxamount: false,
              }))

              return { amount: undefined, decimal: undefined }
            })
          if (temp_getamountwin !== undefined) {
            if (temp_getamountwin.decimal === undefined || temp_getamountwin.amount === undefined) {
              this.setState((prevState) => ({
                ...prevState,
                powdecimal: 1,
                maxamount: undefined,
                loadingmaxamount: false,
              }))
            } else {
              this.setState((prevState) => ({
                ...prevState,
                powdecimal: temp_getamountwin.decimal,
                maxamount: temp_getamountwin.amount,
                loadingmaxamount: false,
              }))
            }
          } else {
            this.setState((prevState) => ({
              ...prevState,
              powdecimal: 1,
              maxamount: undefined,
              loadingmaxamount: false,
            }))
          }
        } else {
          var temp_getamountrc10 = await fetch(`${API_ADDR}/getamountrc10`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({
              address: owner_address,
              token: selectedToken.id,
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              return res.data
            })
            .catch((err) => {
              this.setState((prevState) => ({
                ...prevState,
                powdecimal: 1,
                maxamount: undefined,
                loadingmaxamount: false,
              }))
            })
          if (temp_getamountrc10 !== undefined) {
            if (
              temp_getamountrc10.decimal === undefined ||
              temp_getamountrc10.amount === undefined
            ) {
              this.setState((prevState) => ({
                ...prevState,
                powdecimal: 1,
                maxamount: undefined,
                loadingmaxamount: false,
              }))
            } else {
              this.setState((prevState) => ({
                ...prevState,
                powdecimal: temp_getamountrc10.decimal,
                maxamount: temp_getamountrc10.amount,
                loadingmaxamount: false,
              }))
            }
          } else {
            this.setState((prevState) => ({
              ...prevState,
              powdecimal: 1,
              maxamount: undefined,
              loadingmaxamount: false,
            }))
          }
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          powdecimal: 1,
          maxamount: undefined,
          loadingmaxamount: false,
        }))
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        powdecimal: 1,
        maxamount: undefined,
        loadingmaxamount: false,
      }))
    }
  }
  changePrivateKey = async (address) => {
    if (this.state.countFirstTime) {
      this.setState({
        countFirstTime: false,
      })
    }
    this.formRef.current.setFieldsValue({
      Token: 0,
    })

    if (address != '' && address != undefined) {
      this.props.loadTokens(address)
      await this.changeMaxAmount(defaultToken, address)
    } else {
      this.setState((prevState) => ({
        ...prevState,
        powdecimal: 1,
        maxamount: undefined,
        loadingmaxamount: false,
      }))
    }
    this.setState((prevState) => ({
      ...prevState,
      owner_address: address,
    }))
  }

  render() {
    const { transferInfo, prikeys, transferLoadRC10RC20 } = this.props

    if (!localStorage.getItem('token')) {
      return <Redirect to="/login" />
    }

    let tokens = transferLoadRC10RC20.data ? transferLoadRC10RC20.data : null
    const antIcon = <LoadingOutlined spin />
    return (
      <Wrapper>
        <Spin
          indicator={antIcon}
          tip="Processing..."
          spinning={transferInfo.status === TRANSFER_REQUESTING}
        >
          {transferInfo.status === TRANSFER_SUCCESS && (
            <div>
              <Result
                status="success"
                title={`Your transaction has been issued successfully!`}
                subTitle={`You can check it at transaction ${transferInfo.tranID}`}
                extra={[
                  <Button type="primary">
                    <Link to={`/transaction/${transferInfo.tranID}`}>Details</Link>
                  </Button>,
                  <Button
                    onClick={async () => {
                      this.props.resetTransferAsset()
                      if (this.state.defaultPriv.address !== undefined) {
                        this.props.loadTokens(this.state.defaultPriv.address)
                        await this.changeMaxAmount(defaultToken, this.state.defaultPriv.address)
                      }
                    }}
                  >
                    New Transfer
                  </Button>,
                ]}
              />
              ,
            </div>
          )}
          {transferInfo.status === TRANSFER_FAIL && (
            <div>
              <Result
                status="error"
                title={`Your transaction hasn't been issued, something must went wrong`}
                subTitle={transferInfo.message != '' ? `Error: ${transferInfo.message}` : ''}
                extra={[
                  <Button
                    onClick={async () => {
                      this.props.resetTransferAsset()
                      if (this.state.defaultPriv.address !== undefined) {
                        this.props.loadTokens(this.state.defaultPriv.address)
                        await this.changeMaxAmount(defaultToken, this.state.defaultPriv.address)
                      }
                    }}
                  >
                    New Transfer
                  </Button>,
                ]}
              />
              ,
            </div>
          )}
          {transferInfo.status !== TRANSFER_FAIL && transferInfo.status !== TRANSFER_SUCCESS && (
            <StyledForm layout="vertical" size="large" ref={this.formRef} onFinish={this.transfer}>
              <HeaderTitle>
                <Title>Transfer Asset</Title>
              </HeaderTitle>
              <TitleContainer>
                <ContentTitle>Your private Key</ContentTitle>
              </TitleContainer>
              <Item
                name="privatekey"
                rules={[
                  {
                    required: true,
                    message: 'Private key cannot be empty!',
                  },
                ]}
                initialValue={
                  this.state.defaultPriv.address === undefined ? '' : this.state.defaultPriv.address
                }
              >
                <Select
                  placeholder="Select a private key"
                  onChange={this.changePrivateKey}
                  defaultValue={
                    this.state.defaultPriv.address === undefined
                      ? ''
                      : this.state.defaultPriv.address
                  }
                >
                  {prikeys.prikeys && prikeys.prikeys.length !== 0
                    ? prikeys.prikeys.map((value, index) => (
                        <Option value={value.address} key={index}>
                          {value.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Item>
              <TitleContainer>
                <ContentTitle>To</ContentTitle>
              </TitleContainer>
              <Item
                name="toAddress"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value !== undefined && value !== null) {
                        var regex = new RegExp('^[W][a-zA-Z0-9]{33}$')
                        if (!regex.test(value)) {
                          return Promise.reject(new Error('To address wrong format!'))
                        }

                        return Promise.resolve()
                      } else {
                        return Promise.reject(new Error('To address cannot be empty!'))
                      }
                    },
                  },
                ]}
                initialValue={this.props.location.state && this.props.location.state}
              >
                <Input value={this.state.to} onChange={this.changeToAddress} />
              </Item>
              <TitleContainer>
                <ContentTitle>Token</ContentTitle>
              </TitleContainer>
              <Item
                name="Token"
                rules={[
                  {
                    required: true,
                    message: 'Token cannot be empty!',
                  },
                ]}
                initialValue={0}
              >
                <Select
                  placeholder="Select a token"
                  onChange={this.changeAsset}
                  disabled={transferLoadRC10RC20.status === 'requesting' ? true : false}
                  loading={transferLoadRC10RC20.status === 'requesting' ? true : false}
                >
                  {!this.state.loading
                    ? tokens.map((token, index) => (
                        <Option value={index} key={index}>
                          {token.name}
                        </Option>
                      ))
                    : ''}
                </Select>
              </Item>
              <TitleContainer>
                <ContentTitle>
                  Amount
                  <span style={{ color: 'red' }}>
                    {this.state.maxamount === undefined
                      ? ''
                      : ' (' +
                        new Intl.NumberFormat('ja-JP', { maximumSignificantDigits: 20 }).format(
                          this.state.maxamount / this.state.powdecimal
                        ) +
                        ')'}
                  </span>
                  {this.state.loadingmaxamount ? (
                    <Spin size="small" style={{ marginLeft: '5px' }} />
                  ) : (
                    ''
                  )}
                </ContentTitle>
              </TitleContainer>
              <Item
                name="amount"
                rules={[
                  {
                    validator: (_, value) => {
                      if (this.state.maxamount === '0') {
                        return Promise.reject(new Error("You don't have wallet balance!"))
                      }
                      if (this.state.maxamount === undefined) {
                        return Promise.reject(new Error("You don't have wallet balance!"))
                      }
                      if (value !== undefined && value !== null) {
                        if (String(value).length > 17) {
                          return Promise.reject(new Error('Amount has to be 17 characters long!'))
                        }
                        if (value <= 0) {
                          return Promise.reject(new Error('Amount must be more than 0!'))
                        }
                        if (value > this.state.maxamount / this.state.powdecimal) {
                          return Promise.reject(
                            new Error(
                              'Amount must be less than ' +
                                new Intl.NumberFormat('ja-JP', {
                                  maximumSignificantDigits: 20,
                                }).format(this.state.maxamount / this.state.powdecimal)
                            )
                          )
                        }

                        var regex = new RegExp(
                          `^[?!0\\d]{0,1}\\d*(\.\\d{0,${this.state.selectedToken.precision}})?$`
                        )
                        if (!regex.test(value)) {
                          return Promise.reject(
                            new Error(
                              'Amount is only ' +
                                this.state.selectedToken.precision +
                                ' charaters after the comma!'
                            )
                          )
                        }

                        return Promise.resolve()
                      } else {
                        return Promise.reject(new Error('Amount cannot be empty!'))
                      }
                    },
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} min={0} />
              </Item>
              <TitleContainer>
                <ContentTitle>Note</ContentTitle>
              </TitleContainer>
              <Item>
                <TextArea></TextArea>
              </Item>
              <p style={{ color: 'red' }}>{this.state.error.msg}</p>

              <ButtonSubmit type="primary" htmlType="submit">
                Send
              </ButtonSubmit>
            </StyledForm>
          )}
        </Spin>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transferInfo: state.transferAsset,
    prikeys: state.prikeyManagement,
    login: state.login,
    transferLoadRC10RC20: state.transferLoadRC10RC20,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    transferAsset: (privs, addr, to, amount, precision, assetName) => {
      dispatch(transferAsset(privs, addr, to, amount, precision, assetName))
    },
    resetTransferAsset: () => {
      dispatch(reset())
    },
    loadTokens: (address) => {
      dispatch(loadTransferRCApi(address, true))
    },
    updatePageTokens: (page) => {
      dispatch(updatePageTokens(page))
    },
    loadFromStorage: () => {
      dispatch(loadFromStorage())
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TransferAsset)
