import { Button, Col, Form, Input, Modal, Row } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { checkPassword, editPriKey, setShowState } from '../../actions/prikeyManagement'
import { PRIKEYEXISTED, PRIKEYNAMEEXISTED, NOEXISTED } from './constants'

class EditButton extends Component {
  formRef = React.createRef()
  state = {
    loading: false,
    visible: false,
  }

  checkExist = (value) => {
    const privateKeyList = JSON.parse(localStorage.getItem(this.props.email)) || []
    let isExisted = NOEXISTED
    privateKeyList.forEach((elem) => {
      if (elem.name === value) {
        isExisted = PRIKEYNAMEEXISTED
      }
      if (elem.prikey === value) {
        isExisted = PRIKEYEXISTED
      }
    })

    return isExisted
  }

  showModal = (e) => {
    e.preventDefault()
    this.setState({
      visible: true,
    })
  }

  handleOk = (values) => {
    var { ind, prikeys } = this.props
    this.props.editPriKey(ind, values, prikeys.email)
    this.props.setShowState(false)
    this.formRef.current.resetFields()
    this.setState({ visible: false })
  }

  handleCancel = () => {
    this.props.setShowState(false)
    this.formRef.current.resetFields()
    this.setState({ visible: false })
  }

  handleCheckPassword = (values) => {
    var { prikeys } = this.props
    this.formRef.current.resetFields()
    this.props.checkPassword(prikeys.email, values.password)
  }

  render() {
    var { val, prikeys } = this.props
    const { visible, loading } = this.state
    return (
      <Col>
        <Button type="primary" onClick={(e) => this.showModal(e)}>
          Edit
        </Button>
        <Modal visible={visible} onCancel={this.handleCancel} footer={null}>
          <Form
            layout="vertical"
            name="login"
            initialValues={{
              remember: true,
            }}
            onFinish={this.handleOk}
            size="medium"
            ref={this.formRef}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  validator: (_, value) => {
                    if (value !== undefined && value !== null) {
                      if (val.name !== value) {
                        const isExisted = this.checkExist(value)

                        if (isExisted === PRIKEYNAMEEXISTED) {
                          return Promise.reject(new Error('This Name already exists!'))
                        }
                        if (value.length > 150) {
                          return Promise.reject(new Error('Name has to be 150 characters long!'))
                        }
                      }
                      return Promise.resolve()
                    } else {
                      return Promise.reject(new Error('This Name cannot be empty!'))
                    }
                  },
                },
              ]}
              initialValue={val.name}
            >
              <Input placeholder="Private key name" />
            </Form.Item>
            <Form.Item
              label="Private Key"
              name="prikey"
              rules={[
                {
                  validator: (_, value) => {
                    if (value !== undefined && value !== null) {
                      if (val.prikey !== value) {
                        const isExisted = this.checkExist(value)

                        if (isExisted === PRIKEYEXISTED) {
                          return Promise.reject(new Error('This Private key already exists!'))
                        }

                        if (value.length !== 64) {
                          return Promise.reject(
                            new Error('Private key has to be 64 characters long!')
                          )
                        }
                        if (!value.match(/^[a-zA-Z0-9]+$/)) {
                          return Promise.reject(
                            new Error('Private key must not contain special characters!')
                          )
                        }
                      }
                      return Promise.resolve()
                    } else {
                      return Promise.reject(new Error('Private key cannot be empty!'))
                    }
                  },
                },
              ]}
              initialValue={val.prikey}
            >
              <Input.Password />
            </Form.Item>
            <Row>
              <Col>
                <Button htmlType="submit" type="primary" loading={loading}>
                  Edit
                </Button>
              </Col>
              <Col span={1}></Col>
              <Col>
                <Button key="back" onClick={this.handleCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    prikeys: state.prikeyManagement,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    editPriKey: (index, prikey, email) => {
      dispatch(editPriKey(index, prikey, email))
    },
    checkPassword: (email, password) => {
      dispatch(checkPassword(email, password))
    },
    setShowState: (value) => {
      dispatch(setShowState(value))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EditButton)
