import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PageHeader from '../partials/pageHeader'
import { TableRow, Td } from './style'
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const AddressIdStyled = styled.span`
  font-weight: normal;
`
class ContractHeader extends Component {
  state = {
    copied: false,
  }
  render() {
    return (
      <PageHeader className="contract-detail-header">
        Contract{' '}
        <AddressIdStyled>
          #{this.props.contract}
          <CopyToClipboard
            text={this.props.contract}
            onCopy={() => {
              this.setState({ copied: true })
              setTimeout(
                function () {
                  this.setState({ copied: false })
                }.bind(this),
                1000
              )
            }}
          >
            {this.state.copied ? (
              <span style={{ marginLeft: '10px' }}>
                <CheckCircleOutlined /> Copied
              </span>
            ) : (
              <CopyOutlined style={{ marginLeft: '10px' }} />
            )}
          </CopyToClipboard>
        </AddressIdStyled>
      </PageHeader>
    )
  }
}
const mapStateToProps = (state) => {
  return {}
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(ContractHeader)
