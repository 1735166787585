import React from 'react'
import { List } from 'antd'
// import { BlockOutlined } from '@ant-design/icons'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { connect } from 'react-redux'
import { loadRecentTrans } from '../../actions/home'
import { Row, Col } from 'antd'
import { GLOBAL_SYMBOL } from '../../constant'
import {
  RecentListTitleFrame,
  RecentListTitle,
  RecentListContentFrame,
  RecentItem,
  RecentItemData,
  StyledLink,
  // RecentRightCol,
  RecentListContainer,
  RecentItemContainer,
  TimeAgo,
  RecentItemReward,
  AssetName,
} from './recent-list'
import { currencyFormat, decimalFormat, mapTransactionType } from '../../utils/utils'
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo'
import TruncateMiddle from 'react-truncate-string'

class TransactionList extends React.Component {
  componentDidMount() {
    this.props.loadRecentTrans()
  }
  tranItem = (tran) => {
    var amount = ''
    const contractType = tran.contract.type
    switch (contractType) {
      default:
        amount = ''
        break
      case 'TransferContract':
        amount = currencyFormat(decimalFormat(tran.contract.parameter.raw.amount / 1000000))
        break
      case 'TransferAssetContract':
        amount = currencyFormat(tran.amount / tran.pow_decimal)
        break
    }
    return (
      <>
        <RecentItemContainer key={tran.hash}>
          <RecentItem>
            <Row>
              <Col span={12}>
                <div>
                  Transaction:{' '}
                  <RecentItemData>
                    <StyledLink to={`/transaction/${tran.hash}`} target="_blank">
                      {tran.hash.substring(0, 5) +
                        '...' +
                        tran.hash.substring(59, tran.hash.length)}
                    </StyledLink>
                  </RecentItemData>
                </div>
                <div>
                  Type: <RecentItemData>{mapTransactionType(tran.contract.type)}</RecentItemData>
                </div>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={24} className="text-right">
                    <div style={{ minHeight: 22 }}>
                      <RecentItemReward>{amount} </RecentItemReward>
                      {tran.contract.type === 'TransferAssetContract' ? (
                        <StyledLink
                          to={`/token/${tran.contract.parameter.raw.asset_id}`}
                          target="_blank"
                        >
                          {tran.contract.parameter.raw.asset_name}
                        </StyledLink>
                      ) : (
                        ''
                      )}
                      {contractType === 'TransferContract' ? (
                        <AssetName>{GLOBAL_SYMBOL}</AssetName>
                      ) : (
                        ''
                      )}
                    </div>
                    <div>
                      <TimeAgo>
                        {tran.timestamp ? (
                          <ReactTimeAgo date={new Date(tran.timestamp)} locale="en-US" />
                        ) : (
                          'unknown'
                        )}
                      </TimeAgo>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </RecentItem>
        </RecentItemContainer>
      </>
    )
  }
  render() {
    let trans = this.props.trans == null ? [] : this.props.trans
    trans.sort(function (a, b) {
      return b.timestamp - a.timestamp
    })
    return (
      <RecentListContainer>
        <RecentListTitleFrame>
          <RecentListTitle style={{ fontWeight: 'bold' }}>Recent Transactions</RecentListTitle>
        </RecentListTitleFrame>
        <RecentListContentFrame>
          <PerfectScrollbar>
            <List
              dataSource={trans}
              renderItem={(tran) => this.tranItem(tran)}
              loading={trans.length === 0 ? true : false}
              locale={{ emptyText: 'Loading' }}
            />
          </PerfectScrollbar>
        </RecentListContentFrame>
      </RecentListContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    trans: state.homeTrans.trans,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadRecentTrans: () => {
      dispatch(loadRecentTrans())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TransactionList)
