import React from 'react'
import {
  Table,
  Form,
  Button,
  Space,
  Row,
  Col,
  Input,
  Modal,
  Typography,
  Tooltip,
  notification,
  Spin,
  Affix,
  Popover,
  InputNumber,
} from 'antd'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import {
  LikeTwoTone,
  CaretUpOutlined,
  ClockCircleTwoTone,
  HourglassTwoTone,
  DownCircleOutlined,
  UpCircleFilled,
  QuestionCircleOutlined,
  ArrowUpOutlined,
  QrcodeOutlined,
} from '@ant-design/icons'
import { FaExchangeAlt } from 'react-icons/fa'
import NumberFormat from 'react-number-format'
import PageHeader from '../partials/pageHeader'
import { getVote, updateVote } from '../../actions/votes'
import { getDefaulteyFromToken } from '../../common/commonTransaction'
import { loadAddressApi } from '../../actions/address'
import IconThanks from '../../assets/images/iconThanks.png'
import { initTransactionDetail, loadTransactionDetails } from '../../actions/transaction'
import AccountQrCode from '../accountQrCode'

const { Text } = Typography

const Wrapper = styled.div`
  margin-bottom: 30px;
`
const StyledSpace = styled(Space)`
  background-color: #ffffff;
  padding: 2px;
  border-radius: 20px;
  border: 1px solid #dedede;
  .anticon {
    font-size: 24px;
    &:hover {
      color: #1890ff;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-input-number-input {
    text-align: center;
    padding: 0;
  }
`
const StyledInputNumber = styled(InputNumber)`
  border: none;
  width: 50px;
  .ant-input-number-handler-wrap {
    display: none;
  }
`
const StyledButton = styled(Button)`
  background-color: #e0ae5c;
  border-color: #e0ae5c;
  color: #e0ae5c;
  &:hover,
  &:focus {
    color: #e0ae5c;
    border-color: #e0ae5c;
  }
`
const TopVote = styled(Row)`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  .anticon {
    font-size: 20px;
    margin-right: 20px;
  }
`
const TopVoteTitle = styled.p`
  margin-bottom: 5px;
`
const TopVoteData = styled.b`
  color: ${(props) => props.color};
  font-size: 16px;
  .anticon {
    font-size: 10px;
  }
`
const StyledAffix = styled(Affix)`
  margin-bottom: 20px;
`
const StyledLink = styled(Link)`
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`
const NameWitness = styled.div`
  font-weight: 600;
`
const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`

class Votes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showVote: false,
      defaultPriv: getDefaulteyFromToken(),
      search: '',
      showQrCode: false,
      address: '',
      available: 0,
      loading: false,
    }
  }
  formRef = React.createRef()
  componentDidMount() {
    const { address } = this.state.defaultPriv
    this.props.getVote()
    address && this.props.loadAddress(address)
    this.props.initTransactionDetail()
  }

  columns = [
    {
      title: 'Name',
      render: ({ name, address }) => (
        <Popover
          content={
            <Space direction="vertical">
              <Link to="#">
                <Row align="middle" onClick={() => this.setState({ showQrCode: true, address })}>
                  <QrcodeOutlined />
                  &nbsp; Show QR Code
                </Row>
              </Link>
              <Link to={{ pathname: '/user/transferasset', state: address }}>
                <Row align="middle">
                  <FaExchangeAlt />
                  &nbsp; Send Tokens
                </Row>
              </Link>
            </Space>
          }
          trigger="contextMenu"
          placement="right"
        >
          <Link to={`/accounts/${address}`}>
            <NameWitness>{name ? name : '_'}</NameWitness>
            {address.substring(0, 5) + '...' + address.substring(29, address.length)}
          </Link>
        </Popover>
      ),
    },
    {
      title: 'Last ranking',
      dataIndex: 'last_ranking',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.last_ranking - b.last_ranking,
    },
    {
      title: 'Last votes',
      dataIndex: 'vote_count',
      render: (vote_count) => (
        <NumberFormat displayType="text" thousandSeparator={true} value={vote_count} />
      ),
    },
    {
      title: (
        <Space>
          <Tooltip title="SR votes in the last round/all votes">
            <QuestionCircleOutlined />
          </Tooltip>
          Percentage
        </Space>
      ),
      render: (record) => (
        <NumberFormat
          displayType="text"
          thousandSeparator={true}
          value={
            Math.round((record.vote_count / this.props.votes.totalVotes) * 100 * Math.pow(10, 2)) /
            Math.pow(10, 2)
          }
          suffix="%"
        />
      ),
    },
    {
      title: <Text type="danger">Current ranking</Text>,
      dataIndex: 'current_ranking',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.current_ranking - b.current_ranking,
    },
    {
      title: <Text type="danger">Current votes</Text>,
      render: ({ current_votes, vote_count }) => (
        <>
          <NumberFormat displayType="text" thousandSeparator={true} value={current_votes} />
          <br />
          <Text
            type={
              current_votes - vote_count > 0
                ? 'success'
                : current_votes - vote_count < 0
                ? 'danger'
                : ''
            }
          >
            <NumberFormat
              displayType="text"
              thousandSeparator={true}
              value={current_votes - vote_count}
              prefix={current_votes - vote_count > 0 && '+'}
            />
          </Text>
        </>
      ),
    },
    {
      title: (
        <Space>
          <Tooltip title="Rate of SR's reward to be distributed to his/her voters. Voter's reward = SR's reward * Voters' reward rate * (voter's votes/all votes for the SR).">
            <QuestionCircleOutlined />
          </Tooltip>
          Voting sharing ratio
        </Space>
      ),
      dataIndex: 'brokerage',
      render: (brokerage) => <>{100 - brokerage}%</>,
    },
    {
      title: 'Your vote',
      key: 'vote',
      fixed: 'right',
      render: (record) => {
        const findVote = this.props.address.votes?.find(
          (item) => item.vote_address === record.address
        )
        return (
          <StyledSpace>
            <Button
              type="text"
              icon={<DownCircleOutlined />}
              onClick={() => {
                let voteValue = Number(this.formRef.current.getFieldValue(record.address))
                voteValue > 0 &&
                  this.setState({ available: this.state.available + 1 }, () =>
                    this.formRef.current.setFieldsValue({ [record.address]: (voteValue -= 1) })
                  )
              }}
            />
            <Form.Item shouldUpdate={true}>
              {({ getFieldsValue }) => {
                const fieldsValue = getFieldsValue(true)
                let totalWithoutMe = 0
                for (const key in fieldsValue) {
                  if (Object.hasOwnProperty.call(fieldsValue, key)) {
                    if (key !== record.address) {
                      const val = Number(fieldsValue[key])
                      totalWithoutMe = totalWithoutMe + val
                    }
                  }
                }
                return (
                  <StyledFormItem
                    name={record.address}
                    initialValue={findVote?.vote_count ? findVote?.vote_count : ''}
                  >
                    <StyledInputNumber
                      min={0}
                      max={this.props.address.tronpower - totalWithoutMe}
                      step={1}
                      parser={(value) => parseInt(value)}
                      onKeyPress={(e) => !/\d/.test(e.key) && e.preventDefault()}
                    />
                  </StyledFormItem>
                )
              }}
            </Form.Item>
            <Button
              type="text"
              icon={<UpCircleFilled style={{ color: '#190F5D' }} />}
              onClick={() => {
                let voteValue = Number(this.formRef.current.getFieldValue(record.address))
                this.state.available > 0 &&
                  this.setState({ available: this.state.available - 1 }, () =>
                    this.formRef.current.setFieldsValue({ [record.address]: (voteValue += 1) })
                  )
              }}
            />
          </StyledSpace>
        )
      },
    },
  ]

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.address.address !== this.props.address.address) {
      if (nextProps.address.tronpower && nextProps.address.votes) {
        const voteCount = nextProps.address.votes.reduce(
          (previousValue, currentValue) => previousValue + currentValue.vote_count,
          0
        )
        this.setState({ available: nextProps.address.tronpower - voteCount })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { updateLoading, message, txid } = this.props.votes
    const { hash, loading } = this.props.transaction
    if (txid !== prevProps.votes.txid && !updateLoading) {
      this.props.loadTransactionDetails(txid, 5)
      this.setState({ showVote: false })
    }
    if (hash !== prevProps.transaction.hash && !loading) {
      this.setState({ loading: false })
      Modal.info({
        title: <img src={IconThanks} />,
        content: (
          <>
            <p>
              <b>Thank you for voting!</b>
            </p>
            <p>
              Your votes are successfully submitted, they will take effect when the next voting
              cycle starts. You may redistribute your votes anytime you like.
            </p>
          </>
        ),
        okText: 'OK',
        icon: '',
        className: 'modal-become-witness',
        onOk: () => {
          this.props.loadAddress(this.state.defaultPriv.address)
          this.props.getVote()
        },
      })
    }
    if (message !== prevProps.votes.message && !updateLoading) {
      notification.error({
        message,
      })
      this.formRef.current.resetFields()
      this.initialAvailable()
      this.setState({ loading: false })
    }
  }

  obtain = () => {
    Modal.warning({
      title: 'Not enough WIN',
      content: 'You need at least 1 WIN to be able to stake',
    })
  }

  initialAvailable = () => {
    if (this.props.address.tronpower) {
      const voteCount = this.props.address.votes?.reduce(
        (previousValue, currentValue) => previousValue + currentValue.vote_count,
        0
      )
      this.setState({ available: this.props.address.tronpower - (voteCount ? voteCount : 0) })
    }
  }

  onShowVote = () => {
    if (!localStorage.getItem('token')) {
      this.props.history.push('/login')
    } else {
      if (!this.state.defaultPriv.priv) {
        notification.warning({
          message: 'Warning!',
          description:
            'You have no private key, please add somes in private key management to perform transaction!',
        })
      } else {
        this.setState({ showVote: true })
        this.formRef.current.resetFields()
        this.initialAvailable()
      }
    }
  }

  onCancel = () => {
    this.setState({ showVote: false })
    this.formRef.current.resetFields()
  }

  onReset = () => {
    const { votes } = this.props.votes
    const { tronpower } = this.props.address
    if (votes) {
      for (let i = 0; i < votes.length; i++) {
        this.formRef.current.setFieldsValue({
          [votes[i].address]: '',
        })
      }
      this.setState({ available: tronpower ? tronpower : 0 })
    }
  }

  onValuesChange = (_, allValues) => {
    const arr = Object.entries(allValues)?.filter((item) => item[1] > 0)
    const voteCount = arr.reduce(
      (previousValue, currentValue) => Number(previousValue) + Number(currentValue[1]),
      0
    )
    if (this.props.address.tronpower - voteCount >= 0) {
      this.setState({ available: this.props.address.tronpower - voteCount })
    }
  }

  onFinish = (values) => {
    const { address, priv } = this.state.defaultPriv
    const votes = Object.entries(values)
      ?.filter((item) => item[1] > 0)
      ?.map((item) => ({ vote_address: item[0], vote_count: Number(item[1]) }))

    this.setState({ loading: true })
    this.props.updateVote({
      owner_address: address,
      votes,
      private_key: priv,
    })
  }

  render() {
    const { votes, loading } = this.props.votes
    const { tronpower } = this.props.address
    return (
      <Wrapper>
        <PageHeader>Votes</PageHeader>
        {/* <Row gutter={[16, 16]}>
          <Col span={24} md={8}>
            <TopVote align="middle">
              <HourglassTwoTone twoToneColor="#e0ae5c" />
              <div>
                <TopVoteTitle>Next round</TopVoteTitle>
                <TopVoteData>01:52:25</TopVoteData>
              </div>
            </TopVote>
          </Col>
          <Col span={24} md={8}>
            <TopVote align="middle">
              <ClockCircleTwoTone twoToneColor="#e0ae5c" />
              <div>
                <TopVoteTitle>Real-time total votes this round</TopVoteTitle>
                <TopVoteData>
                  <NumberFormat displayType="text" thousandSeparator={true} value={22674128025} />
                </TopVoteData>
              </div>
            </TopVote>
          </Col>
          <Col span={24} md={8}>
            <TopVote align="middle">
              <LikeTwoTone twoToneColor="#e0ae5c" />
              <div>
                <TopVoteTitle>Most votes gained this round</TopVoteTitle>
                <TopVoteData color="#52c41a">
                  <NumberFormat
                    displayType="text"
                    thousandSeparator={true}
                    prefix="+"
                    value={659034}
                  />
                  <CaretUpOutlined />
                </TopVoteData>
              </div>
            </TopVote>
          </Col>
        </Row> */}
        <Spin spinning={this.state.loading}>
          <Form
            ref={this.formRef}
            onValuesChange={this.onValuesChange}
            onFinish={this.onFinish}
            autoComplete="off"
          >
            <StyledAffix>
              <Row align="middle" justify="space-between" gutter={[16, 16]}>
                <Col span={24} md={8}>
                  <Input.Search
                    placeholder="Search for SRs by address/name"
                    allowClear
                    enterButton
                    onSearch={(e) => this.setState({ search: e })}
                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                  />
                </Col>
                {this.state.showVote && (
                  <Col>
                    Total：{tronpower | 0}，Available：{this.state.available}
                    <Button type="link">
                      <StyledLink to="/user/freeze-balance">Obtain</StyledLink>
                    </Button>
                  </Col>
                )}
                <Col>
                  {this.state.showVote ? (
                    <Space>
                      <Button type="primary" danger onClick={this.onCancel}>
                        Cancel
                      </Button>
                      <StyledButton ghost onClick={this.onReset}>
                        Reset
                      </StyledButton>
                      <Button type="primary" htmlType="submit">
                        Voting
                      </Button>
                    </Space>
                  ) : (
                    <Button type="primary" onClick={this.onShowVote}>
                      <ArrowUpOutlined />
                      Voting
                    </Button>
                  )}
                </Col>
              </Row>
            </StyledAffix>
            <Table
              columns={
                this.state.showVote && tronpower > 0
                  ? this.columns
                  : this.columns.filter((col) => col.key !== 'vote')
              }
              dataSource={
                votes &&
                votes.filter(
                  (item) =>
                    item.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    item.address.toLowerCase().includes(this.state.search.toLowerCase())
                )
              }
              scroll={{ x: 'auto' }}
              loading={loading}
              pagination={false}
              showSorterTooltip={false}
            />
          </Form>
        </Spin>
        <AccountQrCode
          showQrCode={this.state.showQrCode}
          hideQrCode={() => this.setState({ showQrCode: false })}
          address={this.state.address}
        />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    votes: state.votes,
    address: state.address,
    transaction: state.transaction,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getVote: () => {
      dispatch(getVote())
    },
    updateVote: (values) => {
      dispatch(updateVote(values))
    },
    loadAddress: (address) => {
      dispatch(loadAddressApi(address))
    },
    initTransactionDetail: () => {
      dispatch(initTransactionDetail())
    },
    loadTransactionDetails: (txid, timeToTry) => {
      dispatch(loadTransactionDetails(txid, timeToTry))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withRouter(Votes)
)
