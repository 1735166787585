import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Button, Form, Input, Layout, Avatar, Spin } from 'antd'
import { ContainerOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import { loadContractApi } from '../../actions/contract'
import { API_ADDR } from '../../config/config'
import { getDefaulteyFromToken } from '../../common/commonTransaction'
import { Redirect } from 'react-router'
import { format } from 'date-fns-tz'
import {
  initTokenRecord,
  TOKEN_RECORD_REQUESTING,
  TOKEN_RECORD_SUCCESS,
  TOKEN_RECORD_FAIL,
  updateTokenRecord,
} from '../../actions/tokenRecord'
import { LoadingOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const { TextArea } = Input

const StyledLink = styled(Link)`
  &:link,
  &:visited {
    color: #c23631;
  }
`
const Item = styled(Form.Item)`
  font-weight: bold;
`
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`
const Logo = styled.img`
  height: 66px;
  width: 200px;
  margin-bottom: 20px;
`
const Title = styled.span`
  font-size: 25px;
  font-weight: 600;
`
const ContentTitle = styled.span`
  color: #9f9f9f;
  font-size: 12px;
  font-family: serif;
`
const TitleContainer = styled.div`
  text-align: left;
`
const Result = styled.p`
  border-bottom: 0.5px dashed grey;
  color: black;
`
const StyledForm = styled(Form)``
const IconSocial = styled(Avatar)`
  margin: 5px;
  width: 20px;
  height: 20px;
`
const DefaultTokenIcon = styled.div`
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fff;
  border: 1px dashed #dedede;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const ContainResult = styled.div`
  display: flex;
  align-items: flex-end;
`
const LogoDefault = styled.img`
  width: -webkit-fill-available;
  filter: grayscale(1) blur(1.5px);
  padding: 10px;
  transform: rotate(-45deg);
`
const IconContainerOutlined = styled(ContainerOutlined)`
  font-size: 45px;
  border: 1px solid grey;
  padding: 10px;
  border-radius: 65px;
`
const IconCheckCircleOutlined = styled(CheckCircleOutlined)`
  margin-left: -16px;
  color: green;
  background: #fff;
  border-radius: 51px;
`
const IconCloseCircleOutlined = styled(CloseCircleOutlined)`
  margin-left: -16px;
  color: red;
  background: #fff;
  border-radius: 51px;
`
const { Header, Footer, Content } = Layout

class DoneTokenRecord extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultPriv: getDefaulteyFromToken(),
      addressUpdate: false,
    }
  }
  componentDidMount() {
    if (window.location.pathname.split('/')[1] != 'token-record') {
      this.setState({
        addressUpdate: true,
      })
    } else {
      this.setState({
        addressUpdate: false,
      })
    }
    this.state.defaultPriv.priv === undefined ? <Redirect to="/token-record/create/Type" /> : ''
  }

  render() {
    const { tokenRecord } = this.props
    const { addressUpdate } = this.state
    const current = tokenRecord.current_url
    const type_choose = tokenRecord.type_choose
    const antIcon = <LoadingOutlined spin />

    if (current == 1) {
      return <Redirect to={window.location.pathname.replace('/Done', '') + '/Type'} />
    } else if (current == 2) {
      return <Redirect to={window.location.pathname.replace('/Done', '') + '/Record'} />
    } else if (current == 3) {
      return <Redirect to={window.location.pathname.replace('/Done', '') + '/Confirm'} />
    }
    return (
      <Spin
        indicator={antIcon}
        tip="Processing..."
        spinning={tokenRecord.status === TOKEN_RECORD_REQUESTING}
      >
        <Layout style={{ textAlign: 'center', marginTop: '20px' }}>
          {addressUpdate ? (
            <Content>
              <ContainResult style={{ placeContent: 'center' }}>
                <IconContainerOutlined />
                {tokenRecord.status == TOKEN_RECORD_SUCCESS ? (
                  <IconCheckCircleOutlined />
                ) : (
                  <IconCloseCircleOutlined />
                )}
              </ContainResult>
              <p>
                {tokenRecord.status == TOKEN_RECORD_SUCCESS
                  ? 'Token updated successfully'
                  : 'Token update failed'}
              </p>
              <p>
                {tokenRecord.status == TOKEN_RECORD_SUCCESS ? (
                  <p>
                    Your token:
                    <StyledLink
                      to={
                        '/' +
                        (tokenRecord.data.token_record_type == 'WRC20'
                          ? 'token20'
                          : tokenRecord.data.token_record_type == 'WRC721'
                          ? 'token721'
                          : 'token10') +
                        '/' +
                        tokenRecord.data.contract_address
                      }
                      target="_blank"
                    >
                      {' ' + tokenRecord.data.contract_address}
                    </StyledLink>
                  </p>
                ) : tokenRecord.status == TOKEN_RECORD_SUCCESS ? (
                  ' Record Updated Successfully'
                ) : (
                  'Reason for failure: ' + tokenRecord.message
                )}
              </p>
            </Content>
          ) : (
            <Content>
              <ContainResult style={{ placeContent: 'center' }}>
                <IconContainerOutlined />
                {tokenRecord.status == TOKEN_RECORD_SUCCESS ? (
                  <IconCheckCircleOutlined />
                ) : (
                  <IconCloseCircleOutlined />
                )}
              </ContainResult>
              <p>
                {tokenRecord.status == TOKEN_RECORD_SUCCESS
                  ? 'Token recorded successfully'
                  : 'Token record failed'}
              </p>
              <p>
                {tokenRecord.status == TOKEN_RECORD_SUCCESS ? (
                  <p>
                    Your token:
                    <StyledLink
                      to={
                        '/' +
                        (tokenRecord.data.token_record_type == 'WRC20'
                          ? 'token20'
                          : tokenRecord.data.token_record_type == 'WRC721'
                          ? 'token721'
                          : 'token10') +
                        '/' +
                        tokenRecord.data._id
                      }
                      target="_blank"
                    >
                      {' ' + tokenRecord.data._id}
                    </StyledLink>
                  </p>
                ) : (
                  tokenRecord.message
                )}
              </p>
            </Content>
          )}
          {tokenRecord.status == TOKEN_RECORD_FAIL ? (
            <Footer style={{ textAlign: 'right' }}>
              <Button
                type="default"
                style={{ borderRadius: '0px', marginRight: '5px' }}
                onClick={() => {
                  window.location.href = '/home'
                }}
              >
                Not ready to submit
              </Button>
              <Button
                type="primary"
                style={{ borderRadius: '0px' }}
                onClick={() => {
                  this.props.updateTokenRecord(tokenRecord.data, 2, type_choose)
                }}
              >
                Resubmit
              </Button>
            </Footer>
          ) : (
            <></>
          )}
        </Layout>
      </Spin>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tokenRecord: state.tokenRecord,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    initTokenRecord: () => {
      dispatch(initTokenRecord())
    },
    updateTokenRecord: (data, current_url, type_choose) => {
      dispatch(updateTokenRecord(data, current_url, type_choose))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  DoneTokenRecord
)
