import fetch from 'cross-fetch'

import { API_ADDR } from '../config/config'
export const TRANSACTION_DETAIL_INIT = 'TRANSACTION_DETAIL_INIT'
export const TRANSACTION_DETAIL_UDPATE = 'TRANSACTION_DETAIL_UDPATE'

export function initTransactionDetail() {
  return {
    type: TRANSACTION_DETAIL_INIT,
  }
}
export function updateTransactionDetails(tran) {
  return {
    type: TRANSACTION_DETAIL_UDPATE,
    payload: tran,
  }
}

export function loadTransactionDetails(txHash, timeToTry = 3) {
  return (dispatch) => {
    dispatch(initTransactionDetail())
    var fetchData
    var time = 0
    function checkTransactionStatus() {
      if (time === timeToTry) {
        return
      }
      fetch(`${API_ADDR}/transactions/${txHash}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      })
        .then((res) => res.json())
        .then((res) => {
          dispatch(updateTransactionDetails(res.data))
          clearInterval(fetchData)
        })
        .catch((err) => {
          setTimeout(() => {
            checkTransactionStatus()
          }, 3000)
          time === timeToTry ? (window.location.href = '/notfound') : ''
          return
        })
      time++
    }
    checkTransactionStatus()
  }
}
