import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FaExchangeAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { Col, Row } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons'
import {
  Details,
  Top,
  Content,
  StyledRow,
  StyledSpace,
  ConsumeContainer,
  InfoConsumeContainer,
} from './style'

class ProposalCreateContract extends Component {
  state = {
    copied: false,
  }
  render() {
    const { bandwidth_fee, consume_bandwidth, contract } = this.props.transaction
    const owner = contract.parameter.raw ? contract.parameter.raw.owner_address : '–'
    const parameters = contract.parameter.raw ? contract.parameter.raw.parameters : {}
    const bandwidth_fee_1 = bandwidth_fee / Math.pow(10, 6)
    const bandwidth_fee_2 = bandwidth_fee / Math.pow(10, 3)
    console.log(parameters.length)
    return (
      <Details>
        <Top>
          <Row align="middle">
            <FaExchangeAlt />
            &nbsp; Create a Proposal
          </Row>
        </Top>
        <Content>
          <StyledRow>
            <Col md={6} span={24}>
              Owner Address:
            </Col>
            <Col md={18} span={24}>
              {owner.length < 34 ? (
                '–'
              ) : (
                <>
                  <Link to={`/accounts/${owner}`}>{owner}</Link>
                  <CopyToClipboard
                    text={owner}
                    onCopy={() => {
                      this.setState({ copied: true })
                      setTimeout(
                        function () {
                          this.setState({ copied: false })
                        }.bind(this),
                        1000
                      )
                    }}
                  >
                    {this.state.copied ? (
                      <span style={{ marginLeft: '10px' }}>
                        <CheckCircleOutlined /> Copied
                      </span>
                    ) : (
                      <CopyOutlined style={{ marginLeft: '10px' }} />
                    )}
                  </CopyToClipboard>
                </>
              )}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Proposal Content:
            </Col>
            <Col md={18} span={24}>
              <span>
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={0.027777777777777776}
                  suffix=" Hour"
                  prefix="Propose to modify the maintenance interval of SR to "
                />
              </span>
              <br />
              <span>
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={
                    parameters && Object.keys(parameters).length === 2
                      ? parameters[1] / parameters[0]
                      : 0.000002
                  }
                  suffix=" WIN"
                  prefix="Propose to modify the cost of applying for SR account to "
                />
              </span>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Consume Bandwidth:
            </Col>
            <Col md={18} span={24}>
              <ConsumeContainer>
                <InfoConsumeContainer>
                  <NumberFormat
                    displayType={'text'}
                    thousandSeparator={true}
                    value={consume_bandwidth + bandwidth_fee_2}
                    suffix=" Bandwidth"
                  />
                </InfoConsumeContainer>
                <InfoConsumeContainer>
                  <StyledSpace wrap>&#8735; Consumption of staked/free bandwidth:</StyledSpace>
                  <NumberFormat
                    displayType={'text'}
                    thousandSeparator={true}
                    value={consume_bandwidth}
                    suffix=" Bandwidth"
                  />
                </InfoConsumeContainer>
                <InfoConsumeContainer>
                  <StyledSpace wrap>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={bandwidth_fee_1}
                      prefix="&#8735; Burn "
                      suffix=" WIN for bandwidth:"
                    />
                  </StyledSpace>
                  <NumberFormat
                    displayType={'text'}
                    thousandSeparator={true}
                    value={bandwidth_fee_2}
                    suffix=" Bandwidth"
                  />
                </InfoConsumeContainer>
              </ConsumeContainer>
            </Col>
          </StyledRow>
        </Content>
      </Details>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transaction: state.transaction,
  }
}

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(ProposalCreateContract)
