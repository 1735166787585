import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as action from '../../actions/contract'
import { Row, Col, Skeleton } from 'antd'
import { Table, BorderRed, Div, FontFamily, Title, TableRow, Th } from './style'
import ContractAddress from './contractAddress'
import ContractOverview from './contractOverview'
import ContractInformation from './contractInformation'
import ContractMenu from './contractMenu'
import ContractHeader from './contractHeader'
import ContractSwitchMenu from './contractSwitchMenu'
import PageHeader from '../partials/pageHeader'
import CardShadow from '../partials/cardShadow'
import EmptySpace from '../partials/emptySpace'
import { Redirect } from 'react-router'
import NumberFormat from 'react-number-format'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import { API_ADDR } from '../../config/config'

class Contract extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contract_record: {},
    }
  }
  async componentDidMount() {
    this.props.urlAtNotPrivFunc()
    var id = this.props.match.params.id
    this.props.loadContract(id)
    this.props.history.push('/contract/' + id)

    var result = await fetch(`${API_ADDR}/tokenrecord/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'fail') {
          return {}
        } else {
          return res.data
        }
      })
      .catch((err) => {
        return ''
      })

    this.setState({
      contract_record: result,
    })
  }

  render() {
    var { contract } = this.props

    return (
      <div>
        {contract.contract_address === undefined ? (
          <Redirect to={`/notfound`} />
        ) : (
          <Skeleton loading={this.props.contract === {}}>
            <ContractHeader contract={this.props.match.params.id} />
            <CardShadow className="contract-detail-content">
              <Div className="contract-detail-general">
                <Col className="ant-col-xl-11 ant-col-lg-11 ant-col-md-12 ant-col-sm-24 ant-col-xs-24">
                  <Title className="contract-detail-general-title">Contract Overview</Title>
                  <Table>
                    <ContractOverview contract_record={this.state.contract_record} />
                  </Table>
                </Col>
                <Col className="ant-col-xl-11 ant-col-lg-11 ant-col-md-12 ant-col-sm-24 ant-col-xs-24">
                  <Title className="contract-detail-general-title">Information</Title>
                  <Table>
                    <ContractInformation />
                  </Table>
                </Col>
              </Div>
            </CardShadow>
            <EmptySpace />
            <CardShadow>
              <ContractMenu address={this.props.match.params.id} />
              <ContractSwitchMenu
                address={this.props.match.params.id}
                loadContract={this.props.loadContract}
                totalSupply={contract.totalSupply / Math.pow(10, contract.decimals)}
              />
            </CardShadow>
            <EmptySpace />
          </Skeleton>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    contract: state.contract,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadContract: (addr) => {
      dispatch(action.loadContractApi(addr))
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Contract)
