import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Button, Col, Divider, Input, Layout, Row, Spin, Checkbox, Modal } from 'antd'
import LogoWin from '../../assets/images/logo_Company.png'
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { getDefaulteyFromToken } from '../../common/commonTransaction'
import { Redirect } from 'react-router'
import {
  addressUpdateTokenRecordAPI,
  insertTokenRecord,
  TOKEN_RECORD_REQUESTING,
  updateTokenRecord,
} from '../../actions/tokenRecord'
import ConfigKey from '../../api/key'
import NumberFormat from 'react-number-format'

const { TextArea } = Input

const ContentTitle = styled.span`
  color: #9f9f9f;
  font-size: 12px;
  font-family: serif;
`
const TitleContainer = styled.div`
  text-align: left;
`
const Result = styled.p`
  border-bottom: 0.5px dashed grey;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
`
const LogoDefault = styled.img`
  width: -webkit-fill-available;
  filter: grayscale(1) blur(1.5px);
  padding: 10px;
  transform: rotate(-45deg);
`
const TitleForm = styled.p`
  margin-top: 1em;
  margin-bottom: 1em;
`
const CustomDivider = styled(Divider)`
  margin-top: 0;
  margin-bottom: 1em;
`

const { Footer, Content } = Layout

class ConfirmTokenRecord extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultPriv: getDefaulteyFromToken(),
      addressUpdate: false,
      check_ok: false,
      mess_check_ok: '',
      visible_fee: false,
      REACT_APP_API_CREATE_RC10_FEE: 0,
      REACT_APP_API_CREATE_RC20_FEE: 0,
      REACT_APP_API_CREATE_RC721_FEE: 0,
      REACT_APP_API_UPDATE_RC10_FEE: 0,
      REACT_APP_API_UPDATE_RC20_FEE: 0,
      REACT_APP_API_UPDATE_RC721_FEE: 0,
    }
  }
  async componentDidMount() {
    if (window.location.pathname.split('/')[1] != 'token-record') {
      this.setState({
        addressUpdate: true,
        check_ok: true,
      })
    } else {
      this.setState({
        addressUpdate: false,
      })
    }
    this.state.defaultPriv.priv === undefined ? (
      <Redirect to={window.location.pathname + '/Type'} />
    ) : (
      ''
    )
    const config_key = await ConfigKey.getkey()
    this.setState({
      REACT_APP_API_CREATE_RC10_FEE: config_key.REACT_APP_API_CREATE_RC10_FEE,
      REACT_APP_API_CREATE_RC20_FEE: config_key.REACT_APP_API_CREATE_RC20_FEE,
      REACT_APP_API_CREATE_RC721_FEE: config_key.REACT_APP_API_CREATE_RC721_FEE,
      REACT_APP_API_UPDATE_RC10_FEE: config_key.REACT_APP_API_UPDATE_RC10_FEE,
      REACT_APP_API_UPDATE_RC20_FEE: config_key.REACT_APP_API_UPDATE_RC20_FEE,
      REACT_APP_API_UPDATE_RC721_FEE: config_key.REACT_APP_API_UPDATE_RC721_FEE,
    })
  }
  checkAccess(e) {
    this.setState({
      check_ok: e.target.checked,
      mess_check_ok: '',
    })
  }
  submitButton() {
    const temp = this.props.tokenRecord
    if (!this.state.check_ok && temp.type_choose == 3) {
      this.setState({
        mess_check_ok: 'Need to tick the Checkbox',
      })
    } else {
      this.setState({
        mess_check_ok: '',
      })
      temp.data.price_win = parseInt(temp.data.price_win)
      temp.data.price_usd = parseInt(temp.data.price_usd)

      if (temp.data.position_win_first) {
        const temp_price = temp.data.price_win
        temp.data.price_win = temp.data.price_usd
        temp.data.price_usd = temp_price
      }

      temp.data.token_supply = String(
        temp.data.token_supply_without_precision * Math.pow(10, temp.data.precision)
      )
      temp.data.token_supply_without_precision = String(temp.data.token_supply_without_precision)
      temp.data.precision = parseInt(temp.data.precision)

      if (this.state.addressUpdate) {
        if (temp.type_choose == 3) {
          temp.data.contract_address = window.location.pathname
            .replaceAll('/update/token10/', '')
            .replaceAll('/Confirm', '')
        }
        this.showModal()
      } else {
        temp.data.token_record_type =
          temp.type_choose == 1 ? 'WRC20' : temp.type_choose == 2 ? 'WRC721' : 'WRC10'
        this.showModal()
      }
    }
  }

  showModal = () => {
    this.setState({
      visible_fee: true,
    })
  }

  handleCancel = () => {
    this.setState({ visible_fee: false })
  }

  render() {
    const { tokenRecord } = this.props
    const {
      visible_fee,
      addressUpdate,
      REACT_APP_API_CREATE_RC10_FEE,
      REACT_APP_API_CREATE_RC20_FEE,
      REACT_APP_API_CREATE_RC721_FEE,
      REACT_APP_API_UPDATE_RC10_FEE,
      REACT_APP_API_UPDATE_RC20_FEE,
      REACT_APP_API_UPDATE_RC721_FEE,
    } = this.state
    const current = tokenRecord.current_url
    const type_choose = tokenRecord.type_choose
    const antIcon = <LoadingOutlined spin />

    if (current == 1) {
      return <Redirect to={window.location.pathname.replace('/Confirm', '') + '/Type'} />
    } else if (current == 2) {
      return <Redirect to={window.location.pathname.replace('/Confirm', '') + '/Record'} />
    } else if (current == 4) {
      return <Redirect to={window.location.pathname.replace('/Confirm', '') + '/Done'} />
    }
    return (
      <Spin
        indicator={antIcon}
        tip="Processing..."
        spinning={tokenRecord.status === TOKEN_RECORD_REQUESTING}
      >
        <Modal
          visible={visible_fee}
          title={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '40px', color: '#DF711B' }} />{' '}
            </div>
          }
          closeIcon={<></>}
          onCancel={this.handleCancel}
          footer={[
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                key="back"
                onClick={() => {
                  this.setState({ visible_fee: false })
                }}
                type="default"
                style={{ padding: '0 35px' }}
              >
                Cancel
              </Button>
              <Button
                key="back"
                onClick={() => {
                  if (addressUpdate) {
                    this.props.addressUpdateTokenRecordAPI(
                      this.props.tokenRecord,
                      this.state.defaultPriv.priv
                    )
                  } else {
                    this.props.insertTokenRecord(
                      this.props.tokenRecord.data,
                      this.state.defaultPriv.priv
                    )
                  }
                  this.setState({ visible_fee: false })
                }}
                type="primary"
                style={{ padding: '0 35px' }}
              >
                Confirm
              </Button>
            </div>,
          ]}
        >
          <p style={{ width: '100%', textAlign: 'center' }}>
            {addressUpdate
              ? 'Update fee: ' +
                (type_choose == 1
                  ? REACT_APP_API_UPDATE_RC20_FEE
                  : type_choose == 2
                  ? REACT_APP_API_UPDATE_RC721_FEE
                  : REACT_APP_API_UPDATE_RC10_FEE) +
                ' '
              : 'Record fee: ' +
                (type_choose == 1
                  ? REACT_APP_API_CREATE_RC20_FEE
                  : type_choose == 2
                  ? REACT_APP_API_CREATE_RC721_FEE
                  : REACT_APP_API_CREATE_RC10_FEE) +
                ' '}
            WIN
          </p>
        </Modal>

        <Layout>
          <Content>
            {type_choose !== 3 ? (
              <>
                <TitleForm>Contract Information</TitleForm>
                <CustomDivider />
                <Row gutter={[24, 8]}>
                  <Col span={12}>
                    <TitleContainer>
                      <ContentTitle>Contract Address:</ContentTitle>
                    </TitleContainer>
                    <Result>
                      {tokenRecord.data.contract_address == ''
                        ? '–'
                        : tokenRecord.data.contract_address}
                    </Result>
                  </Col>
                  <Col span={12}>
                    <TitleContainer>
                      <ContentTitle>Contract Name:</ContentTitle>
                    </TitleContainer>
                    <Result>
                      {tokenRecord.data.contract_name == '' ? '–' : tokenRecord.data.contract_name}
                    </Result>
                  </Col>
                </Row>
                <Row gutter={[24, 8]}>
                  <Col span={12}>
                    <TitleContainer>
                      <ContentTitle>Contract creator:</ContentTitle>
                    </TitleContainer>
                    <Result>
                      {tokenRecord.data.contract_creator == ''
                        ? '–'
                        : tokenRecord.data.contract_creator}
                    </Result>
                  </Col>
                </Row>
              </>
            ) : (
              ''
            )}
            <TitleForm>Basic Information</TitleForm>
            <CustomDivider />
            <Row gutter={[24, 8]}>
              <Col span={12}>
                <TitleContainer>
                  <ContentTitle>Token name:</ContentTitle>
                </TitleContainer>
                <Result>
                  {tokenRecord.data.token_name == '' ? '–' : tokenRecord.data.token_name}
                </Result>
              </Col>
              <Col span={12}>
                <TitleContainer>
                  <ContentTitle>Token abbreviation:</ContentTitle>
                </TitleContainer>
                <Result>{tokenRecord.data.token_abbreviation}</Result>
              </Col>
            </Row>
            <Row gutter={[24, 8]}>
              <Col span={12}>
                <TitleContainer>
                  <ContentTitle>Token introduction:</ContentTitle>
                </TitleContainer>
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 4 }}
                  disabled
                  value={tokenRecord.data.token_introduction}
                />
              </Col>
              <Col span={12}>
                <TitleContainer>
                  <ContentTitle>Total Supply:</ContentTitle>
                </TitleContainer>
                <Result>
                  <NumberFormat
                    displayType={'text'}
                    thousandSeparator={true}
                    value={tokenRecord.data.token_supply_without_precision}
                  />
                </Result>
              </Col>
            </Row>
            <Row gutter={[24, 8]}>
              {type_choose != 2 ? (
                <Col span={12}>
                  <TitleContainer>
                    <ContentTitle>Precision:</ContentTitle>
                  </TitleContainer>
                  <Result>{tokenRecord.data.precision}</Result>
                </Col>
              ) : (
                <></>
              )}
              <Col span={12}>
                <TitleContainer>
                  <ContentTitle>Issuer:</ContentTitle>
                </TitleContainer>
                <Result>{tokenRecord.data.issuer == '' ? '–' : tokenRecord.data.issuer}</Result>
              </Col>
            </Row>
            <Row style={{ display: 'grid' }}>
              <TitleContainer>
                <ContentTitle>Token logo:</ContentTitle>
              </TitleContainer>
              <div
                class="ant-upload ant-upload-select-picture-card"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  cursor: 'unset',
                }}
              >
                {tokenRecord.data.token_icon != '' ? (
                  <img src={tokenRecord.data.token_icon} alt="avatar" style={{ width: '100%' }} />
                ) : (
                  <LogoDefault src={LogoWin} />
                )}
              </div>
            </Row>
            {type_choose === 3 ? (
              <>
                <TitleForm>Price Information</TitleForm>
                <CustomDivider />
                <TitleContainer>
                  <ContentTitle>
                    <span style={{ color: 'red' }}>* </span>Token price:
                  </ContentTitle>
                </TitleContainer>

                <div style={{ display: 'flex' }}>
                  {tokenRecord.data.position_win_first
                    ? tokenRecord.data.price_win +
                      (tokenRecord.data.token_abbreviation === undefined
                        ? ''
                        : ' ' +
                          tokenRecord.data.token_abbreviation
                            .trim()
                            .replace(/[^\x0-\x7]/g, '')
                            .replace(/[\u{0080}-\u{FFFF}]/gu, '')) +
                      ' ≈ ' +
                      tokenRecord.data.price_usd +
                      ' WIN'
                    : tokenRecord.data.price_usd +
                      (tokenRecord.data.token_abbreviation === undefined
                        ? ''
                        : ' ' +
                          tokenRecord.data.token_abbreviation
                            .trim()
                            .replace(/[^\x0-\x7]/g, '')
                            .replace(/[\u{0080}-\u{FFFF}]/gu, '')) +
                      ' ≈ ' +
                      tokenRecord.data.price_win +
                      ' WIN'}
                </div>
              </>
            ) : (
              ''
            )}
            <TitleForm>Social Media Information</TitleForm>
            <CustomDivider />
            <Row gutter={[24, 8]}>
              <Col span={12}>
                <TitleContainer>
                  <ContentTitle>Project offical website:</ContentTitle>
                </TitleContainer>
                <Result>
                  {tokenRecord.data.project_offical_website == ''
                    ? '–'
                    : tokenRecord.data.project_offical_website}
                </Result>
              </Col>
              <Col span={12}>
                <TitleContainer>
                  <ContentTitle>Email:</ContentTitle>
                </TitleContainer>
                <Result>{tokenRecord.data.email == '' ? '–' : tokenRecord.data.email}</Result>
              </Col>
            </Row>
            <Row gutter={[24, 8]}>
              <Col span={12}>
                <TitleContainer>
                  <ContentTitle>Link for Github:</ContentTitle>
                </TitleContainer>
                <Result>
                  {tokenRecord.data.link_for_github == '' ? '–' : tokenRecord.data.link_for_github}
                </Result>
              </Col>
              <Col span={12}>
                <TitleContainer>
                  <ContentTitle>Link for white paper:</ContentTitle>
                </TitleContainer>
                <Result>
                  {tokenRecord.data.link_for_white_paper == ''
                    ? '–'
                    : tokenRecord.data.link_for_white_paper}
                </Result>
              </Col>
            </Row>
            <span>Social media link(s) added:</span>
            <Row gutter={[24, 8]}>
              {tokenRecord.data.social_media_link != null
                ? Object.keys(tokenRecord.data.social_media_link).map((key, i) =>
                    tokenRecord.data.social_media_link[key] == '' ? (
                      ''
                    ) : (
                      <Col span={12}>
                        <TitleContainer>
                          <ContentTitle style={{ textTransform: 'capitalize' }}>
                            {key}:
                          </ContentTitle>
                        </TitleContainer>
                        <Result>{tokenRecord.data.social_media_link[key]}</Result>
                      </Col>
                    )
                  )
                : ''}
            </Row>
            {type_choose == 3 && !this.state.addressUpdate ? (
              <div style={{ marginTop: '30px', position: 'absolute' }}>
                <Checkbox onChange={(e) => this.checkAccess(e)} checked={this.state.check_ok}>
                  I have learned that issuing a token requires a one-time consumption of{' '}
                  {REACT_APP_API_CREATE_RC10_FEE} WIN.
                </Checkbox>
                <br />
                <small style={{ color: '#d9534f', marginLeft: '5px' }}>
                  {this.state.mess_check_ok}
                </small>
              </div>
            ) : (
              <></>
            )}
          </Content>

          <Footer style={{ textAlign: 'right' }}>
            <Button
              type="default"
              style={{ borderRadius: '0px', marginRight: '5px' }}
              onClick={() => {
                this.props.updateTokenRecord(tokenRecord.data, 2, type_choose)
              }}
            >
              Previous
            </Button>
            <Button
              type="primary"
              style={{ borderRadius: '0px' }}
              onClick={() => this.submitButton()}
            >
              Submit
            </Button>
          </Footer>
        </Layout>
      </Spin>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tokenRecord: state.tokenRecord,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateTokenRecord: (data, current_url, type_choose) => {
      dispatch(updateTokenRecord(data, current_url, type_choose))
    },
    insertTokenRecord: (data, priv) => {
      dispatch(insertTokenRecord(data, priv))
    },
    addressUpdateTokenRecordAPI: (data, priv) => {
      dispatch(addressUpdateTokenRecordAPI(data, priv))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  ConfirmTokenRecord
)
