import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { TableRow, Td } from './style'

class AddressTransaction extends Component {
  render() {
    let { transaction } = this.props

    return (
      <TableRow>
        <Td>
          <span>Transactions</span>:
        </Td>
        <td>
          <div>
            <span>
              <NumberFormat
                displayType={'text'}
                thousandSeparator={true}
                value={
                  (transaction.from === undefined ? 0 : transaction.from) +
                  (transaction.to === undefined ? 0 : transaction.to)
                }
              />
              {' Txns'}
            </span>
          </div>
        </td>
      </TableRow>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transaction: state.address.transaction === undefined ? {} : state.address.transaction,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AddressTransaction)
