import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FaExchangeAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { Col, Row } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons'
import {
  Details,
  Top,
  Content,
  StyledRow,
  StyledSpace,
  ConsumeContainer,
  InfoConsumeContainer,
} from './style'

class UnfreezeBalanceContract extends Component {
  state = {
    copied_owner: false,
    copied_receive: false,
  }
  render() {
    const { amount, pow_decimal, consume_bandwidth, contract, bandwidth_fee } =
      this.props.transaction
    const owner = contract.parameter.raw ? contract.parameter.raw.owner_address : '–'
    const receive_address = contract.parameter.raw ? contract.parameter.raw.receive_address : '–'
    const resource = contract.parameter.raw ? contract.parameter.raw.resource : '–'
    const bandwidth_fee_1 = bandwidth_fee / Math.pow(10, 6)
    const bandwidth_fee_2 = bandwidth_fee / Math.pow(10, 3)
    return (
      <Details>
        <Top>
          <Row align="middle">
            <FaExchangeAlt />
            &nbsp; Unfreeze Balance Contract
          </Row>
        </Top>
        <Content>
          <StyledRow>
            <Col md={6} span={24}>
              Owner Address:
            </Col>
            <Col md={18} span={24}>
              {owner.length < 34 ? (
                '–'
              ) : (
                <>
                  <Link to={`/accounts/${owner}`}>{owner}</Link>
                  <CopyToClipboard
                    text={owner}
                    onCopy={() => {
                      this.setState({ copied_owner: true })
                      setTimeout(
                        function () {
                          this.setState({ copied_owner: false })
                        }.bind(this),
                        1000
                      )
                    }}
                  >
                    {this.state.copied_owner ? (
                      <span style={{ marginLeft: '10px' }}>
                        <CheckCircleOutlined /> Copied
                      </span>
                    ) : (
                      <CopyOutlined style={{ marginLeft: '10px' }} />
                    )}
                  </CopyToClipboard>
                </>
              )}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Receiver Address:
            </Col>
            <Col md={18} span={24}>
              {owner.length < 34 ? (
                '–'
              ) : (
                <>
                  <Link to={`/accounts/${receive_address}`}>{receive_address}</Link>
                  <CopyToClipboard
                    text={receive_address}
                    onCopy={() => {
                      this.setState({ copied_receive: true })
                      setTimeout(
                        function () {
                          this.setState({ copied_receive: false })
                        }.bind(this),
                        1000
                      )
                    }}
                  >
                    {this.state.copied_receive ? (
                      <span style={{ marginLeft: '10px' }}>
                        <CheckCircleOutlined /> Copied
                      </span>
                    ) : (
                      <CopyOutlined style={{ marginLeft: '10px' }} />
                    )}
                  </CopyToClipboard>
                </>
              )}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Resource:
            </Col>
            <Col md={18} span={24}>
              <span>{resource}</span>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Amount:
            </Col>
            <Col md={18} span={24}>
              <NumberFormat
                displayType={'text'}
                thousandSeparator={true}
                value={amount / pow_decimal}
                suffix=" WIN"
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Consume Bandwidth:
            </Col>
            <Col md={18} span={24}>
              <ConsumeContainer>
                <InfoConsumeContainer>
                  <NumberFormat
                    displayType={'text'}
                    thousandSeparator={true}
                    value={consume_bandwidth + bandwidth_fee_2}
                    suffix=" Bandwidth"
                  />
                </InfoConsumeContainer>
                <InfoConsumeContainer>
                  <StyledSpace wrap>&#8735; Consumption of staked/free bandwidth:</StyledSpace>
                  <NumberFormat
                    displayType={'text'}
                    thousandSeparator={true}
                    value={consume_bandwidth}
                    suffix=" Bandwidth"
                  />
                </InfoConsumeContainer>
                <InfoConsumeContainer>
                  <StyledSpace wrap>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={bandwidth_fee_1}
                      prefix="&#8735; Burn "
                      suffix=" WIN for bandwidth:"
                    />
                  </StyledSpace>
                  <NumberFormat
                    displayType={'text'}
                    thousandSeparator={true}
                    value={bandwidth_fee_2}
                    suffix=" Bandwidth"
                  />
                </InfoConsumeContainer>
              </ConsumeContainer>
            </Col>
          </StyledRow>
        </Content>
      </Details>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transaction: state.transaction,
  }
}

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(UnfreezeBalanceContract)
