import React from 'react'
import { Table, Pagination, Input, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { currencyFormat } from '../../utils/utils'
import NumberFormat from 'react-number-format'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import {
  loadTokenHolders,
  updatePageTokenHolders,
  updatePageTokenHoldersLimit,
  initPageTokenHolders,
} from '../../actions/tokenHolders'
import { loadTopTokenHolders } from '../../actions/topTokenHolders'
import { loadFindTokenHolders, initFindTokenHolders } from '../../actions/findTokenHolders'

const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`

const TopItem = styled.div`
  margin-right: 20px;
`

const TopIcon = styled.div`
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: ${(props) => props.color};
  margin-right: 8px;
`

const Graph = styled.div`
  display: flex;
  margin: 10px 0;
  height: 30px;
`

const GraphItem = styled.div`
  flex: ${(props) => props.percent} 1 0%;
  background-color: ${(props) => props.color};
`

const Hr = styled.div`
  border-bottom: 1px solid #dedede;
  margin: 30px 0;
`

const HoldersAddress = styled(Row)`
  margin-bottom: 30px;
`

const TotalHoldersAddress = styled.span`
  font-size: 13px;
  display: block;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`

const StyledLink = styled(Link)``

const Wrapper = styled.div`
  margin: 10px;
`
const Pagin = styled.div`
  margin-top: 15px;
  text-align: right !important;
`

class TokenHolderTable extends React.Component {
  componentDidMount() {
    this.props.urlAtNotPrivFunc()
    this.props.initPageTokenHolders()
    this.props.loadTopTokenHolders(this.props.address)
    this.props.initFindTokenHolders()
    const { pageTokenHolders } = this.props
    this.props.loadTokenHolders(
      this.props.address,
      pageTokenHolders.current_page,
      pageTokenHolders.page_limit
    )
  }

  onSearch = (value) => {
    if (value === '') {
      this.props.initFindTokenHolders()
    } else {
      this.props.loadFindTokenHolders(this.props.address, value)
    }
  }

  onChange = (pageNumber, pageLimit) => {
    this.props.updatePageTokenHolders(pageNumber)
    var { pageTokenHolders } = this.props
    if (pageLimit !== pageTokenHolders.page_limit) {
      this.props.updatePageTokenHoldersLimit(pageLimit)
    }
    this.props.loadTokenHolders(
      this.props.address,
      pageTokenHolders.current_page,
      pageTokenHolders.page_limit
    )
  }

  handleTopPercent = (value) => {
    const percent = ((value / this.props.totalSupply) * 100).toFixed(2)
    if (!this.props.totalSupply || value === undefined) {
      return 0
    }
    return percent
  }

  render() {
    var { tokenHolders, pageTokenHolders, topTokenHolders, totalSupply, findTokenHolders } =
      this.props
    const columns = [
      {
        title: 'No.',
        render: (_, __, index) =>
          findTokenHolders.length
            ? 1
            : index + 1 + (pageTokenHolders.current_page - 1) * pageTokenHolders.page_limit,
        width: 70,
      },
      {
        title: 'Address',
        dataIndex: 'address',
        render: (address) => <StyledLink to={`/accounts/${address}`}>{address}</StyledLink>,
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        render: (quantity) => (
          <NumberFormat
            displayType="text"
            thousandSeparator={true}
            value={quantity?.amount / quantity?.powdecimal}
          />
        ),
      },
      {
        title: 'Percentage',
        dataIndex: 'quantity',
        render: (quantity) => (
          <NumberFormat
            displayType="text"
            thousandSeparator={true}
            suffix="%"
            value={
              Math.round(
                (quantity?.amount / quantity?.powdecimal / totalSupply) * 100 * Math.pow(10, 6)
              ) / Math.pow(10, 6)
            }
          />
        ),
      },
    ]
    return (
      <Wrapper>
        <b>Asset Breakdown by Holders</b>
        <Top>
          <TopItem>
            <TopIcon color="#0477FF" />
            <span>Top 1~10 holders: {this.handleTopPercent(topTokenHolders[0])}%</span>
          </TopItem>
          <TopItem>
            <TopIcon color="#EDB92B" />
            <span>Top 11~50 holders: {this.handleTopPercent(topTokenHolders[1])}%</span>
          </TopItem>
          <TopItem>
            <TopIcon color="#32C956" />
            <span>Top 51~100 holders: {this.handleTopPercent(topTokenHolders[2])}%</span>
          </TopItem>
          <TopItem>
            <TopIcon color="#FF9065" />
            <span>Top 101~500 holders: {this.handleTopPercent(topTokenHolders[3])}%</span>
          </TopItem>
          <TopItem>
            <TopIcon color="#05D2AD" />
            <span>Top 501~∞ holders: {this.handleTopPercent(topTokenHolders[4])}%</span>
          </TopItem>
        </Top>
        <Graph>
          <GraphItem percent={this.handleTopPercent(topTokenHolders[0])} color="#0477FF" />
          <GraphItem percent={this.handleTopPercent(topTokenHolders[1])} color="#EDB92B" />
          <GraphItem percent={this.handleTopPercent(topTokenHolders[2])} color="#32C956" />
          <GraphItem percent={this.handleTopPercent(topTokenHolders[3])} color="#FF9065" />
          <GraphItem percent={this.handleTopPercent(topTokenHolders[4])} color="#05D2AD" />
        </Graph>
        <Hr />
        <HoldersAddress align="middle" justify="space-between">
          <Col span={16}>
            <b>Holders' Address</b>
            <TotalHoldersAddress>
              {!findTokenHolders.length
                ? `A Total of ${pageTokenHolders.total_items} Token Holders`
                : '1 Token Holders Found'}
            </TotalHoldersAddress>
          </Col>
          <Col md={8} xs={24}>
            <Input.Search placeholder="Search Address" onSearch={this.onSearch} allowClear />
          </Col>
        </HoldersAddress>
        <Table
          columns={columns}
          dataSource={!findTokenHolders.length ? tokenHolders : findTokenHolders}
          pagination={false}
          sticky
        />
        <Pagin>
          <Pagination
            current={findTokenHolders.length ? 1 : pageTokenHolders.start_page}
            total={findTokenHolders.length ? 1 : pageTokenHolders.total_items}
            pageSize={pageTokenHolders.page_limit}
            onChange={this.onChange}
            showSizeChanger
            showQuickJumper
          />
        </Pagin>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tokenHolders: state.tokenHolders,
    pageTokenHolders: state.pageTokenHolders,
    topTokenHolders: state.topTokenHolders,
    findTokenHolders: state.findTokenHolders,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadTokenHolders: (address, page, limit) => {
      dispatch(loadTokenHolders(address, page, limit))
    },
    updatePageTokenHolders: (page) => {
      dispatch(updatePageTokenHolders(page))
    },
    updatePageTokenHoldersLimit: (limit) => {
      dispatch(updatePageTokenHoldersLimit(limit))
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
    initPageTokenHolders: () => {
      dispatch(initPageTokenHolders())
    },
    loadTopTokenHolders: (address) => {
      dispatch(loadTopTokenHolders(address))
    },
    loadFindTokenHolders: (address, search) => {
      dispatch(loadFindTokenHolders(address, search))
    },
    initFindTokenHolders: () => {
      dispatch(initFindTokenHolders())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  TokenHolderTable
)
