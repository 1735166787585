import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  loadTransactions,
  updatePageTransactions,
  updatePageTransactionsLimit,
} from '../../actions/transactions'
import { Table, Pagination, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { mapTransactionType } from '../../utils/utils'
import PageHeader from '../partials/pageHeader'
import NumberFormat from 'react-number-format'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo'
import TruncateMiddle from 'react-truncate-string'

const Container = styled.div`
  margin: 5px;
`
const RedText = styled.span``

const PagiContainer = styled.div`
  margin-top: 15px;
  text-align: right;
`
const Token = styled.p`
  color: #c23631;
  font-size: 14px;
`
const StyledLink = styled(Link)``

class TransactionsList extends React.Component {
  componentDidMount() {
    this.props.urlAtNotPrivFunc()
    const { transactions } = this.props
    this.props.loadTransactions(
      transactions.transactionPage.start_item,
      transactions.transactionPage.page_limit
    )
  }

  onChange = (pageNumber, pageLimit) => {
    this.props.updatePageTransactions(pageNumber)
    var { transactions } = this.props
    if (pageLimit !== transactions.transactionPage.page_limit) {
      this.props.updatePageTransactionsLimit(pageLimit)
    }
    this.props.loadTransactions(
      transactions.transactionPage.start_item,
      transactions.transactionPage.page_limit
    )
  }
  render() {
    var { transactions } = this.props
    const columns = [
      {
        title: 'No.',
        key: 'num',
        width: '5%',
        render: (value, item, index) =>
          index +
          1 +
          (transactions.transactionPage.current_page - 1) * transactions.transactionPage.page_limit,
      },
      {
        title: 'Hash',
        dataIndex: 'hash',
        key: 'hash',
        width: '12%',
        render: (text) => (
          <Link to={'/transaction/' + text}>
            <RedText>
              <Tooltip placement="top" title={text}>
                <TruncateMiddle text={text} />
              </Tooltip>
            </RedText>
          </Link>
        ),
      },
      {
        title: 'Block',
        dataIndex: 'block_number',
        key: 'blockNumber',
        width: '8%',
        render: (text) => (
          <Link to={'/block/' + text}>
            <RedText>{text}</RedText>
          </Link>
        ),
      },
      {
        title: 'Transaction type',
        dataIndex: 'contract',
        width: '18%',
        key: 'type',
        render: (record) => {
          return <span>{mapTransactionType(record.type)}</span>
        },
      },
      {
        title: 'From',
        key: 'from',
        width: '12%',
        render: (record) =>
          record.contract.parameter.raw ? (
            '–'
          ) : record.contract.parameter.raw.owner_address ? (
            '–'
          ) : record.contract.parameter.raw.owner_address === '' ? (
            '–'
          ) : (
            <StyledLink
              key={record.contract.parameter.raw.owner_address}
              to={'/accounts/' + record.contract.parameter.raw.owner_address}
              onClick={(e) =>
                record.contract.parameter.raw.owner_address === this.props.address
                  ? e.preventDefault()
                  : ''
              }
            >
              <Tooltip placement="top" title={record.contract.parameter.raw.owner_address}>
                <TruncateMiddle text={record.contract.parameter.raw.owner_address} />
              </Tooltip>
            </StyledLink>
          ),
      },
      {
        title: 'To',
        key: 'to',
        width: '12%',
        render: (record) =>
          record.contract.parameter.raw ? (
            '–'
          ) : record.contract.parameter.raw.to_address ? (
            record.contract.parameter.raw.receive_address ? (
              '–'
            ) : record.contract.parameter.raw.receive_address === '' ? (
              '–'
            ) : (
              <StyledLink
                key={record.contract.parameter.raw.receive_address}
                to={'/accounts/' + record.contract.parameter.raw.receive_address}
                onClick={(e) =>
                  record.contract.parameter.raw.receive_address === this.props.address
                    ? e.preventDefault()
                    : ''
                }
              >
                <Tooltip placement="top" title={record.contract.parameter.raw.receive_address}>
                  <TruncateMiddle text={record.contract.parameter.raw.receive_address} />
                </Tooltip>
              </StyledLink>
            )
          ) : record.contract.parameter.raw.to_address === '' ? (
            '–'
          ) : (
            <StyledLink
              key={record.contract.parameter.raw.to_address}
              to={'/accounts/' + record.contract.parameter.raw.to_address}
              onClick={(e) =>
                record.contract.parameter.raw.to_address === this.props.address
                  ? e.preventDefault()
                  : ''
              }
            >
              <Tooltip placement="top" title={record.contract.parameter.raw.to_address}>
                <TruncateMiddle text={record.contract.parameter.raw.to_address} />
              </Tooltip>
            </StyledLink>
          ),
      },
      {
        title: 'Amount',
        width: '12%',
        key: 'amount',
        render: (record) =>
          record.contract.type === 'FreezeBalanceContract' ? (
            <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              value={record.contract.parameter.raw.frozen_balance / Math.pow(10, 6)}
            />
          ) : (
            <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              value={record.amount / record.pow_decimal}
            />
          ),
      },
      {
        title: 'Asset',
        width: '10%',
        key: 'asset',
        render: (record) => {
          let assetName =
            record.name === ''
              ? '–'
              : record.name
                  .trim()
                  .replace(/[^\x0-\x7]/g, '')
                  .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                (record.symbol === ''
                  ? ''
                  : ' (' +
                    record.symbol
                      .trim()
                      .replace(/[^\x0-\x7]/g, '')
                      .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                    ')')
          if (assetName === 'WEL') {
            assetName = 'WIN'
          }
          return (
            <Token>
              <Tooltip placement="top" title={assetName}>
                <TruncateMiddle text={assetName} />
              </Tooltip>
            </Token>
          )
        },
      },
      {
        title: 'Age',
        width: '14%',
        key: 'timestamp',
        render: (record) =>
          record.timestamp ? (
            <ReactTimeAgo date={new Date(record.timestamp)} locale="en-US" />
          ) : (
            'unknown'
          ),
      },
    ]
    return (
      <Container>
        <PageHeader>List of Transactions</PageHeader>
        <div id="datetime"></div>
        <Table
          columns={columns}
          dataSource={transactions.transactions}
          rowKey="hash"
          scroll={{ x: 1300 }}
          sticky
          pagination={false}
          loading={
            transactions.transactions !== null
              ? transactions.transactions.length === 0
                ? true
                : false
              : false
          }
          locale={{ emptyText: 'Loading' }}
        />
        <PagiContainer>
          <Pagination
            current={transactions.transactionPage.start_page}
            total={transactions.transactionPage.total_items}
            pageSize={transactions.transactionPage.page_limit}
            onChange={this.onChange}
            showSizeChanger
            showQuickJumper
          />
        </PagiContainer>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transactions: state.transactions,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadTransactions: (offset, limit) => {
      dispatch(loadTransactions(offset, limit))
    },
    updatePageTransactions: (page) => {
      dispatch(updatePageTransactions(page))
    },
    updatePageTransactionsLimit: (limit) => {
      dispatch(updatePageTransactionsLimit(limit))
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  TransactionsList
)
