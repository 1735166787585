import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deletePriKey } from '../../actions/prikeyManagement'
import { Row, Col, Modal, Input, Button, Form } from 'antd'
import { deleteDefaultPrivateKey } from '../../actions/defaultPrivateKey'

class DeleteButton extends Component {
  formRef = React.createRef()
  state = {
    loading: false,
    visible: false,
  }

  showModal = (e) => {
    e.preventDefault()
    this.setState({
      visible: true,
    })
  }

  handleOk = () => {
    var { login, index, pageSize } = this.props
    this.props.deletePriKey(index, login.email)

    this.props.deleteDefaultPrivateKey(login.email)
    this.setState({ visible: false })
    if (localStorage.getItem(login.email)) {
      const privskey = JSON.parse(localStorage.getItem(login.email))
      this.props.setCurrentPage(
        privskey.length % pageSize === 0
          ? parseInt(privskey.length / pageSize)
          : parseInt(privskey.length / pageSize) + 1
      )
    }
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  render() {
    const { visible, loading } = this.state
    return (
      <Col>
        <Button type="primary" onClick={(e) => this.showModal(e)} style={{ marginRight: '10px' }}>
          Delete
        </Button>
        <Modal visible={visible} onCancel={this.handleCancel} footer={null}>
          <Form
            layout="vertical"
            name="login"
            initialValues={{
              remember: true,
            }}
            onFinish={this.handleOk}
            size="medium"
            ref={this.formRef}
          >
            <i>
              Do you want to delete address: <p>{this.props.address}</p>
            </i>
            <Row>
              <Col>
                <Button htmlType="submit" type="primary" loading={loading}>
                  Delete
                </Button>
              </Col>
              <Col span={1}></Col>
              <Col>
                <Button key="back" onClick={this.handleCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    prikeys: state.prikeyManagement,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    deletePriKey: (index, email) => {
      dispatch(deletePriKey(index, email))
    },
    deleteDefaultPrivateKey: (email) => {
      dispatch(deleteDefaultPrivateKey(email))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  DeleteButton
)
