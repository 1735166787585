import React from 'react'
import { connect } from 'react-redux'
import { BadgeGreen, BadgeRed, StyledLink } from './style'
import { Table } from 'antd'
import { toTimeAgo } from '../../utils/utils'
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo'
import { getCurrentBlock } from '../../common/commonTransaction'

const columns = [
  {
    title: 'No.',
    key: 'no',
    render: (value, item, index) => index + 1,
    fixed: 'left',
    width: 70,
  },
  {
    title: 'Hash',
    dataIndex: 'hash',
    key: 'hash',
    render: (text) => (
      <StyledLink key={text} to={`/transaction/${text}`}>
        {text}
      </StyledLink>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (record) =>
      record === 'confirmed' ? <BadgeGreen count="CONFIRMED" /> : <BadgeRed count="UNCOMFIRMED" />,
  },
  {
    title: 'Age',
    key: 'age',
    render: (record) =>
      record.timestamp ? (
        <ReactTimeAgo date={new Date(record.timestamp)} locale="en-US" />
      ) : (
        'unknown'
      ),
  },
  {
    title: 'Contract Type',
    key: 'type',
    dataIndex: 'type',
  },
]

class TransactionTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      temo: [],
      block_height: 0,
    }
  }

  async componentDidMount() {
    var { block_num } = this.props

    var block_height = await getCurrentBlock()

    this.setState({
      block_height: block_height,
    })

    if (block_height - block_num < 21) {
      let getBlockHeight = setInterval(
        async function () {
          var block_height = await getCurrentBlock()
          this.setState({
            block_height: block_height,
          })
          if (block_height - block_num >= 21) {
            clearInterval(getBlockHeight)
          }
        }.bind(this),
        2000
      )
    }
  }
  render() {
    var { transactionList, confirm, block_num } = this.props
    var { block_height } = this.state
    let transactionListUpdateStatus = []
    if (transactionList) {
      transactionListUpdateStatus = [...transactionList]
      transactionList.map((item, index) => {
        transactionListUpdateStatus[index] = {
          ...transactionListUpdateStatus[index],
          status: block_height - block_num >= 21 ? 'confirmed' : 'unconfirmed',
        }
      })
    }
    return (
      <Table
        columns={columns}
        dataSource={transactionListUpdateStatus}
        rowKey="hash"
        scroll={{ x: 1300 }}
        sticky
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transactionList: state.blockTransaction,
    confirm: state.block.confirm,
    block_num: state.block.block_num,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  TransactionTable
)
