import React from 'react'
import { Table, Button, Row, Tabs, Typography, Tooltip, Space, Popover } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { QuestionCircleOutlined, ArrowUpOutlined, QrcodeOutlined } from '@ant-design/icons'
import { FaExchangeAlt } from 'react-icons/fa'
import NumberFormat from 'react-number-format'
import PageHeader from '../partials/pageHeader'
import { getVote } from '../../actions/votes'
import AccountQrCode from '../accountQrCode'

const { Text } = Typography
const { TabPane } = Tabs

const Wrapper = styled.div`
  margin-bottom: 30px;
`
const Intro = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin: 10px 0 20px 0;
`
const NameWitness = styled.div`
  font-weight: 600;
`

class Representatives extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showQrCode: false,
      address: '',
    }
  }
  componentDidMount() {
    this.props.getVote()
  }

  columns = [
    {
      title: 'Rank',
      dataIndex: 'last_ranking',
    },
    {
      title: 'Name',
      render: ({ name, address, url }) => (
        <Popover
          content={
            <Space direction="vertical">
              <Link to="#">
                <Row align="middle" onClick={() => this.setState({ showQrCode: true, address })}>
                  <QrcodeOutlined />
                  &nbsp; Show QR Code
                </Row>
              </Link>
              <Link to={{ pathname: '/user/transferasset', state: address }}>
                <Row align="middle">
                  <FaExchangeAlt />
                  &nbsp; Send Tokens
                </Row>
              </Link>
            </Space>
          }
          trigger="contextMenu"
          placement="right"
        >
          <Link to={`/accounts/${address}`}>
            <NameWitness>{name ? name : '_'}</NameWitness>
          </Link>
          <small>
            <a href={url} target="_blank">
              {url}
            </a>
          </small>
        </Popover>
      ),
    },
    {
      title: 'Last block',
      dataIndex: 'lastest_block_num',
      render: (lastest_block_num) => (
        <Link to={`/block/${lastest_block_num}`}>{lastest_block_num}</Link>
      ),
    },
    {
      title: 'Blocks produced',
      dataIndex: 'total_produced',
      render: (total_produced) => (
        <NumberFormat displayType="text" thousandSeparator={true} value={total_produced} />
      ),
    },
    {
      title: 'Blocks missed	',
      dataIndex: 'total_missed',
      render: (total_missed) => (
        <NumberFormat displayType="text" thousandSeparator={true} value={total_missed} />
      ),
    },
    {
      title: 'Current votes',
      render: ({ current_votes, vote_count }) => (
        <>
          <NumberFormat displayType="text" thousandSeparator={true} value={current_votes} />
          <br />
          <Text
            type={
              current_votes - vote_count > 0
                ? 'success'
                : current_votes - vote_count < 0
                ? 'danger'
                : ''
            }
          >
            <NumberFormat
              displayType="text"
              thousandSeparator={true}
              value={current_votes - vote_count}
              prefix={current_votes - vote_count > 0 && '+'}
            />
          </Text>
        </>
      ),
    },
    {
      title: (
        <Space>
          <Tooltip title="Rate of SR's reward to be distributed to his/her voters. Voter's reward = SR's reward * Voters' reward rate * (voter's votes/all votes for the SR).">
            <QuestionCircleOutlined />
          </Tooltip>
          Voting sharing ratio
        </Space>
      ),
      dataIndex: 'brokerage',
      render: (brokerage) => <>{100 - brokerage}%</>,
    },
  ]

  render() {
    const { votes, loading } = this.props.votes
    return (
      <Wrapper>
        <PageHeader>Representatives</PageHeader>
        <Row justify="end">
          <Link to="/wallet/witness">Apply to be a SR &gt;</Link>
        </Row>
        <Intro>
          On the WINUPS network, all WIN holders can apply to be SR candidates, and are likely to
          become SRs or super partners. Any WIN holder can vote for SR candidates, among which the
          top 27 most-voted candidates will become SRs, while the 28th to 127th will become super
          partners. SRs are responsible for producing blocks and packing transactions; super
          partners, on the other hand, only receive voting rewards without performing the
          aforementioned two tasks. All SR candidates, super partners and SRs have the right to
          raise proposals to modify parameters on the WINUPS network.
        </Intro>
        <Tabs
          defaultActiveKey="superRepresentatives"
          tabBarExtraContent={{
            right: (
              <Button type="primary">
                <Link to="/votes">
                  <ArrowUpOutlined />
                  &nbsp; Voting
                </Link>
              </Button>
            ),
          }}
        >
          <TabPane tab="Super representatives" key="superRepresentatives">
            <Table
              columns={this.columns}
              dataSource={votes?.filter((item) => item.last_ranking < 28)}
              scroll={{ x: 'auto' }}
              loading={loading}
              pagination={false}
            />
          </TabPane>
          <TabPane tab="SR partners" key="srPartners">
            <Table
              columns={this.columns}
              dataSource={votes?.filter(
                (item) => item.last_ranking > 27 && item.last_ranking < 128
              )}
              scroll={{ x: 'auto' }}
              loading={loading}
              pagination={{ defaultPageSize: 20, showSizeChanger: true }}
            />
          </TabPane>
          <TabPane tab="SR candidates" key="srCandidates">
            <Table
              columns={this.columns}
              dataSource={votes?.filter((item) => item.last_ranking > 127)}
              scroll={{ x: 'auto' }}
              loading={loading}
              pagination={{ defaultPageSize: 20, showSizeChanger: true }}
            />
          </TabPane>
        </Tabs>
        <AccountQrCode
          showQrCode={this.state.showQrCode}
          hideQrCode={() => this.setState({ showQrCode: false })}
          address={this.state.address}
        />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    votes: state.votes,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getVote: () => {
      dispatch(getVote())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  Representatives
)
