import React from 'react'
import { Table, Pagination, Badge } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  initPageBlocks,
  loadBlocks,
  updatePageBlocks,
  updatePageBlocksLimit,
} from '../../actions/blocks'
import styled from 'styled-components'
import PageHeader from '../partials/pageHeader'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo'
import { API_ADDR } from '../../config/config'

const StyledLink = styled(Link)``
const Wrapper = styled.div`
  margin: 5px;
`
const Pagin = styled.div`
  margin-top: 15px;
  text-align: right !important;
`
const BadgeGreen = styled(Badge)`
  .ant-badge-count {
    background-color: #e1f3e0;
    color: black;
  }
`

const BadgeRed = styled(Badge)`
  .ant-badge-count {
    background-color: #ff7677;
    color: black;
  }
`

class BlockTable extends React.Component {
  async componentDidMount() {
    this.props.initPageBlocks()
    var { block_height } = await fetch(`${API_ADDR}/system/state`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        return res.data
      })
    this.props.urlAtNotPrivFunc()
    var { pageBlocks } = this.props
    this.props.loadBlocks(block_height, pageBlocks.page_limit)
  }

  onChange = (pageNumber, pageLimit) => {
    var { pageBlocks } = this.props
    if (pageNumber !== pageBlocks.current_page) {
      this.props.updatePageBlocks(pageNumber)
    }
    if (pageLimit !== pageBlocks.page_limit) {
      this.props.updatePageBlocksLimit(pageLimit)
    }
    this.props.loadBlocks(pageBlocks.start_item + 1, pageLimit)
  }

  render() {
    var { blocks, pageBlocks, status } = this.props
    const columns = [
      {
        title: 'No.',
        key: 'no',
        render: (value, item, index) =>
          index + 1 + (pageBlocks.current_page - 1) * pageBlocks.page_limit,
        fixed: 'left',
        width: 70,
      },
      {
        title: 'Height',
        dataIndex: 'num',
        width: 120,
        key: 'height',
        render: (text) => (
          <StyledLink key={text} to={'/block/' + text}>
            {text}
          </StyledLink>
        ),
      },
      {
        title: 'Age',
        key: 'age',
        render: (record) =>
          record.timestamp ? (
            <ReactTimeAgo date={new Date(record.timestamp)} locale="en-US" />
          ) : (
            'unknown'
          ),
      },
      {
        title: 'Status',
        key: 'status',
        render: (record) => {
          var status = record.confirmed ? (
            <BadgeGreen count="CONFIRMED" />
          ) : (
            <BadgeRed count="UNCOMFIRMED" />
          )
          return status
        },
      },
      {
        title: 'Transactions',
        dataIndex: 'num_of_txs',
        width: 90,
        key: 'transactions',
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'Producer',
        key: 'producer',
        render: (record) => (
          <StyledLink key={record.witness_name} to={`/accounts/${record.witness_address}`}>
            {record.witness_name}
          </StyledLink>
        ),
      },
    ]
    return (
      <Wrapper>
        <PageHeader>List of Blocks</PageHeader>
        <Table
          columns={columns}
          dataSource={blocks}
          rowKey="num"
          scroll={{ x: 700 }}
          sticky
          pagination={false}
          loading={status ? false : true}
        />
        <Pagin>
          <Pagination
            current={pageBlocks.start_page}
            total={pageBlocks.total_items}
            pageSize={pageBlocks.page_limit}
            onChange={this.onChange}
            showSizeChanger
            showQuickJumper
          />
        </Pagin>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    blocks: state.blocks.blocks,
    pageBlocks: state.pageBlocks,
    status: state.blocks.status,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updatePageBlocks: (page) => {
      dispatch(updatePageBlocks(page))
    },
    updatePageBlocksLimit: (limit) => {
      dispatch(updatePageBlocksLimit(limit))
    },
    loadBlocks: (offset, limit) => {
      dispatch(loadBlocks(offset, limit))
    },
    initPageBlocks: () => {
      dispatch(initPageBlocks())
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BlockTable)
