import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableRow, Th, QuestionMark, Flex, contentRatio, StyledLink } from './style'
import { Avatar, Popover } from 'antd'
import { currencyFormat } from '../../utils/utils'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'

import IconTwitter from '../../assets/images/iconTwitter.png'
import IconTelegram from '../../assets/images/iconTelegram.png'
import IconFacebook from '../../assets/images/iconFacebook.png'
import IconWeibo from '../../assets/images/iconWeibo.png'
import IconReddit from '../../assets/images/iconReddit.png'
import IconSteem from '../../assets/images/iconSteem.png'
import IconMedium from '../../assets/images/iconMedium.png'
import IconInstagram from '../../assets/images/iconInstagram.png'
import IconWechat from '../../assets/images/iconWechat.png'
import IconGroup from '../../assets/images/iconGroup.png'
import IconDiscord from '../../assets/images/iconDiscord.png'
import { Link } from 'react-router-dom'
import { format } from 'date-fns-tz'

const RatioText = styled.span`
  margin-right: 10px;
`
const IconSocial = styled(Avatar)`
  margin: 5px;
  width: 20px;
  height: 20px;
  cursor: 'pointer';
`
class More extends Component {
  constructor(props) {
    super(props)
    this.state = {
      socialMedia: [
        {
          name: 'twitter',
          icon: IconTwitter,
        },
        {
          name: 'telegram',
          icon: IconTelegram,
        },
        {
          name: 'facebook',
          icon: IconFacebook,
        },
        {
          name: 'weibo',
          icon: IconWeibo,
        },
        {
          name: 'reddit',
          icon: IconReddit,
        },
        {
          name: 'steem',
          icon: IconSteem,
        },
        {
          name: 'medium',
          icon: IconMedium,
        },
        {
          name: 'instagram',
          icon: IconInstagram,
        },
        {
          name: 'wechat',
          icon: IconWechat,
        },
        {
          name: 'group',
          icon: IconGroup,
        },
        {
          name: 'discord',
          icon: IconDiscord,
        },
      ],
    }
  }
  render() {
    let { tokenRecord } = this.props
    return (
      <tbody>
        {tokenRecord.data.contract_address.length == 34 ? (
          <TableRow>
            <Th>
              <span>Contract:</span>
            </Th>
            <td>
              <StyledLink to={'/contract/' + tokenRecord.data.contract_address}>
                {tokenRecord.data.contract_address}
              </StyledLink>
            </td>
          </TableRow>
        ) : (
          <TableRow>
            <Th>
              <span>Token ID:</span>
            </Th>
            <td>
              <StyledLink to={'/token/' + tokenRecord.data.contract_address}>
                {tokenRecord.data.contract_address}
              </StyledLink>
            </td>
          </TableRow>
        )}

        {tokenRecord.token_record_type == 'WRC20' ? (
          <TableRow>
            <Th>
              <span>Precision:</span>
            </Th>
            <td>
              <span>{tokenRecord.data.precision}</span>
            </td>
          </TableRow>
        ) : (
          ''
        )}
        <TableRow>
          <Th>
            <span>Issuing Time:</span>
          </Th>
          <td>
            <span>
              {tokenRecord.data.created_time === 0
                ? '–'
                : format(new Date(tokenRecord.data.created_time), 'yyyy-MM-dd HH:mm:ss').toString()}
            </span>
          </td>
        </TableRow>
        <TableRow>
          <Th>
            <span>Issuer:</span>
          </Th>
          <td>
            <StyledLink to={'/accounts/' + tokenRecord.data.issuer}>
              {tokenRecord.data.issuer}
            </StyledLink>
          </td>
        </TableRow>
        <TableRow>
          <Th>
            <span>Official Website:</span>
          </Th>
          <td>
            {tokenRecord.data.project_offical_website === undefined ? (
              <span>–</span>
            ) : (
              <a
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textoverflow: 'ellipsis',
                  display: 'block',
                }}
                href={
                  tokenRecord.data.project_offical_website.split('www.').length == 2
                    ? 'http://' + tokenRecord.data.project_offical_website.split('www.')[1]
                    : tokenRecord.data.project_offical_website.split('www.')
                }
                target="_blank"
              >
                {tokenRecord.data.project_offical_website}
              </a>
            )}
          </td>
        </TableRow>
        <TableRow>
          <Th>
            <span>Social Profiles:</span>
          </Th>
          <td>
            <span>
              {Object.keys(tokenRecord.data.social_media_link).map((key, i) =>
                tokenRecord.data.social_media_link[key] == ''
                  ? ''
                  : this.state.socialMedia.map((item) =>
                      item.name == key ? (
                        <a
                          href={
                            tokenRecord.data.social_media_link[key].split('www.').length == 2
                              ? 'http://' + tokenRecord.data.social_media_link[key].split('www.')[1]
                              : tokenRecord.data.social_media_link[key].split('www.')
                          }
                          target="_blank"
                        >
                          <IconSocial src={item.icon} />
                        </a>
                      ) : (
                        ''
                      )
                    )
              )}
            </span>
          </td>
        </TableRow>
      </tbody>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tokenRecord: state.tokenRecord,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(More)
