import React, { Component } from 'react'
import { connect } from 'react-redux'
import Card from './card'
import Trigger from './lowerCard/triggerSmartContract'
import TransferContract from './lowerCard/transferContract'
import TransferAssetContract from './lowerCard/transferAssetContract'
import CreateSmartContract from './lowerCard/createSmartContract'
import IssueAssetContract from './lowerCard/issueAssetContract'
import FreezeBalanceContract from './lowerCard/freezeBalanceContract'
import styled from 'styled-components'
import { loadTransactionDetails } from '../../actions/transaction'
import ReactJson from 'react-json-view'
import { Skeleton, Spin } from 'antd'
import PageHeader from './../partials/pageHeader'
import { LoadingOutlined } from '@ant-design/icons'
import { getCurrentBlock } from '../../common/commonTransaction'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import VoteWitnessContract from './lowerCard/voteWitnessContract'
import WithdrawBalanceContract from './lowerCard/withdrawBalanceContract'
import WitnessCreateContract from './lowerCard/witnessCreateContract'
import WitnessUpdateContract from './lowerCard/witnessUpdateContract'
import UnfreezeBalanceContract from './lowerCard/unfreezeBalanceContract'
import SetAccountIdContract from './lowerCard/setAccountIdContract'
import ProposalDeleteContract from './lowerCard/proposalDeleteContract'
import ProposalCreateContract from './lowerCard/proposalCreateContract'
import ProposalApproveContract from './lowerCard/proposalApproveContract'
import AccountUpdateContract from './lowerCard/accountUpdateContract'

const Container = styled.div`
  margin: 5px;
`
const CardTransactionInfo = styled.div`
  box-shadow: 0px 3px 5px #00000014;
  border: 1px solid #e7eaf3;
  border-radius: 10px;
  overflow: hidden;
`
class TransactionDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      block_height: 0,
    }
  }
  contractDetails = (type, raw) => {
    switch (type) {
      case 'TriggerSmartContract':
        return <Trigger />
      case 'TransferContract':
        return <TransferContract />
      case 'TransferAssetContract':
        return <TransferAssetContract />
      case 'CreateSmartContract':
        return <CreateSmartContract />
      case 'AssetIssueContract':
        return <IssueAssetContract />
      case 'FreezeBalanceContract':
        return <FreezeBalanceContract />
      case 'VoteWitnessContract':
        return <VoteWitnessContract />
      case 'WithdrawBalanceContract':
        return <WithdrawBalanceContract />
      case 'WitnessCreateContract':
        return <WitnessCreateContract />
      case 'WitnessUpdateContract':
        return <WitnessUpdateContract />
      case 'UnfreezeBalanceContract':
        return <UnfreezeBalanceContract />
      case 'SetAccountIdContract':
        return <SetAccountIdContract />
      case 'ProposalDeleteContract':
        return <ProposalDeleteContract />
      case 'ProposalCreateContract':
        return <ProposalCreateContract />
      case 'ProposalApproveContract':
        return <ProposalApproveContract />
      case 'AccountUpdateContract':
        return <AccountUpdateContract />
      case '':
        return <Skeleton loading></Skeleton>
      default:
        return <ReactJson src={raw} />
    }
  }

  async componentDidMount() {
    this.props.urlAtNotPrivFunc()
    this.props.loadTransactionDetails(this.props.match.params.id)
    var block_height = await getCurrentBlock()
    this.setState({
      block_height: block_height,
    })
    this.currentBlockInterval = setInterval(
      async function () {
        var block_height = await getCurrentBlock()
        this.setState({
          block_height: block_height,
        })
      }.bind(this),
      2000
    )
  }

  componentWillUnmount() {
    this.currentBlockInterval && clearInterval(this.currentBlockInterval)
  }

  render() {
    const TransactionType = this.props.transaction.contract.type
    const jsonContract = this.props.transaction.contract.parameter.raw
    const antIcon = <LoadingOutlined spin />
    return (
      <Container>
        <PageHeader>Transaction details</PageHeader>
        <Spin indicator={antIcon} tip="Processing..." spinning={this.props.transaction.hash === ''}>
          <CardTransactionInfo className="mb-25">
            <Card block_height={this.state.block_height} />
          </CardTransactionInfo>
          <CardTransactionInfo>
            {this.contractDetails(TransactionType, jsonContract)}
          </CardTransactionInfo>
        </Spin>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transaction: state.transaction,
    systemState: state.system.systemState,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadTransactionDetails: (txHash) => {
      dispatch(loadTransactionDetails(txHash))
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  TransactionDetails
)
