import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FaExchangeAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons'
import {
  Details,
  Top,
  Content,
  StyledRow,
  ConsumeContainer,
  InfoConsumeContainer,
  DetailTop,
} from './style'
import { Col } from 'antd'

class FreezeBalanceContract extends Component {
  state = {
    copied_owner: false,
    copied_to: false,
  }
  render() {
    const { pow_decimal, amount, bandwidth_fee, consume_bandwidth, contract } =
      this.props.transaction
    const owner_address = contract.parameter.raw ? contract.parameter.raw.owner_address : '–'
    const receive_address = contract.parameter.raw ? contract.parameter.raw.receive_address : '–'
    const frozen_balance = contract.parameter.raw
      ? contract.parameter.raw.frozen_balance / Math.pow(10, 6)
      : '–'

    const type = contract.type
    const bandwidth_fee_1 = bandwidth_fee / Math.pow(10, 6)
    const bandwidth_fee_2 = bandwidth_fee / Math.pow(10, 3)
    return (
      <Details>
        <Top>
          <DetailTop>
            <FaExchangeAlt />
            &nbsp; Freeze Balance Contract
          </DetailTop>
        </Top>
        <Content>
          <StyledRow>
            <Col md={6} span={24}>
              Owner Address:
            </Col>
            <Col md={18} span={24}>
              {owner_address.length < 34 ? (
                '–'
              ) : (
                <>
                  <Link to={`/accounts/${owner_address}`}>{owner_address}</Link>
                  <CopyToClipboard
                    text={owner_address}
                    onCopy={() => {
                      this.setState({ copied_owner: true })
                      setTimeout(
                        function () {
                          this.setState({ copied_owner: false })
                        }.bind(this),
                        1000
                      )
                    }}
                  >
                    {this.state.copied_owner ? (
                      <span style={{ marginLeft: '10px' }}>
                        <CheckCircleOutlined /> Copied
                      </span>
                    ) : (
                      <CopyOutlined style={{ marginLeft: '10px' }} />
                    )}
                  </CopyToClipboard>
                </>
              )}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Receive Address:
            </Col>
            <Col md={18} span={24}>
              {receive_address.length < 34 ? (
                '–'
              ) : (
                <>
                  <Link to={`/accounts/${receive_address}`}>{receive_address}</Link>
                  <CopyToClipboard
                    text={receive_address}
                    onCopy={() => {
                      this.setState({ copied_to: true })
                      setTimeout(
                        function () {
                          this.setState({ copied_to: false })
                        }.bind(this),
                        1000
                      )
                    }}
                  >
                    {this.state.copied_to ? (
                      <span style={{ marginLeft: '10px' }}>
                        <CheckCircleOutlined /> Copied
                      </span>
                    ) : (
                      <CopyOutlined style={{ marginLeft: '10px' }} />
                    )}
                  </CopyToClipboard>
                </>
              )}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              <span>Amount</span>:
            </Col>
            <Col md={18} span={24}>
              {type === 'FreezeBalanceContract' ? (
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={frozen_balance}
                  suffix=" WIN"
                />
              ) : (
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={amount / pow_decimal}
                  suffix=" WIN"
                />
              )}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              <span>Consume Bandwidth</span>:
            </Col>
            <Col md={18} span={24}>
              <ConsumeContainer>
                <InfoConsumeContainer>
                  <span>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={consume_bandwidth + bandwidth_fee_2}
                      suffix=" Bandwidth"
                    />
                  </span>
                </InfoConsumeContainer>
                <InfoConsumeContainer>
                  <span style={{ width: '50%' }}>
                    &#8735; Consumption of staked/free bandwidth:
                  </span>
                  <span>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={consume_bandwidth}
                      suffix=" Bandwidth"
                    />
                  </span>
                </InfoConsumeContainer>
                <InfoConsumeContainer>
                  <span style={{ width: '50%' }}>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={bandwidth_fee_1}
                      suffix=" WIN for bandwidth:"
                      prefix="&#8735; Burn "
                    />
                  </span>
                  <span>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={bandwidth_fee_2}
                      suffix=" Bandwidth"
                    />
                  </span>
                </InfoConsumeContainer>
              </ConsumeContainer>
            </Col>
          </StyledRow>
        </Content>
      </Details>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transaction: state.transaction,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(FreezeBalanceContract)
