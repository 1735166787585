import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FaExchangeAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons'
import {
  Details,
  Top,
  Content,
  StyledRow,
  ConsumeContainer,
  InfoConsumeContainer,
  StyledSpace,
  DetailTop,
  ContentLine,
} from './style'
import { Col, Row } from 'antd'

class Trigger extends Component {
  state = {
    copied_owner: false,
    copied_contract: false,
    copied_to_transfer: false,
    copied_owner_transfer: false,
  }
  render() {
    const {
      energy_fee,
      energy_usage,
      consume_energy,
      bandwidth_fee,
      fee_limit,
      consume_bandwidth,
      contract,
      amount,
      pow_decimal,
      symbol,
    } = this.props.transaction
    const owner_address = contract.parameter.raw ? contract.parameter.raw.owner_address : '–'
    const contract_address = contract.parameter.raw ? contract.parameter.raw.contract_address : '–'
    const to_address = contract.parameter.raw ? contract.parameter.raw.to_address : '–'

    const bandwidth_fee_1 = bandwidth_fee / Math.pow(10, 6)
    const bandwidth_fee_2 = bandwidth_fee / Math.pow(10, 3)
    return (
      <Details className="trigger-smart-contracts">
        <Top>
          <DetailTop>
            <FaExchangeAlt /> Trigger Smart Contracts
          </DetailTop>
        </Top>
        <Content>
          <StyledRow>
            <Col md={6} span={24}>
              Owner Address:
            </Col>
            <Col md={18} span={24}>
              {owner_address.length < 34 ? (
                '–'
              ) : (
                <>
                  <Link to={`/accounts/${owner_address}`}>{owner_address}</Link>
                  <CopyToClipboard
                    text={owner_address}
                    onCopy={() => {
                      this.setState({ copied_owner: true })
                      setTimeout(
                        function () {
                          this.setState({ copied_owner: false })
                        }.bind(this),
                        1000
                      )
                    }}
                  >
                    {this.state.copied_owner ? (
                      <span style={{ marginLeft: '10px' }}>
                        <CheckCircleOutlined /> Copied
                      </span>
                    ) : (
                      <CopyOutlined style={{ marginLeft: '10px' }} />
                    )}
                  </CopyToClipboard>
                </>
              )}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Contract address:
            </Col>
            <Col md={18} span={24}>
              {contract_address.length < 34 ? (
                '–'
              ) : (
                <>
                  <Link to={`/contract/${contract_address}`}>{contract_address}</Link>
                  <span>
                    {' (' +
                      symbol
                        .trim()
                        .replace(/[^\x0-\x7]/g, '')
                        .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                      ' Token)'}
                  </span>
                  <CopyToClipboard
                    text={contract_address}
                    onCopy={() => {
                      this.setState({ copied_contract: true })
                      setTimeout(
                        function () {
                          this.setState({ copied_contract: false })
                        }.bind(this),
                        1000
                      )
                    }}
                  >
                    {this.state.copied_contract ? (
                      <span style={{ marginLeft: '10px' }}>
                        <CheckCircleOutlined /> Copied
                      </span>
                    ) : (
                      <CopyOutlined style={{ marginLeft: '10px' }} />
                    )}
                  </CopyToClipboard>
                </>
              )}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              TRC20 Transfers:
            </Col>
            <Col md={18} span={24}>
              <Row>
                <Col md={6} span={24}>
                  From
                </Col>
                <Col md={18} span={24}>
                  {owner_address.length < 34 ? (
                    '–'
                  ) : (
                    <>
                      <Link to={`/contract/${owner_address}`}>{owner_address}</Link>
                      <CopyToClipboard
                        text={owner_address}
                        onCopy={() => {
                          this.setState({ copied_owner_transfer: true })
                          setTimeout(
                            function () {
                              this.setState({ copied_owner_transfer: false })
                            }.bind(this),
                            1000
                          )
                        }}
                      >
                        {this.state.copied_owner_transfer ? (
                          <span style={{ marginLeft: '10px' }}>
                            <CheckCircleOutlined /> Copied
                          </span>
                        ) : (
                          <CopyOutlined style={{ marginLeft: '10px' }} />
                        )}
                      </CopyToClipboard>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6} span={24}>
                  To
                </Col>
                <Col md={18} span={24}>
                  {to_address.length < 34 ? (
                    '–'
                  ) : (
                    <>
                      <Link to={`/contract/${to_address}`}>{to_address}</Link>
                      <CopyToClipboard
                        text={to_address}
                        onCopy={() => {
                          this.setState({ copied_to: true })
                          setTimeout(
                            function () {
                              this.setState({ copied_to: false })
                            }.bind(this),
                            1000
                          )
                        }}
                      >
                        {this.state.copied_to ? (
                          <span style={{ marginLeft: '10px' }}>
                            <CheckCircleOutlined /> Copied
                          </span>
                        ) : (
                          <CopyOutlined style={{ marginLeft: '10px' }} />
                        )}
                      </CopyToClipboard>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6} span={24}>
                  Amount
                </Col>
                <Col md={18} span={24}>
                  <NumberFormat
                    displayType={'text'}
                    thousandSeparator={true}
                    value={amount / pow_decimal}
                    suffix=""
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} span={24}>
                  Token
                </Col>
                <Col md={18} span={24}>
                  <span>
                    {symbol
                      .trim()
                      .replace(/[^\x0-\x7]/g, '')
                      .replace(/[\u{0080}-\u{FFFF}]/gu, '')}
                  </span>
                </Col>
              </Row>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Consume Bandwidth:
            </Col>
            <Col md={18} span={24}>
              <ConsumeContainer>
                <InfoConsumeContainer>
                  <NumberFormat
                    displayType={'text'}
                    thousandSeparator={true}
                    value={consume_bandwidth + bandwidth_fee_2}
                    suffix=" Bandwidth"
                  />
                </InfoConsumeContainer>
                <Row>
                  <Col md={14} span={24}>
                    &#8735; Consumption of staked/free bandwidth:
                  </Col>
                  <Col md={10} span={24}>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={consume_bandwidth}
                      suffix=" Bandwidth"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={14} span={24}>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={bandwidth_fee_1}
                      prefix="&#8735; Burn "
                      suffix=" WIN for bandwidth:"
                    />
                  </Col>
                  <Col md={10} span={24}>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={bandwidth_fee_2}
                      suffix=" Bandwidth"
                    />
                  </Col>
                </Row>
              </ConsumeContainer>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Consume Energy:
            </Col>
            <Col md={18} span={24}>
              <ConsumeContainer>
                <InfoConsumeContainer>
                  <span>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={consume_energy}
                      suffix=" Energy"
                    />
                  </span>
                </InfoConsumeContainer>
                <Row>
                  <Col md={14} span={24}>
                    <span>&#8735; Energy usage from user's staked energy:</span>
                  </Col>
                  <Col md={10} span={24}>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={energy_usage / Math.pow(10, 6)}
                      suffix=" Energy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={14} span={24}>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={energy_fee / Math.pow(10, 6)}
                      suffix=" WIN for energy:"
                      prefix="&#8735; Burn"
                    />
                  </Col>
                  <Col md={10} span={24}>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={consume_energy - energy_usage / Math.pow(10, 6)}
                      suffix=" Energy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={14} span={24}>
                    <span>&#8735; Consume contract owner's Energy:</span>
                  </Col>
                  <Col md={10} span={24}>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={0}
                      suffix=" Energy"
                    />
                  </Col>
                </Row>
              </ConsumeContainer>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Fee limit:
            </Col>
            <Col md={18} span={24}>
              <ContentLine>
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={String(fee_limit) ? 0 : fee_limit / Math.pow(10, 6)}
                  suffix=" WIN"
                />
              </ContentLine>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              <span>Method Calling</span>:
            </Col>
            <Col md={18} span={24}>
              <ContentLine>
                <span>transfer(address recipient,uint256 amount)</span>
                <br />
                <span>amount: 1000000</span>
                <br />
                <span>recipient: {to_address}</span>
              </ContentLine>
            </Col>
          </StyledRow>
        </Content>
      </Details>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transaction: state.transaction,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Trigger)
