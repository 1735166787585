import {
  GET_VOTE_REQUEST,
  GET_VOTE_SUCCESS,
  GET_VOTE_FAIL,
  UPDATE_VOTE_REQUEST,
  UPDATE_VOTE_SUCCESS,
  UPDATE_VOTE_FAIL,
} from '../actions/votes'

const initVote = {
  loading: false,
  votes: null,
  message: null,
  totalVotes: 0,
  updateLoading: false,
  txid: '',
}

export function votesReducer(state = initVote, action) {
  switch (action.type) {
    case GET_VOTE_REQUEST:
      return { ...state, loading: true, votes: null }
    case GET_VOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        votes: action.payload.data,
        totalVotes: action.payload.total_votes,
      }
    case GET_VOTE_FAIL:
      return { ...state, loading: false, votes: false }
    case UPDATE_VOTE_REQUEST:
      return { ...state, updateLoading: true, message: null, txid: '' }
    case UPDATE_VOTE_SUCCESS:
      return { ...state, updateLoading: false, message: null, txid: action.payload.txid }
    case UPDATE_VOTE_FAIL:
      return { ...state, updateLoading: false, message: action.payload }
    default:
      return state
  }
}
