import React from 'react'
import { Row, Col, Menu } from 'antd'
import { Link, Route, Switch, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import PageHeader from '../partials/pageHeader'
import Assets from './assets'
import Resources from './resources'
import Witness from './witness'

const Wrapper = styled.div`
  margin-bottom: 30px;
`
const StyledMenu = styled(Menu)`
  border: 1px solid #dedede;
  border-radius: 10px;
  .ant-menu-item {
    height: 30px;
    line-height: 30px;
    &:active {
      background-color: #ffffff;
    }
    &-selected {
      background-color: #ffffff !important;
      a {
        color: #fcb040;
        font-weight: 600;
      }
    }
  }
`

class Wallet extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const pathname = window.location.pathname.substring(8)
    if (!localStorage.getItem('token')) {
      return <Redirect to="/login" />
    }

    return (
      <Wrapper>
        <PageHeader></PageHeader>
        <Row gutter={[24, 24]}>
          <Col md={4} span={24}>
            <StyledMenu defaultSelectedKeys={pathname ? pathname : 'assets'}>
              <Menu.Item key="assets">
                <Link to="/wallet">Assets</Link>
              </Menu.Item>
              <Menu.Item key="resources">
                <Link to="/wallet/resources">Resources</Link>
              </Menu.Item>
              <Menu.Item key="witness">
                <Link to="/wallet/witness">Witness</Link>
              </Menu.Item>
            </StyledMenu>
          </Col>
          <Col md={20} span={24}>
            <Switch>
              <Route exact path="/wallet" render={() => <Assets />} />
              <Route path="/wallet/resources" render={() => <Resources />} />
              <Route path="/wallet/witness" render={() => <Witness />} />
            </Switch>
          </Col>
        </Row>
      </Wrapper>
    )
  }
}

export default Wallet
