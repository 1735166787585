import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { TableRow, Td } from './style'

class AddressName extends Component {
  render() {
    let { name } = this.props

    return (
      <TableRow>
        <Td>
          <span>Name</span>:
        </Td>
        <td>
          <div>
            <span>{name}</span>
          </div>
        </td>
      </TableRow>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.address.name === '' || state.address.name === undefined ? '–' : state.address.name,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AddressName)
