import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import FormTokenRecordRC10 from './formRecordRC10'
import FormTokenRecordRC20RC721 from './formRecordRC20RC721'
class FormTokenRecord extends React.Component {
  componentDidMount() {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', (event) => {
      if (window.location.pathname.includes('token-record')) {
        window.location.href = '/token-record/create/Type'
      } else {
        window.location.href = window.location.pathname
          .replaceAll('/Record', '')
          .replaceAll('/update', '')
      }
    })
  }

  render() {
    const { tokenRecord } = this.props
    const current = tokenRecord.current_url
    const type_choose =
      tokenRecord.data.token_record_type != ''
        ? tokenRecord.data.token_record_type == 'WRC20'
          ? (tokenRecord.type_choose = 1)
          : tokenRecord.data.token_record_type == 'WRC721'
          ? (tokenRecord.type_choose = 2)
          : (tokenRecord.type_choose = 3)
        : tokenRecord.type_choose

    if (current == 1) {
      return <Redirect to={window.location.pathname.replace('/Record', '') + '/Type'} />
    } else if (current == 3) {
      return <Redirect to={window.location.pathname.replace('/Record', '') + '/Confirm'} />
    } else if (current == 4) {
      return <Redirect to={window.location.pathname.replace('/Record', '') + '/Done'} />
    }
    return <>{type_choose == 3 ? <FormTokenRecordRC10 /> : <FormTokenRecordRC20RC721 />}</>
  }
}

const mapStateToProps = (state) => {
  return {
    contract: state.contract,
    tokenRecord: state.tokenRecord,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  FormTokenRecord
)
