import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { TableRow, Td } from './style'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
class AddressTransfer extends Component {
  render() {
    let { transaction } = this.props

    return (
      <TableRow>
        <Td>
          <span>Transfer</span>:
        </Td>
        <td>
          <div>
            <span>
              <NumberFormat
                displayType={'text'}
                thousandSeparator={true}
                value={
                  (transaction.from === undefined ? 0 : transaction.from) +
                  (transaction.to === undefined ? 0 : transaction.to)
                }
              />
              {' Txns'}
              <span style={{ marginLeft: '10px' }}>
                (<ArrowUpOutlined style={{ padding: '5px', color: 'darkred' }} />
                {transaction.from === undefined ? 0 : transaction.from}
                {' Txns'}
                <ArrowDownOutlined style={{ marginLeft: '10px', padding: '5px', color: 'green' }} />
                {transaction.to === undefined ? 0 : transaction.to}
                {' Txns'})
              </span>
            </span>
          </div>
        </td>
      </TableRow>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transaction: state.address.transaction === undefined ? {} : state.address.transaction,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AddressTransfer)
