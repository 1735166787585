// import { LineProgressBar } from '@frogress/line'
import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import {
  Table,
  BorderRed,
  Div,
  DivContain,
  FontFamily,
  TableRow,
  Th,
  Td,
  Span,
  Remaining,
  Used,
} from './style'
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import './style.css'

class AddressBandwidth extends Component {
  render() {
    let { band_width_used, band_width_total } = this.props

    return (
      <DivContain>
        <Span>
          Bandwidth
          <Remaining>
            Remaining:
            <Used>
              <NumberFormat
                displayType={'text'}
                thousandSeparator={true}
                value={band_width_total - band_width_used}
              />
            </Used>
            /
            <NumberFormat displayType={'text'} thousandSeparator={true} value={band_width_total} />
          </Remaining>
        </Span>
        <Progress
          status="success"
          theme={{
            success: {
              symbol: '‍',
              color: '#FCB040',
            },
          }}
          percent={
            band_width_total ? ((band_width_total - band_width_used) / band_width_total) * 100 : 0
          }
        />

        {/* <LineProgressBar
          percent={
            band_width_total ? ((band_width_total - band_width_used) / band_width_total) * 100 : 0
          }
          progressColor="linear-gradient(to right, #78abe9, #74dad8, #ec7cac)"
        /> */}
      </DivContain>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    band_width_total:
      state.address.band_width_total === undefined ? 0 : state.address.band_width_total,
    band_width_used:
      state.address.band_width_used === undefined ? 0 : state.address.band_width_used,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AddressBandwidth)
