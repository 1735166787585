import { Button, Col, Form, Input, Modal, Row } from 'antd'
import React from 'react'
import { PRIKEYEXISTED, PRIKEYNAMEEXISTED, NOEXISTED } from './constants'

const useForm = Form.useForm

export default function AddKeyFormModal({ handleCancel, handleOk, visible, loading, email }) {
  const [form] = useForm()

  const checkExist = (value) => {
    const privateKeyList = JSON.parse(localStorage.getItem(email)) || []
    let isExisted = NOEXISTED
    privateKeyList.forEach((elem) => {
      if (elem.name === value) {
        isExisted = PRIKEYNAMEEXISTED
      }
      if (elem.prikey === value) {
        isExisted = PRIKEYEXISTED
      }
    })

    return isExisted
  }

  const _handleCancel = () => {
    handleCancel()
    form.resetFields()
  }

  const _handleOk = (values) => {
    handleOk(values)
    form.resetFields()
  }

  return (
    <Modal visible={visible} onCancel={_handleCancel} footer={null}>
      <Form layout="vertical" form={form} onFinish={_handleOk} size="medium">
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              validator: (_, value) => {
                if (value !== undefined && value !== null) {
                  const isExisted = checkExist(value)

                  if (isExisted === PRIKEYNAMEEXISTED) {
                    return Promise.reject(new Error('This Name already exists!'))
                  }
                  if (value.length > 150) {
                    return Promise.reject(new Error('Name has to be 150 characters long!'))
                  }
                  return Promise.resolve()
                } else {
                  return Promise.reject(new Error('This Name cannot be empty!'))
                }
              },
            },
          ]}
        >
          <Input placeholder="Private key name" />
        </Form.Item>
        <Form.Item
          label="Private Key"
          name="prikey"
          rules={[
            {
              validator: (_, value) => {
                if (value !== undefined && value !== null) {
                  const isExisted = checkExist(value)

                  if (isExisted === PRIKEYEXISTED) {
                    return Promise.reject(new Error('This Private key already exists!'))
                  }

                  if (value.length !== 64) {
                    return Promise.reject(new Error('Private key has to be 64 characters long!'))
                  }

                  if (!value.match(/^[a-zA-Z0-9]+$/)) {
                    return Promise.reject(
                      new Error('Private key must not contain special characters!')
                    )
                  }
                  return Promise.resolve()
                } else {
                  return Promise.reject(new Error('Private key cannot be empty!'))
                }
              },
            },
          ]}
        >
          <Input placeholder="Private key" />
        </Form.Item>
        <Row>
          <Col>
            <Button htmlType="submit" type="primary" loading={loading}>
              Add
            </Button>
          </Col>
          <Col span={1}></Col>
          <Col>
            <Button key="back" onClick={_handleCancel}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
