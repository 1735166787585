import { Menu, Tabs } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
const { TabPane } = Tabs

class ContractMenu extends Component {
  state = {
    current: 'transaction',
  }

  componentDidMount() {}

  handleClick = (e) => {
    this.setState({ current: e.key })
  }

  render() {
    const { current } = this.state
    return (
      <Menu
        onClick={this.handleClick}
        selectedKeys={[current]}
        mode="horizontal"
        className="account-detail-menu"
      >
        <Menu.Item key="transaction">
          <Link to={'/contract/' + this.props.address}>Transactions</Link>
        </Menu.Item>
        <Menu.Item key="tokenHolder">
          <Link to={'/contract/' + this.props.address + '/holders'}>Token Holders</Link>
        </Menu.Item>
        <Menu.Item key="contract">
          <Link to={'/contract/' + this.props.address + '/contract'}>Contract</Link>
        </Menu.Item>
      </Menu>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(ContractMenu)
