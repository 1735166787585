import React from 'react'
import { Table, Pagination, Badge, Skeleton, Radio, Spin, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  loadAddressTransfer,
  updatePageAddressTransfer,
  updatePageAddressTransferLimit,
} from '../../actions/addressTransfer'
import styled from 'styled-components'
import { toTimeAgo } from '../../utils/utils'
import { DatePicker } from 'antd'
import '../../styles/account.less'
import TruncateMiddle from 'react-truncate-string'
import NumberFormat from 'react-number-format'
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo'

const { RangePicker } = DatePicker

const TranCount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const ContainChooseDate = styled.div`
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  position: absolute;
  padding: 9px;
`
const StyledLink = styled(Link)``

const Pagin = styled.div`
  margin-top: 15px;
  text-align: right !important;
`
const BadgeGreen = styled(Badge)`
  .ant-badge-count {
    background-color: #e1f3e0;
    color: black;
  }
`

const BadgeRed = styled(Badge)`
  .ant-badge-count {
    background-color: #ff7677;
    color: black;
  }
`

const ChooseDate = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  position: absolute;
  width: -webkit-fill-available;
`
const Token = styled.p`
  color: #c23631;
  font-size: 14px;
`
class TransferTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: [],
      type: 'wrc10',
    }
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    var { pageAddressTransfers } = this.props

    this.props.loadData(
      this.state.type,
      this.props.address,
      pageAddressTransfers.current_page,
      pageAddressTransfers.page_limit,
      this.state.date
    )
  }
  onChange = (pageNumber, pageLimit) => {
    var { pageAddressTransfers } = this.props
    if (pageNumber !== pageAddressTransfers.current_page) {
      this.props.updatePageAddressTransfer(pageNumber)
    }
    if (pageLimit !== pageAddressTransfers.page_limit) {
      this.props.updatePageAddressTransferLimit(pageLimit)
    }
    this.props.loadData(
      this.state.type,
      this.props.address,
      pageAddressTransfers.current_page,
      pageLimit,
      this.state.date
    )
  }
  handleSwitchTransfer = (e) => {
    this.setState({ type: e.target.value })
    this.props.loadData(
      e.target.value,
      this.props.address,
      1,
      this.props.pageAddressTransfers.page_limit,
      []
    )
  }

  render() {
    var { transfers, status, total, pageAddressTransfers } = this.props
    const columns = [
      {
        title: 'Hash',
        key: 'hash',
        dataIndex: 'hash',
        render: (text) => (
          <StyledLink key={text} to={'/transaction/' + text}>
            <Tooltip placement="top" title={text}>
              <TruncateMiddle text={text} />
            </Tooltip>
          </StyledLink>
        ),
        width: '20%',
      },
      {
        title: 'Block',
        dataIndex: 'block_number',
        key: 'block_number',
        render: (text) => (
          <StyledLink key={text} to={'/block/' + text}>
            {text}
          </StyledLink>
        ),
        width: '9%',
      },
      {
        title: 'Age',
        key: 'timestamp',
        dataIndex: 'timestamp',
        render: (text) =>
          text ? <ReactTimeAgo date={new Date(text)} locale="en-US" /> : 'unknown',
        width: '10%',
      },
      {
        title: 'From',
        key: 'from_address',
        render: (record) =>
          record.from_address != null ? (
            <StyledLink
              key={record.from_address}
              to={'/accounts/' + record.from_address}
              onClick={(e) =>
                record.from_address === this.props.address ? e.preventDefault() : ''
              }
            >
              <Tooltip placement="top" title={record.from_address}>
                <TruncateMiddle text={record.from_address} />
              </Tooltip>
            </StyledLink>
          ) : (
            <StyledLink
              key={record.contract_address}
              to={'/contract/' + record.contract_address}
              onClick={(e) =>
                record.contract_address === this.props.address ? e.preventDefault() : ''
              }
            >
              <Tooltip placement="top" title={record.contract_address}>
                <TruncateMiddle text={record.contract_address} />
              </Tooltip>
            </StyledLink>
          ),
        width: '15%',
      },
      {
        title: 'To',
        key: 'to_address',
        render: (record) =>
          record.to_address != null ? (
            <StyledLink
              key={record.to_address}
              to={'/accounts/' + record.to_address}
              onClick={(e) => (record.to_address === this.props.address ? e.preventDefault() : '')}
            >
              <Tooltip placement="top" title={record.to_address}>
                <TruncateMiddle text={record.to_address} />
              </Tooltip>
            </StyledLink>
          ) : (
            <StyledLink
              key={record.contract_address}
              to={'/contract/' + record.contract_address}
              onClick={(e) =>
                record.contract_address === this.props.address ? e.preventDefault() : ''
              }
            >
              <Tooltip placement="top" title={record.contract_address}>
                <TruncateMiddle text={record.contract_address} />
              </Tooltip>
            </StyledLink>
          ),
        width: '15%',
      },
      {
        title: 'Amount',
        key: 'amount',
        render: (record) => (
          <NumberFormat
            displayType={'text'}
            thousandSeparator={true}
            value={
              record.pow_decimal === undefined
                ? record.amount
                : record.amount / record.pow_decimal < 1000000000000000
                ? record.amount / record.pow_decimal
                : 1000000000000000
            }
          />
        ),
        width: '20%',
      },
      {
        title: 'Result',
        key: 'ret',
        align: 'center',
        dataIndex: 'ret',
        render: (text) => {
          return text === 'SUCESS' ? <BadgeGreen count="SUCCESS" /> : <BadgeRed count="UNSUCCESS" />
        },
        width: '10%',
      },
      {
        title: 'Token',
        key: 'token',
        fixed: 'left',
        render: (record) => (
          <Link key={record.contract_address} from="*" to={'/token20/' + record.contract_address}>
            <Token>
              {record.name === ''
                ? '–'
                : record.name
                    .trim()
                    .replace(/[^\x0-\x7]/g, '')
                    .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                  (record.symbol === ''
                    ? ''
                    : ' (' +
                      record.symbol
                        .trim()
                        .replace(/[^\x0-\x7]/g, '')
                        .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                      ')')}
            </Token>
          </Link>
        ),
        width: '20%',
      },
    ]

    return (
      <div>
        <div className="ant-col-xl-24 blk-p-10 ant-col-lg-24 ant-col-md-24 ant-col-sm-24 ant-col-xs-24 account-transfer">
          <div className="ant-col-xl-12 ant-col-lg-12 ant-col-md-12 ant-col-sm-24 ant-col-xs-24 account-transfer-choose">
            <Radio.Group
              value="large"
              onChange={this.handleSwitchTransfer}
              disabled={status != 'success'}
            >
              <Radio.Button
                value="wrc10"
                style={
                  this.state.type === 'wrc10'
                    ? {
                        color: 'white',
                        background: '#c23631',
                      }
                    : {}
                }
              >
                WIN&WRC10 Transfers
              </Radio.Button>
              <Radio.Button
                value="wrc20"
                style={
                  this.state.type === 'wrc20'
                    ? {
                        color: 'white',
                        background: '#c23631',
                      }
                    : {}
                }
              >
                WRC20 Transfers
              </Radio.Button>
            </Radio.Group>
          </div>
          <div className="ant-col-xl-12 ant-col-lg-12 ant-col-md-12 ant-col-sm-24 ant-col-xs-24 account-transfer-date">
            <RangePicker
              value={this.state.date}
              onChange={(val) => {
                this.setState({
                  date: val,
                })
                this.props.loadData(
                  this.state.type,
                  this.props.address,
                  1,
                  pageAddressTransfers.page_limit,
                  val
                )
              }}
            />
          </div>
        </div>
        <div
          style={{ paddingBottom: '55px', textAlign: 'center', width: '-webkit-fill-available' }}
          className="blk-f-left blk-row ant-col-xl-24 ant-col-lg-24 ant-col-md-24 ant-col-sm-24 ant-col-xs-24"
        >
          {status != 'success' ? (
            <div style={{ paddingTop: '20px' }}>
              <Spin size="large" />
              <p>Loading...</p>
            </div>
          ) : (
            <div>
              <TranCount className="blk-row ant-col-xl-24 ant-col-lg-24 ant-col-md-24 ant-col-sm-24 ant-col-xs-24 account-transfer-table-total">
                <p>
                  A Total of {total + ' '}
                  Transfer
                </p>
              </TranCount>
              <Table
                columns={columns}
                dataSource={transfers}
                rowKey="address"
                scroll={{ x: 1300 }}
                sticky
                pagination={false}
                loading={transfers === undefined ? true : false}
                className="account-list"
              />
              <Pagin>
                <Pagination
                  current={pageAddressTransfers.start_page}
                  total={pageAddressTransfers.total_items}
                  onChange={this.onChange}
                  pageSize={pageAddressTransfers.page_limit}
                  showSizeChanger
                  showQuickJumper
                  className="account-pagi"
                  pageSizeOptions={[10, 15, 20, 25]}
                />
              </Pagin>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.addressTransfers.status,
    transfers: state.addressTransfers.result,
    total: state.addressTransfers.total,
    pageAddressTransfers: state.pageAddressTransfers,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updatePageAddressTransfer: (page) => {
      dispatch(updatePageAddressTransfer(page))
    },
    updatePageAddressTransferLimit: (limit) => {
      dispatch(updatePageAddressTransferLimit(limit))
    },
    loadData: (type, address, offset, limit, date) => {
      dispatch(loadAddressTransfer(type, address, offset, limit, date))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  TransferTable
)
