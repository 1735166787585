import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import TransTable from './contractTransactionTable'
import TokenBalance from './tokenBalance'
import ContractCodeMenu from './contractCodeMenu'
import TokenHolderTable from '../tokenHolder'

class ContractSwitchMenu extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Switch>
        <Route path="/contract/:id/contract" render={() => <ContractCodeMenu />} />
        <Route
          exact
          path="/contract/:id"
          render={() => (
            <TransTable address={this.props.address} loadContract={this.props.loadContract} />
          )}
        />
        <Route
          path="/contract/:id/holders"
          render={() => (
            <TokenHolderTable address={this.props.address} totalSupply={this.props.totalSupply} />
          )}
        />
      </Switch>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(ContractSwitchMenu)
