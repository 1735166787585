import React from 'react'
import { Input, message } from 'antd'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { WalletOutlined } from '@ant-design/icons'
import ReCAPTCHA from 'react-google-recaptcha'
import Axios from 'axios'
const { Search } = Input

const PALCEHOLDER = 'Test Wallet Address'
const INPUTTITLE = 'Submit'

const captchaKey = process.env.REACT_APP_RE_CAPTCHA_KEY

const InputWrapper = styled(Search)`
  input {
    height: 50px;
  }
  .ant-input-search-button {
    border-radius: 10px 10px 0 0;
    height: 50px;
    background: #504e4e;
    border-color: #504e4e;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
  }
  .ant-input-affix-wrappers
    min-width: 400px;
    max-width: 401px;
  }
  .ant-input-affix-wrapper{
    padding: 0px;

  }
  .ant-input-wrapper {
    min-width: 399px;
    max-width: 400px;
    border: none,
    background: #ecf0f5; 
  }
  margin-bottom: 30px;
`
const recaptchaRef = React.createRef()
class GetConFree extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    }
  }
  onChange = (value) => {
    console.log('Captcha value:', value)
  }
  onSearch = (key) => {
    const valueRecaptcha = recaptchaRef.current.getValue()
    if (!key || !valueRecaptcha) {
      message.error('Invalid input')
    } else {
      Axios.post('https://api-chain.comartek.com/send-free-token', {
        toAddress: key,
        recaptchaResponse: valueRecaptcha,
      })
        .then((response) => {
          message.success('Sended ! Check your wallet !')
        })
        .catch((error) => {
          message.error('Failed !' + error.response.data.message)
        })
    }
  }

  render() {
    return (
      <>
        <div>
          <form
            style={{
              minHeight: 400,
              maxHeight: 401,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
            onSubmit={this.handleClick}
          >
            <h2 style={{ fontWeight: 'bold' }}>Looking For Test ( WIN ) Coins ? </h2>
            <span>Fill out your test wallet address to receive 2.000 WIN</span>
            <br></br>
            <InputWrapper
              prefix={<WalletOutlined style={{ fontSize: '170%', marginLeft: 7 }} />}
              placeholder={PALCEHOLDER}
              enterButton={INPUTTITLE}
              onSearch={this.onSearch}
              style={{ border: 'none' }}
            />
            <ReCAPTCHA sitekey={captchaKey} onChange={this.onChange} ref={recaptchaRef} />
          </form>
        </div>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    state: state.search.state,
    type: state.search.type,
    searchKey: state.search.key,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GetConFree)
