import React from 'react'
import { Table, Button, Modal, notification, Tooltip, Result, Spin } from 'antd'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'
import { loadResources } from '../../actions/resources'
import { loadUnfreezeBalance, init } from '../../actions/unfreezeBalance'
import { getDefaulteyFromToken } from '../../common/commonTransaction'

const formatETLastStake = (value) => {
  return moment(value).subtract(3, 'days').format('YYYY-MM-DD HH:mm:ss')
}

const formatETUnstake = (value) => {
  return moment(value).format('YYYY-MM-DD HH:mm:ss')
}

const FilterItem = styled.p`
  background-color: ${({ isSelected }) => isSelected && `#dedede`};
  padding: 8px 12px;
  margin-bottom: 0;
  &:hover {
    cursor: pointer;
    background-color: #dedede;
  }
`

class Resources extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultPriv: getDefaulteyFromToken(),
    }
  }

  componentDidMount() {
    const { address, priv } = this.state.defaultPriv
    this.props.loadResources({ address })
    this.props.initUnfreezeBalance()
    if (!priv) {
      notification.warning({
        message: 'Warning!',
        description:
          'You have no private key, please add somes in private key management to perform transaction!',
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { unfreezeBalance, loading, message } = this.props.unfreezeBalance
    if (message && message !== prevProps.unfreezeBalance.message && !loading) {
      notification.error({
        message,
        description: 'Failed',
      })
    }
    if (
      unfreezeBalance &&
      unfreezeBalance !== prevProps.unfreezeBalance.unfreezeBalance &&
      !loading
    ) {
      notification.success({
        message: 'TxID: ' + unfreezeBalance.txid,
        description: `Succeeded`,
      })
    }
  }

  columns = [
    {
      title: 'Last Stake Time(Local)',
      render: (record) => (
        <>
          {formatETLastStake(
            record.expire_time_for_bandwidth
              ? record.expire_time_for_bandwidth
              : record.expire_time_for_energy
          )}
        </>
      ),
    },
    {
      title: 'Staking Account',
      render: () => {
        const address = this.state.defaultPriv.address
        return (
          <Link to={`/accounts/${address}`}>
            {address.substring(0, 5) + '...' + address.substring(29, address.length)}
          </Link>
        )
      },
    },
    {
      title: 'Receiving Account',
      dataIndex: 'to_address',
      render: (to_address) => (
        <Link to={`/accounts/${to_address}`}>
          {to_address.substring(0, 5) + '...' + to_address.substring(29, to_address.length)}
        </Link>
      ),
    },
    {
      title: 'Resources Type',
      dataIndex: 'frozen_balance_for_bandwidth',
      render: (frozen_balance_for_bandwidth) => (
        <>{frozen_balance_for_bandwidth ? 'Bandwidth' : 'Energy'}</>
      ),
      filterMultiple: false,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        const filters = ['All', 'Bandwidth', 'Energy']
        return (
          <>
            {filters.map((item, index) => (
              <FilterItem
                key={index}
                onClick={() => {
                  setSelectedKeys([item])
                  confirm()
                }}
                isSelected={selectedKeys[0] === item}
              >
                {item}
              </FilterItem>
            ))}
          </>
        )
      },
      onFilter: (value, record) => {
        if (value === 'Bandwidth') {
          return record.frozen_balance_for_bandwidth
        } else if (value === 'Energy') {
          return record.frozen_balance_for_energy
        }
        return record
      },
    },
    // {
    //   title: 'Resources Amount',
    //   dataIndex: '',
    // },
    {
      title: 'Amount Staked',
      render: (record) => (
        <NumberFormat
          displayType="text"
          suffix=" WIN"
          thousandSeparator
          value={
            (record.frozen_balance_for_bandwidth
              ? record.frozen_balance_for_bandwidth
              : record.frozen_balance_for_energy) / Math.pow(10, 6)
          }
        />
      ),
    },
    {
      title: 'Operation',
      render: (record) => {
        const etBandwidth = record.expire_time_for_bandwidth
        const etEnergy = record.expire_time_for_energy
        const now = moment().valueOf()
        return (
          <Tooltip
            title={
              etBandwidth
                ? etBandwidth > now
                  ? `Earliest Unstake Time(Local): ${formatETUnstake(etBandwidth)}`
                  : ''
                : etEnergy > now
                ? `Earliest Unstake Time(Local): ${formatETUnstake(etEnergy)}`
                : ''
            }
          >
            <Button
              type="primary"
              disabled={etBandwidth ? etBandwidth > now : etEnergy > now}
              onClick={() => this.showConfirm(record)}
            >
              Unstake
            </Button>
          </Tooltip>
        )
      },
    },
  ]

  showConfirm = (values) => {
    Modal.confirm({
      title: 'Are you sure you want to unfreeze?',
      onOk: () => {
        this.props.loadUnfreezeBalance({
          owner_address: this.state.defaultPriv.address,
          private_key: this.state.defaultPriv.priv,
          receiver_address: values.to_address,
          resource: values.frozen_balance_for_bandwidth ? 'BANDWIDTH' : 'ENERGY',
        })
      },
    })
  }

  render() {
    const { resources, loading } = this.props.resources
    const { unfreezeBalance, message } = this.props.unfreezeBalance

    return (
      <Spin spinning={this.props.unfreezeBalance.loading}>
        {unfreezeBalance && (
          <Result
            status="success"
            title="Your transaction has been issued successfully!"
            subTitle={`You can check it at transaction ${unfreezeBalance.txid}`}
            extra={[
              <Button type="primary">
                <Link to={`/transaction/${unfreezeBalance.txid}`}>Go to details</Link>
              </Button>,
              <Button
                onClick={() => {
                  this.props.initUnfreezeBalance()
                  this.props.loadResources({ address: this.state.defaultPriv.address })
                }}
              >
                Unfreeze more
              </Button>,
            ]}
          />
        )}
        {message && (
          <Result
            status="error"
            title={message}
            extra={[<Button onClick={() => this.props.initUnfreezeBalance()}>New transfer</Button>]}
          />
        )}
        {!unfreezeBalance && !message && (
          <Table
            columns={this.columns}
            dataSource={resources}
            loading={loading}
            pagination={false}
            scroll={{ x: 'auto' }}
          />
        )}
      </Spin>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    resources: state.resources,
    unfreezeBalance: state.unfreezeBalance,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadResources: (params) => {
      dispatch(loadResources(params))
    },
    loadUnfreezeBalance: (params) => {
      dispatch(loadUnfreezeBalance(params))
    },
    initUnfreezeBalance: () => {
      dispatch(init())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withRouter(Resources)
)
