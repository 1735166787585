import { notification } from 'antd'
import fetch from 'cross-fetch'
import Asset from '../api/asset'
import ConfigKey from '../api/key'

import { API_ADDR } from '../config/config'
import { transferAsset } from './transferasset'

export const TOKEN_RECORD_INIT = 'TOKEN_RECORD_INIT'
export const TOKEN_RECORD_UPDATE = 'TOKEN_RECORD_UPDATE'
export const TOKEN_RECORD_FAIL = 'TOKEN_RECORD_FAIL'
export const TOKEN_RECORD_SUCCESS = 'TOKEN_RECORD_SUCCESS'
export const TOKEN_RECORD_REQUESTING = 'TOKEN_RECORD_REQUESTING'
export const TOKEN_RECORD_UPDATE_FAIL = 'TOKEN_RECORD_UPDATE_FAIL'

export function request() {
  return {
    type: TOKEN_RECORD_REQUESTING,
  }
}
export function fail(message, data, type_token) {
  return {
    type: TOKEN_RECORD_FAIL,
    payload: {
      data: data,
      message: message,
      type_token: type_token,
    },
  }
}
export function failUpdate(message, data, type_token) {
  return {
    type: TOKEN_RECORD_UPDATE_FAIL,
    payload: {
      data: data,
      message: message,
      type_token: type_token,
    },
  }
}
export function success(data, current_url, type_choose) {
  return {
    type: TOKEN_RECORD_SUCCESS,
    payload: {
      data: data,
      current_url: current_url,
      type_choose: type_choose,
    },
  }
}

export function initTokenRecord() {
  return {
    type: TOKEN_RECORD_INIT,
  }
}
export function updateTokenRecord(data, current_url, type_choose) {
  return {
    type: TOKEN_RECORD_UPDATE,
    payload: {
      data: data,
      current_url: current_url,
      type_choose: type_choose,
    },
  }
}

export function addressUpdateTokenRecord(contract_address) {
  return (dispatch) => {
    dispatch(request())
    fetch(`${API_ADDR}/tokenrecord/${contract_address}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'fail') {
          dispatch(fail(res.message, undefined, 1))
        } else {
          let type_choose = 1
          res.data.token_record_type == 'WRC721'
            ? (type_choose = 2)
            : res.data.token_record_type == 'WRC10'
            ? (type_choose = 3)
            : (type_choose = 1)
          dispatch(success(res.data, 4, type_choose))
        }
      })
      .catch((err) => {})
  }
}

export function insertTokenRecord(data, priv) {
  const type_token =
    data.token_record_type == 'WRC20' ? 1 : data.token_record_type == 'WRC721' ? 2 : 3

  var formData = new FormData()

  formData.append(
    'contract_address',
    data.contract_address == undefined ? '' : data.contract_address
  )
  formData.append('contract_name', data.contract_name == undefined ? '' : data.contract_name)
  formData.append(
    'contract_creation_date',
    data.contract_creation_date == undefined ? 0 : data.contract_creation_date
  )
  formData.append(
    'contract_creator',
    data.contract_creator == undefined ? '' : data.contract_creator
  )
  formData.append('token_name', data.token_name == undefined ? '' : data.token_name)
  formData.append(
    'token_introduction',
    data.token_introduction == undefined ? '' : data.token_introduction
  )
  formData.append(
    'token_abbreviation',
    data.token_abbreviation == undefined ? '' : data.token_abbreviation
  )
  formData.append(
    'token_supply_without_precision',
    data.token_supply_without_precision == undefined ? 0 : data.token_supply_without_precision
  )
  formData.append('token_supply', data.token_supply == undefined ? 0 : data.token_supply)
  formData.append('precision', data.precision == undefined ? 0 : data.precision)
  formData.append('issuer', data.issuer == undefined ? '' : data.issuer)
  formData.append('email', data.email == undefined ? '' : data.email)
  formData.append(
    'project_offical_website',
    data.project_offical_website == undefined ? '' : data.project_offical_website
  )
  formData.append('link_for_github', data.link_for_github == undefined ? '' : data.link_for_github)
  formData.append(
    'link_for_white_paper',
    data.link_for_white_paper == undefined ? '' : data.link_for_white_paper
  )
  formData.append('social_media_link', JSON.stringify(data.social_media_link))
  formData.append('token_record_type', data.token_record_type)
  formData.append('price_win', data.price_win == undefined ? 0 : data.price_win)
  formData.append('price_usd', data.price_usd == undefined ? 0 : data.price_usd)
  formData.append('private_key', data.private_key == undefined ? '' : data.private_key)
  formData.append('token_icon', data.token_icon == undefined ? '' : data.token_icon)
  formData.append('file_logo', data.file_logo)

  return async (dispatch) => {
    dispatch(request(data))
    const config_key = await ConfigKey.getkey()
    const fee =
      type_token == 1
        ? config_key.REACT_APP_API_CREATE_RC20_FEE
        : type_token == 2
        ? config_key.REACT_APP_API_CREATE_RC721_FEE
        : config_key.REACT_APP_API_CREATE_RC10_FEE

    let precision = 6

    var num_after_split = String(parseFloat(fee)).split('.')

    if (num_after_split.length == 2) {
      precision -= num_after_split[1].length
    }
    var amount_temp = String(parseFloat(fee)).replace('.', '')

    for (let idx = 0; idx < precision; idx++) {
      amount_temp += '0'
    }

    fetch(`${API_ADDR}/tokenrecord`, {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == 'success') {
          dispatch(success(res.data, 4, 1))
        } else {
          dispatch(fail(res.message, data, type_token))
        }
      })
      .catch((err) => {})
  }
}

export function getTokenRecord(contract_address) {
  return (dispatch) => {
    dispatch(request())
    fetch(`${API_ADDR}/tokenrecord/${contract_address}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'fail') {
          dispatch(fail(res.message, undefined, 1))
        } else {
          dispatch(success(res.data, 2, 1))
        }
      })
      .catch((err) => {
        dispatch(fail('', undefined, 1))
      })
  }
}

export function addressUpdateTokenRecordAPI(data, priv) {
  const type_token = data.type_choose

  var formData = new FormData()

  formData.append(
    'token_introduction',
    data.data.token_introduction == undefined ? '' : data.data.token_introduction
  )
  formData.append('email', data.data.email == undefined ? '' : data.data.email)
  formData.append(
    'project_offical_website',
    data.data.project_offical_website == undefined ? '' : data.data.project_offical_website
  )
  formData.append(
    'link_for_github',
    data.data.link_for_github == undefined ? '' : data.data.link_for_github
  )
  formData.append(
    'link_for_white_paper',
    data.data.link_for_white_paper == undefined ? '' : data.data.link_for_white_paper
  )
  formData.append('social_media_link', JSON.stringify(data.data.social_media_link))
  formData.append('token_icon', data.data.token_icon == undefined ? '' : data.data.token_icon)
  formData.append('file_logo', data.data.file_logo)
  formData.append(
    'token_record_type',
    type_token == 1 ? 'WRC20' : type_token == 2 ? 'WRC721' : 'WRC10'
  )
  formData.append('issuer', data.data.issuer)

  return async (dispatch) => {
    dispatch(request(data.data))
    const config_key = await ConfigKey.getkey()

    const fee =
      type_token == 1
        ? config_key.REACT_APP_API_UPDATE_RC20_FEE
        : type_token == 2
        ? config_key.REACT_APP_API_UPDATE_RC721_FEE
        : config_key.REACT_APP_API_UPDATE_RC10_FEE

    let precision = 6

    var num_after_split = String(parseFloat(fee)).split('.')

    if (num_after_split.length == 2) {
      precision -= num_after_split[1].length
    }
    var amount_temp = String(parseFloat(fee)).replace('.', '')

    for (let idx = 0; idx < precision; idx++) {
      amount_temp += '0'
    }

    fetch(`${API_ADDR}/tokenrecord/address/${data.data.contract_address}`, {
      method: 'PUT',
      mode: 'no-cors',
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == 'success') {
          dispatch(success(res.data, 4, 1))
        } else {
          dispatch(fail(res.message, data.data, type_token))
        }
      })
      .catch((err) => {})
  }
}

var bcrypt = require('bcryptjs')
const saltRounds = 10

export function adminUpdateTokenRecordAPI(data, priv, contrac_addr, repuration) {
  const type_token =
    data.token_record_type == 'WRC20' ? 1 : data.token_record_type == 'WRC721' ? 2 : 3

  return (dispatch) => {
    // dispatch(request(data))
    bcrypt.genSalt(saltRounds, function (err, salt) {
      bcrypt.hash(priv, salt, function (err, ciphertext) {
        fetch(`${API_ADDR}/tokenrecord/admin/${contrac_addr}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          body: JSON.stringify({
            repuration: repuration,
            cipher: ciphertext,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.status === 'fail') {
              dispatch(failUpdate(res.message, data, type_token))
              // dispatch(initTokenRecord())
              notification.error({
                message: 'Failed!',
                description: `Updated Failed`,
              })
              return
            } else {
              notification.success({
                message: 'Success!',
                description: `Updated Successfully`,
              })
              dispatch(success(res.data, 4, 1))
            }
          })
          .catch((err) => {})
      })
    })
  }
}
