import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PageHeader from '../partials/pageHeader'
import { CopyOutlined, CheckCircleOutlined, QrcodeOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import '../../styles/account.less'
import AccountQrCode from '../accountQrCode'

const AddressIdStyled = styled.span`
  font-weight: normal;
`

class AddressHeader extends Component {
  state = {
    copied: false,
    showQR: false,
  }

  handleCloseQRModal = () => {
    this.setState({
      showQR: false,
    })
  }

  handleShowQRModal = () => {
    this.setState({
      showQR: true,
    })
  }

  render() {
    return (
      <PageHeader className="account-detail-header">
        Account{' '}
        <AddressIdStyled>
          #{this.props.address}
          <CopyToClipboard
            text={this.props.address}
            onCopy={() => {
              this.setState({ copied: true })
              setTimeout(
                function () {
                  this.setState({ copied: false })
                }.bind(this),
                1000
              )
            }}
          >
            {this.state.copied ? (
              <span style={{ marginLeft: '10px' }}>
                <CheckCircleOutlined /> Copied
              </span>
            ) : (
              <CopyOutlined style={{ marginLeft: '10px' }} />
            )}
          </CopyToClipboard>
          <span style={{ marginLeft: '10px' }}>
            <QrcodeOutlined onClick={this.handleShowQRModal} />
          </span>
        </AddressIdStyled>
        <AccountQrCode
          showQrCode={this.state.showQR}
          hideQrCode={this.handleCloseQRModal}
          address={this.props.address}
        />
      </PageHeader>
    )
  }
}
const mapStateToProps = (state) => {
  return {}
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AddressHeader)
