import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import TypeTokenRecord from './typeRecord'
import FormTokenRecord from './formRecord'
import ConfirmTokenRecord from './confirm'
import DoneTokenRecord from './done'

class RecordSwitchMenu extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Switch>
        <Route
          path={[
            '/token-record/create/Type',
            '/update/token20/:id/Type',
            '/update/token10/:id/Type',
            '/update/token721/:id/Type',
          ]}
          render={() => <TypeTokenRecord />}
        />
        <Route
          path={[
            '/token-record/create/Record',
            '/update/token20/:id/Record',
            '/update/token10/:id/Record',
            '/update/token721/:id/Record',
          ]}
          render={() => <FormTokenRecord />}
        />
        <Route
          path={[
            '/token-record/create/Confirm',
            '/update/token20/:id/Confirm',
            '/update/token10/:id/Confirm',
            '/update/token721/:id/Confirm',
          ]}
          render={() => <ConfirmTokenRecord />}
        />
        <Route
          path={[
            '/token-record/create/Done',
            '/update/token20/:id/Done',
            '/update/token10/:id/Done',
            '/update/token721/:id/Done',
          ]}
          render={() => <DoneTokenRecord />}
        />
      </Switch>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(RecordSwitchMenu)
