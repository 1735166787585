import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PageHeader from '../partials/pageHeader'
import { TableRow, Td } from './style'
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Avatar, Button, Col, Row } from 'antd'
import TypeRC from '../../assets/images/type_rc.png'
import { getDefaulteyFromToken } from '../../common/commonTransaction'
import { Redirect } from 'react-router'
import WUelupsLogo from '../../assets/images/WUelupsLogo.png'

const DivName = styled(Col)`
  align-self: center;
  margin-left: 10px;
`
const DivHeader = styled(Row)`
  padding: 20px 10px;
  display: flex;
  border: 0.5px solid lightgrey;
  flex-direction: row;
`
const TextHeader = styled.p`
  margin: 0;
`
const TypeText = styled.p`
  position: absolute;
  padding: 5px 12px;
  background-image: url(${TypeRC});
  top: 55px;
  left: 70px;
  font-size: 10px;
  border-radius: 8px;
  color: white;
  padding-left: 18px;
`
const DivHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
`
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultPriv: getDefaulteyFromToken(),
      updateRecord: false,
    }
  }
  render() {
    let { tokenRecord } = this.props
    return (
      <>
        {this.state.updateRecord ? (
          <Redirect
            to={'/update/' + window.location.pathname.split('/')[1] + '/' + this.props.id + '/Type'}
          />
        ) : (
          <div style={{ paddingTop: '20px' }}>
            <DivHeaderTitle>
              <p style={{ fontSize: '20px', fontWeight: '500', margin: 0 }}>Token Detail</p>
              {tokenRecord.data.issuer == this.state.defaultPriv.address ? (
                <Button
                  danger
                  style={{ borderRadius: 0 }}
                  onClick={() =>
                    this.setState({
                      updateRecord: true,
                    })
                  }
                >
                  Update information
                </Button>
              ) : (
                ''
              )}
            </DivHeaderTitle>
            <TypeText>{tokenRecord.data.token_record_type}</TypeText>
            <DivHeader>
              <Col flex="65px">
                <img
                  size={64}
                  src={
                    tokenRecord.data.token_icon
                      ? tokenRecord.data.token_icon
                      : '/token-record-default.jpg'
                  }
                  style={{
                    objectFit: 'contain',
                    background: 'white',
                    width: '64px',
                    height: '64px',
                    borderRadius: '64px',
                    padding: '5px',
                  }}
                  onError={(e) => (e.target.src = '/token-record-default.jpg')}
                />
              </Col>
              <DivName style={{ overflow: 'auto', flex: '1' }}>
                <TextHeader
                  style={{
                    fontSize: '20px',
                    fontWeight: '500',
                  }}
                >
                  {tokenRecord.data.token_name === undefined
                    ? '–'
                    : tokenRecord.data.token_name
                        .trim()
                        .replace(/[^\x0-\x7]/g, '')
                        .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                      (tokenRecord.data.token_abbreviation === undefined
                        ? ''
                        : ' (' +
                          tokenRecord.data.token_abbreviation
                            .trim()
                            .replace(/[^\x0-\x7]/g, '')
                            .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                          ')')}
                </TextHeader>
                <TextHeader
                  style={{
                    fontSize: '12px',
                    overflowWrap: 'break-word',
                  }}
                >
                  {tokenRecord.data.token_introduction}
                </TextHeader>
              </DivName>
            </DivHeader>
          </div>
        )}
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    tokenRecord: state.tokenRecord,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(Header)
