import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FaExchangeAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons'
import {
  Details,
  Top,
  Content,
  StyledRow,
  StyledSpace,
  ConsumeContainer,
  InfoConsumeContainer,
  DetailTop,
} from './style'
import { Col } from 'antd'

class IssueAssetContract extends Component {
  state = {
    copied_owner: false,
    copied_to: false,
  }
  render() {
    const { asset_name, bandwidth_fee, consume_bandwidth, contract } = this.props.transaction
    const owner_address = contract.parameter.raw ? contract.parameter.raw.owner_address : '–'
    const total_supply = contract.parameter.raw ? contract.parameter.raw.total_supply : '–'
    const trx_num = contract.parameter.raw ? contract.parameter.raw.trx_num : '–'
    const precision = contract.parameter.raw ? contract.parameter.raw.precision : '–'
    const abbr = contract.parameter.raw ? contract.parameter.raw.abbr : '–'
    const url = contract.parameter.raw ? contract.parameter.raw.url : '–'

    const bandwidth_fee_1 = bandwidth_fee / Math.pow(10, 6)
    const bandwidth_fee_2 = bandwidth_fee / Math.pow(10, 3)
    return (
      <Details>
        <Top>
          <DetailTop>
            <FaExchangeAlt />
            &nbsp; Issue Asset Contract
          </DetailTop>
        </Top>
        <Content>
          <StyledRow>
            <Col md={6} span={24}>
              Owner Address:
            </Col>
            <Col md={18} span={24}>
              {owner_address.length < 34 ? (
                '–'
              ) : (
                <>
                  <Link to={`/accounts/${owner_address}`}>{owner_address}</Link>
                  <CopyToClipboard
                    text={owner_address}
                    onCopy={() => {
                      this.setState({ copied_owner: true })
                      setTimeout(
                        function () {
                          this.setState({ copied_owner: false })
                        }.bind(this),
                        1000
                      )
                    }}
                  >
                    {this.state.copied_owner ? (
                      <span style={{ marginLeft: '10px' }}>
                        <CheckCircleOutlined /> Copied
                      </span>
                    ) : (
                      <CopyOutlined style={{ marginLeft: '10px' }} />
                    )}
                  </CopyToClipboard>
                </>
              )}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Asset Name:
            </Col>
            <Col md={18} span={24}>
              <Link to={`/token/${asset_name}`}>{abbr}</Link>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Total Supply:
            </Col>
            <Col md={18} span={24}>
              <NumberFormat
                displayType={'text'}
                thousandSeparator={true}
                value={total_supply / Math.pow(10, precision)}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Price:
            </Col>
            <Col md={18} span={24}>
              <NumberFormat
                displayType={'text'}
                thousandSeparator={true}
                value={trx_num / Math.pow(10, 6)}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Precision:
            </Col>
            <Col md={18} span={24}>
              <span>{precision}</span>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              Official Website:
            </Col>
            <Col md={18} span={24}>
              <a target="_blank" href={url}>
                {url}
              </a>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={6} span={24}>
              <span>Consume Bandwidth</span>:
            </Col>
            <Col md={18} span={24}>
              <ConsumeContainer>
                <InfoConsumeContainer>
                  <span>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={consume_bandwidth + bandwidth_fee_2}
                      suffix=" Bandwidth"
                    />
                  </span>
                </InfoConsumeContainer>
                <InfoConsumeContainer>
                  <span style={{ width: '50%' }}>
                    &#8735; Consumption of staked/free bandwidth:
                  </span>
                  <span>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={consume_bandwidth}
                      suffix=" Bandwidth"
                    />
                  </span>
                </InfoConsumeContainer>
                <InfoConsumeContainer>
                  <span style={{ width: '50%' }}>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={bandwidth_fee_1}
                      suffix=" WIN for bandwidth:"
                      prefix="&#8735; Burn "
                    />
                  </span>
                  <span>
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      value={bandwidth_fee_2}
                      suffix=" Bandwidth"
                    />
                  </span>
                </InfoConsumeContainer>
              </ConsumeContainer>
            </Col>
          </StyledRow>
        </Content>
      </Details>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transaction: state.transaction,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  IssueAssetContract
)
