import React from 'react'
import { Table } from 'antd'
import { connect } from 'react-redux'
import { loadNodes } from '../../actions/nodes'
import styled from 'styled-components'
import PageHeader from '../partials/pageHeader'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'

const Wrapper = styled.div`
  margin: 5px;
`

class NodeTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = { current: 1, pageSize: 20 }
  }

  componentDidMount() {
    this.props.urlAtNotPrivFunc()
    this.props.loadNodes()
  }

  columns = [
    {
      title: 'No.',
      key: 'no',
      render: (_, __, index) => index + 1 + (this.state.current - 1) * this.state.pageSize,
      fixed: 'left',
      width: 70,
    },
    {
      title: 'Host',
      key: 'host',
      dataIndex: 'host',
    },
    {
      title: 'Port',
      key: 'port',
      dataIndex: 'port',
    },
  ]

  render() {
    const { nodes } = this.props
    return (
      <Wrapper>
        <PageHeader>List of Official Nodes</PageHeader>
        <Table
          columns={this.columns}
          dataSource={nodes}
          rowKey="host"
          loading={nodes !== null ? (nodes.length === 0 ? true : false) : false}
          locale={{ emptyText: 'Loading' }}
          pagination={{
            current: this.state.current,
            pageSize: this.state.pageSize,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              this.setState({ current: page, pageSize })
            },
          }}
        />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    nodes: state.nodes,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadNodes: () => {
      dispatch(loadNodes())
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(NodeTable)
