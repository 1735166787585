import { API_TOOL_ADDR_WALLET, API_TOOL_ADDR_WALLET_SOLIDITY, API_ADDR } from '../config/config'
import jwt_decode from 'jwt-decode'

const TronWeb = require('tronweb')

const tronWebWallet = new TronWeb({
  fullHost: `${API_TOOL_ADDR_WALLET}`,
  solidityNode: `${API_TOOL_ADDR_WALLET}`,
  privateKey: 'fdfdffdf',
})
const tronWebWalletSolidity = new TronWeb({
  fullHost: `${API_TOOL_ADDR_WALLET_SOLIDITY}`,
  solidityNode: `${API_TOOL_ADDR_WALLET_SOLIDITY}`,
  privateKey: 'fdfdffdf',
})

const arrayBufferToHex = require('array-buffer-to-hex')

var BASE58 = '123456789ABCDEFGHJKLMNPQRSWUVTXYZabcdefghijkmnopqrswuvtxyz'
export var bs58 = require('base-x')(BASE58)

export async function GetFromPrivateKey(privateKey) {
  let w = await tronWebWallet.address.fromPrivateKey(privateKey)
  return w
}

export async function GetInfoTransactionWRC20(id) {
  let w = await tronWebWalletSolidity.trx.getTransactionInfo(id)
  try {
    return parseInt(w.log[0].data, 16)
  } catch (error) {
    return undefined
  }
}
export async function GetInfoTransactionWRC10(id) {
  let w = await tronWebWallet.trx.getTransaction(id)

  return {
    amount: w.raw_data.contract[0].parameter.value.amount,
    asset_name: w.raw_data.contract[0].parameter.value.asset_name,
  }
}
export async function GetTriggerSmartContract(account, contractAddress) {
  try {
    let contract = await tronWebWallet.contract().at(contractAddress)
    let name = await contract.name().call()
    let symbol = await contract.symbol().call()
    let decimals = await contract.decimals().call()
    let totalSupply = await contract.totalSupply().call()
    var amount = 0
    if (account != '') {
      let balance = await contract.balanceOf(account).call()
      amount = new Number(balance.toString()) / Math.pow(10, decimals)
    }

    var token = name === undefined ? '–' : name + (symbol === undefined ? '' : ` (${symbol})`)
    return {
      token: token,
      totalSupply: new Number(totalSupply.toString()),
      decimals: decimals.toString(),
      amount: amount,
    }
  } catch (error) {
    return 0
  }
}

export function ConvertHexToString(data) {
  var hex = data.toString()
  var str = ''
  for (var n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16))
  }
  return str
}

export async function GetInformationWRC10(data) {
  var infoWRC10 = await GetInfoTransactionWRC10(data.hash)
  var token = undefined
  if (data.contract.type === 'TransferAssetContract') {
    var tokenInfo = await fetch(
      `${API_ADDR}/address/gettokenwrc10/${ConvertHexToString(infoWRC10.asset_name)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      }
    )
      .then((res) => res.json())
      .then((res) => {
        return res.data.result
      })
      .catch((err) => {})

    data.contract.parameter.raw.amount = infoWRC10.amount / Math.pow(10, tokenInfo.precision)

    token = ConvertHexToString(tokenInfo.name) + ' (' + ConvertHexToString(tokenInfo.abbr) + ')'
  } else if (data.contract.type === 'TransferContract') {
    token = 'WIN'
  }
  return {
    data: data,
    token: token,
  }
}
export async function GetInformationWRC20(data) {
  var amountWRC20 = await GetInfoTransactionWRC20(data.hash)
  var triggerContract = await GetTriggerSmartContract(
    '',
    arrayBufferToHex(bs58.decode(data.contract.parameter.raw.contract_address)).slice(0, 42)
  )
  var token = triggerContract.token === undefined ? '–' : triggerContract.token

  if (amountWRC20 === undefined) {
    data.contract.parameter.raw.amount = undefined
  } else {
    data.contract.parameter.raw.amount = amountWRC20 / Math.pow(10, triggerContract.decimals)
  }

  return {
    data: data,
    token: token,
  }
}

export async function getCurrentBlock() {
  var result = await fetch(`${API_ADDR}/system/state`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  })
    .then((res) => res.json())
    .then((res) => {
      return res.data.block_height
    })
    .catch((err) => {})
  return result
}

export async function GetAccountNew(id) {
  let w = await tronWebWallet.trx.getAccount(arrayBufferToHex(bs58.decode(id)).slice(0, 42))
}

export function getDefaulteyFromToken() {
  let tokenDecoded = null
  let token = localStorage.getItem('token')
  try {
    tokenDecoded = jwt_decode(token)
  } catch (e) {}

  if (tokenDecoded && tokenDecoded.email) {
    var privateKey = localStorage.getItem(tokenDecoded.email + 'defaultKey')
    if (privateKey) {
      return JSON.parse(privateKey)
    }
  }
  return {
    priv: undefined,
    address: undefined,
  }
}
