import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import TransTable from './addressTransactionTable'
import TransferTable from './addressTransferTable'
import TokenTable from './addressTokenTable'

class AddressSwitchMenu extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Switch>
        <Route
          path={'/accounts/' + this.props.address + '/transaction'}
          render={() => (
            <TransTable address={this.props.address} loadAddress={this.props.loadAddress} />
          )}
        />
        <Route
          path={'/accounts/' + this.props.address + '/transfer'}
          render={() => <TransferTable address={this.props.address} />}
        />
        <Route
          path={'/accounts/' + this.props.address}
          render={() => <TokenTable address={this.props.address} />}
        />
      </Switch>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AddressSwitchMenu)
