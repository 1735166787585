import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Button, Col, Layout, Row, notification, Modal, Space } from 'antd'
import { Redirect } from 'react-router'
import { getDefaulteyFromToken } from '../../common/commonTransaction'
import { updateTokenRecord } from '../../actions/tokenRecord'
import { ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { API_ADDR, REACT_APP_API_CREATE_RC10_FEE } from '../../config/config'
import { Link } from 'react-router-dom'
import ConfigKey from '../../api/key'

const { warning } = Modal

const Wrapper = styled.div`
  margin: 5px;
`
const TypeDiv = styled.div`
  line-height: 55px;
  text-align: center;
  cursor: pointer;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  text-align: justify;
  padding: 20px;
`
const InfoSpan = styled.span`
  margin-top: 10px;
  font: -webkit-control;
  font-size: 10px;
  color: #999;
`
const NameType = styled.span`
  font-size: 55px;
  margin: 0px;
  position: relative;
  top: -5px;
`
const StyledLink = styled(Link)`
  &:link,
  &:visited {
    color: #c23631;
  }
`
const RecommendedDiv = styled.div`
  color: #fff;
  font-size: 12px;
  position: absolute;
  background-color: crimson;
  padding: 0 7px 0 7px;
  line-height: 25px;
  border-top-right-radius: 7px;
  top: 0px;
  right: 8px;
`
const { Header, Footer, Content } = Layout

class TypeTokenRecord extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultPriv: getDefaulteyFromToken(),
      tokenType: 1,
      pathAtType: true,
      powdecimal: 1,
      maxamount: undefined,
      visible: false,
      REACT_APP_API_CREATE_RC10_FEE: 0,
    }
  }
  async componentDidMount() {
    let temp = ''
    if (this.state.defaultPriv != undefined) {
      if (this.state.defaultPriv.address != undefined) {
        temp = this.state.defaultPriv.address
      }
    }
    const config_key = await ConfigKey.getkey()

    this.setState({
      REACT_APP_API_CREATE_RC10_FEE: config_key.REACT_APP_API_CREATE_RC10_FEE,
    })
    var temp_getamountwin = await fetch(`${API_ADDR}/getamountwel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        address: temp,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState,
          powdecimal: 1,
          maxamount: 0,
          loadingmaxamount: false,
        }))
      })

    if (temp_getamountwin !== undefined) {
      if (temp_getamountwin.decimal === undefined || temp_getamountwin.amount === undefined) {
        this.setState((prevState) => ({
          ...prevState,
          powdecimal: 1,
          maxamount: 0,
          loadingmaxamount: false,
        }))
      } else {
        this.setState((prevState) => ({
          ...prevState,
          powdecimal: temp_getamountwin.decimal,
          maxamount: temp_getamountwin.amount,
          loadingmaxamount: false,
        }))
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        powdecimal: 1,
        maxamount: 0,
        loadingmaxamount: false,
      }))
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  confirmSubmit() {
    const init_data = {
      contract_address: '',
      contract_name: '',
      contract_creation_date: '',
      contract_creator: '',
      token_name: '',
      token_introduction: '',
      token_abbreviation: '',
      token_supply: '',
      precision: '',
      issuer: '',
      email: '',
      project_offical_website: '',
      link_for_github: '',
      link_for_white_paper: '',
      social_media_link: {
        twitter: '',
        facebook: '',
        telegram: '',
        weibo: '',
        reddit: '',
        medium: '',
        steem: '',
        instagram: '',
        wechat: '',
        group: '',
        discord: '',
      },
      created_time: 0,
      price_usd: '',
      price_win: '',
      reputation: '',
      token_record_type: '',
    }
    if (this.props.login.token == '') {
      notification.error({
        message: 'Failed!',
        description: `Please login to create a Token record`,
      })
    } else {
      if (this.state.defaultPriv.priv == undefined) {
        notification.error({
          message: 'Failed!',
          description: `There is no privatekey`,
        })
      } else {
        if (this.state.tokenType == 3) {
          if (
            this.state.maxamount / this.state.powdecimal <
            this.state.REACT_APP_API_CREATE_RC10_FEE
          ) {
            this.showModal()
          } else {
            this.props.updateTokenRecord(init_data, 2, this.state.tokenType)
          }
        } else {
          this.props.updateTokenRecord(init_data, 2, this.state.tokenType)
        }
      }
    }
  }
  showConfirm() {
    warning({
      title: (
        <div style={{ textAlign: 'center' }}>
          <ExclamationCircleOutlined style={{ color: 'red', fontSize: '40px' }} />
        </div>
      ),
      icon: '',
      content: (
        <p style={{ textAlign: 'center' }}>
          Issuing WRC10 Token consumes {this.state.REACT_APP_API_CREATE_RC10_FEE} WIN, and you have
          insufficient WIN in your balance.
        </p>
      ),
      onOk() {},
      okText: 'Confirm',
      okType: 'danger',
    })
  }
  render() {
    const { visible } = this.state
    const { tokenRecord } = this.props
    const current = tokenRecord.current_url

    if (current == 2) {
      return <Redirect to={window.location.pathname.replace('/Type', '') + '/Record'} />
    } else if (current == 3) {
      return <Redirect to={window.location.pathname.replace('/Type', '') + '/Confirm'} />
    } else if (current == 4) {
      return <Redirect to={window.location.pathname.replace('/Type', '') + '/Done'} />
    }
    return (
      <Layout>
        <Modal
          visible={visible}
          title={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CloseCircleOutlined style={{ fontSize: '40px', color: '#d10000' }} />
            </div>
          }
          closeIcon={<></>}
          maskClosable={false}
          onCancel={this.handleCancel}
          footer={[
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                key="back"
                onClick={this.handleCancel}
                type="primary"
                style={{ padding: '0 45px' }}
              >
                Confirm
              </Button>
            </div>,
          ]}
        >
          <p>
            Issuing WRC10 Token consumes {this.state.REACT_APP_API_CREATE_RC10_FEE} WIN, and you
            have insufficient WIN in your balance.
          </p>
        </Modal>
        <Content>
          <p style={{ marginTop: '20px', marginBottom: '20px' }}>Please select token type</p>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <TypeDiv
                style={{
                  background: this.state.tokenType === 1 ? '#333' : '#fff',
                }}
                onClick={() =>
                  this.setState({
                    tokenType: 1,
                  })
                }
              >
                <NameType
                  style={{
                    color: this.state.tokenType !== 1 ? '#333' : '#fff',
                  }}
                >
                  WRC20
                </NameType>
                <InfoSpan>
                  WRC20 token is issued by smart contract, so make sure you have finished{' '}
                  <StyledLink to="/user/deploycontract" target="_blank">
                    Deploy Smart Contract
                  </StyledLink>
                  . Each account may enter mutiple WRC20 tokens.
                </InfoSpan>
              </TypeDiv>
            </Col>
            <Col className="gutter-row" span={8}>
              <TypeDiv
                style={{
                  background: this.state.tokenType === 2 ? '#333' : '#fff',
                }}
                onClick={() =>
                  this.setState({
                    tokenType: 2,
                  })
                }
              >
                <NameType
                  style={{
                    color: this.state.tokenType !== 2 ? '#333' : '#fff',
                  }}
                >
                  WRC721
                </NameType>
                <InfoSpan>
                  WRC721 token is issued by smart contract, so make sure you have finished{' '}
                  <StyledLink to="/user/deploycontract" target="_blank">
                    Deploy Smart Contract
                  </StyledLink>
                  . Each account may enter mutiple WRC721 tokens.
                </InfoSpan>
              </TypeDiv>
            </Col>
            <Col className="gutter-row" span={8}>
              <TypeDiv
                style={{
                  background: this.state.tokenType === 3 ? '#333' : '#fff',
                }}
                onClick={() =>
                  this.setState({
                    tokenType: 3,
                  })
                }
              >
                <NameType
                  style={{
                    color: this.state.tokenType !== 3 ? '#333' : '#fff',
                  }}
                >
                  WRC10
                </NameType>
                <InfoSpan>
                  WRC10 token, which is issued directly on the blockchain, costs{' '}
                  {this.state.REACT_APP_API_CREATE_RC10_FEE} WIN. Please make sure you have
                  sufficient WIN balance. Each account may only issue one WRC10 token.
                </InfoSpan>
              </TypeDiv>
            </Col>
          </Row>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            style={{ borderRadius: '0px', width: '250px' }}
            onClick={() => this.confirmSubmit()}
          >
            Confirm
          </Button>
        </Footer>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tokenRecord: state.tokenRecord,
    login: state.login,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateTokenRecord: (data, current_url, type_choose) => {
      dispatch(updateTokenRecord(data, current_url, type_choose))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  TypeTokenRecord
)
