import { LoadingOutlined } from '@ant-design/icons'
import { Button, Form, Input, InputNumber, notification, Result, Select, Spin } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import { loadTransferRCApi } from '../../actions/transferLoadRC10RC20'
import { reset, transferWRC20 } from '../../actions/transferWRC20'
import { getDefaulteyFromToken, GetFromPrivateKey } from '../../common/commonTransaction'
import { ValidateAddress } from '../../common/validate'
import { API_ADDR } from '../../config/config'
import { GLOBAL_SYMBOL } from '../../constant'
import TruncateMiddle from 'react-truncate-string'

const ButtonSubmit = styled(Button)`
  width: 100%;
  margin-bottom: 40px;
  color: #ffffff;
`
const Item = styled(Form.Item)`
  font-weight: bold;
`
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`
const Logo = styled.img`
  height: 66px;
  width: 200px;
  margin-bottom: 20px;
`
const Title = styled.span`
  font-size: 25px;
  font-weight: 600;
`
const ContentTitle = styled.span`
  font-weight: 400;
  margin-left: 7px;
`
const TitleContainer = styled.div`
  text-align: left;
`
const HeaderTitle = styled.div`
  margin-top: 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledForm = styled(Form)`
  @media (min-width: 540px) {
    width: 450px;
  }
  @media (max-width: 450px) {
    width: 250px;
  }
`

const { Option } = Select
const defaultToken = { abbr: GLOBAL_SYMBOL, precision: 6, name: GLOBAL_SYMBOL }
class TransferWRC20 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      address_owner: '',
      to_address: '',
      contract_address: undefined,
      amount: '',
      maxamount: undefined,
      amounttest: '',
      errorAmount: false,
      error: {
        status: false,
        msg: '',
      },
      loading: false,
      decimal: 1,
      powdecimal: 1,
      loadingmaxamount: false,
      defaultPriv: getDefaulteyFromToken(),
      countFirstTime: true,
    }
  }
  formRef = React.createRef()

  async componentDidMount() {
    this.props.urlAtNotPrivFunc()
    var { prikeys, login } = this.props
    if (
      (!prikeys.prikeys && login.token !== '') ||
      (prikeys.prikeys.length === 0 && login.token !== '')
    ) {
      notification.warning({
        message: 'Warning!',
        description:
          'You have no private key, please add somes in private key management to perform transaction!',
      })
    }
    if (this.state.defaultPriv.address !== undefined) {
      this.props.loadTokens(this.state.defaultPriv.address)
    }
  }
  componentWillUnmount() {
    this.props.resetTransferAsset()
  }
  transfer = (values) => {
    var { prikeys } = this.props
    this.props.transferWRC20Func(
      prikeys.prikeys,
      values.privatekey,
      values.toAddress,
      values.contractAddress,
      values.amount
    )
  }
  changeToAddress = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      to_address: e.target.value,
    }))
  }
  changeContractAddress = async (selected) => {
    this.setState((prevState) => ({
      ...prevState,
      contract_address: selected,
    }))

    if (selected != undefined) {
      var powdecimal = await fetch(`${API_ADDR}/transferrc20/getpowdecimal/` + selected, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      })
        .then((res) => res.json())
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          this.setState((prevState) => ({
            ...prevState,
            powdecimal: 1,
            maxamount: undefined,
          }))
        })

      var address = this.state.countFirstTime
        ? this.state.defaultPriv.address === undefined
          ? ''
          : this.state.defaultPriv.address
        : this.state.address_owner === undefined
        ? ''
        : this.state.address_owner

      await this.changeMaxAmount(selected, address)

      var temp = 0
      while (powdecimal != 1) {
        powdecimal /= 10
        temp++
      }
      this.setState((prevState) => ({
        ...prevState,
        decimal: temp,
        amount: '',
      }))
    } else {
      this.setState((prevState) => ({
        ...prevState,
        powdecimal: 1,
        maxamount: undefined,
      }))
    }
  }

  changeAmount = (e) => {
    var regex = RegExp(`^[?!0\\d]{0,1}\\d*(\.\\d{0,${this.state.decimal}})?$`)
    if (regex.test(e.target.value)) {
      this.setState((prevState) => ({
        ...prevState,
        amount: e.target.value,
      }))
    }
  }
  changePrivateKey = async (address) => {
    this.setState((prevState) => ({
      ...prevState,
      contract_address: undefined,
    }))
    this.formRef.current.setFieldsValue({
      contractAddress: undefined,
    })
    if (this.state.countFirstTime && address === undefined) {
      this.setState({
        countFirstTime: false,
      })
    }
    if (address != '' && address != undefined) {
      this.setState({
        countFirstTime: false,
      })

      this.props.loadTokens(address)
      if (this.state.contract_address != undefined) {
        await this.changeMaxAmount(this.state.contract_address, address)
      } else {
        this.setState((prevState) => ({
          ...prevState,
          powdecimal: 1,
          maxamount: undefined,
          loadingmaxamount: false,
        }))
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        powdecimal: 1,
        maxamount: undefined,
        loadingmaxamount: false,
      }))
    }
    this.setState((prevState) => ({
      ...prevState,
      address_owner: address,
    }))
  }

  changeMaxAmount = async (contract_address, address) => {
    if (contract_address.length === 34 && address != '' && address != undefined) {
      if (address.length === 34) {
        this.setState((prevState) => ({
          ...prevState,
          loadingmaxamount: true,
        }))
        var temp_getamountrc20 = await fetch(`${API_ADDR}/getamountrc20`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          body: JSON.stringify({
            address: address,
            contract: contract_address,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            return res.data
          })
          .catch((err) => {
            this.setState((prevState) => ({
              ...prevState,
              powdecimal: 1,
              maxamount: undefined,
              loadingmaxamount: false,
            }))
          })

        if (temp_getamountrc20 !== undefined) {
          if (temp_getamountrc20.decimal === undefined || temp_getamountrc20.amount === undefined) {
            this.setState((prevState) => ({
              ...prevState,
              powdecimal: 1,
              maxamount: undefined,
              loadingmaxamount: false,
            }))
          } else {
            this.setState((prevState) => ({
              ...prevState,
              powdecimal: temp_getamountrc20.decimal,
              maxamount: temp_getamountrc20.amount,
              loadingmaxamount: false,
            }))
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            powdecimal: 1,
            maxamount: undefined,
            loadingmaxamount: false,
          }))
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          powdecimal: 1,
          maxamount: undefined,
          loadingmaxamount: false,
        }))
      }
    }
  }

  validation = () => {
    if (!ValidateAddress.test(this.state.contract_address)) {
      this.setState({
        error: {
          status: true,
          msg: 'Contract address không đúng định dạng',
        },
      })
    } else if (!ValidateAddress.test(this.state.to_address)) {
      this.setState({
        error: {
          status: true,
          msg: 'To address không đúng định dạng',
        },
      })
    } else {
      this.setState({
        error: {
          status: false,
          msg: '',
        },
      })
    }
  }

  render() {
    const { datawrc20, prikeys, transferLoadRC10RC20 } = this.props
    if (!localStorage.getItem('token')) {
      return <Redirect to="/login" />
    }
    let tokens = transferLoadRC10RC20.data ? transferLoadRC10RC20.data : null

    const antIcon = <LoadingOutlined spin />
    return (
      <Wrapper>
        <Spin indicator={antIcon} tip="Processing..." spinning={datawrc20.status === 'requesting'}>
          {datawrc20.status === 'success' && (
            <div>
              <Result
                status="success"
                title={`Your transaction has been issued successfully!`}
                subTitle={`You can check it at transaction ${datawrc20.data.txid}`}
                extra={[
                  <Button type="primary">
                    <Link to={`/transaction/${datawrc20.data.txid}`}>Details</Link>
                  </Button>,
                  <Button
                    onClick={() => {
                      this.props.resetTransferAsset()
                      if (this.state.defaultPriv.address !== undefined) {
                        this.props.loadTokens(this.state.defaultPriv.address)
                      }
                      this.setState((prevState) => ({
                        ...prevState,
                        powdecimal: 1,
                        maxamount: undefined,
                      }))
                    }}
                  >
                    New Transfer WRC20
                  </Button>,
                ]}
              />
              ,
            </div>
          )}
          {datawrc20.status === 'fail' && (
            <div>
              <Result
                status="error"
                title={`Your transaction hasn't been issued, something must went wrong`}
                subTitle={
                  <p style={{ color: 'rgb(234 0 0)!important', fontSize: '15px!important' }}>
                    {datawrc20.message != '' ? `Error: ${datawrc20.message}` : ''}
                  </p>
                }
                extra={[
                  <Button
                    onClick={() => {
                      this.props.resetTransferAsset()
                      if (this.state.defaultPriv.address !== undefined) {
                        this.props.loadTokens(this.state.defaultPriv.address)
                      }
                      this.setState((prevState) => ({
                        ...prevState,
                        powdecimal: 1,
                        maxamount: undefined,
                      }))
                    }}
                  >
                    New Transfer WRC20
                  </Button>,
                ]}
              />
              ,
            </div>
          )}
          {datawrc20.status !== 'fail' && datawrc20.status !== 'success' && (
            <StyledForm layout="vertical" size="large" ref={this.formRef} onFinish={this.transfer}>
              <HeaderTitle>
                <Title>Transfer WRC20</Title>
              </HeaderTitle>
              <TitleContainer>
                <ContentTitle>Your private Key</ContentTitle>
              </TitleContainer>
              <Item
                name="privatekey"
                rules={[
                  {
                    required: true,
                    message: 'Private key cannot be empty!',
                  },
                ]}
                initialValue={
                  this.state.defaultPriv.address === undefined ? '' : this.state.defaultPriv.address
                }
              >
                <Select
                  placeholder="Select a private key"
                  onChange={this.changePrivateKey}
                  defaultValue={
                    this.state.defaultPriv.address === undefined
                      ? ''
                      : this.state.defaultPriv.address
                  }
                >
                  {prikeys.prikeys && prikeys.prikeys.length !== 0
                    ? prikeys.prikeys.map((value, index) => (
                        <Option value={value.address} key={index}>
                          {value.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Item>
              <TitleContainer>
                <ContentTitle>To</ContentTitle>
              </TitleContainer>
              <Item
                name="toAddress"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value !== undefined && value !== null) {
                        var regex = new RegExp('^[W][a-zA-Z0-9]{33}$')
                        if (!regex.test(value)) {
                          return Promise.reject(new Error('To address wrong format!'))
                        }

                        return Promise.resolve()
                      } else {
                        return Promise.reject(new Error('To address cannot be empty!'))
                      }
                    },
                  },
                ]}
              >
                <Input value={this.state.to_address} onChange={this.changeToAddress} />
              </Item>
              <TitleContainer>
                <ContentTitle>Contract Address</ContentTitle>
              </TitleContainer>
              <Item
                name="contractAddress"
                rules={[
                  {
                    required: true,
                    message: 'Contract address cannot be empty!',
                  },
                ]}
                initialValue={undefined}
              >
                <Select
                  placeholder="Select a contract address"
                  onChange={this.changeContractAddress}
                  disabled={transferLoadRC10RC20.status === 'requesting' ? true : false}
                  loading={transferLoadRC10RC20.status === 'requesting' ? true : false}
                >
                  {tokens && tokens.length !== 0
                    ? tokens.map((token, index) => (
                        <Option value={token.contract_address} key={index}>
                          <span style={{ display: 'flex' }}>
                            {token.name != undefined && token.name != ''
                              ? token.name
                                  .trim()
                                  .replace(/[^\x0-\x7]/g, '')
                                  .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                                (token.abbr != undefined && token.abbr != ''
                                  ? ' - ' +
                                    token.abbr
                                      .trim()
                                      .replace(/[^\x0-\x7]/g, '')
                                      .replace(/[\u{0080}-\u{FFFF}]/gu, '')
                                  : '')
                              : ''}
                            {token.contract_address != undefined && token.contract_address != ''
                              ? ' ('
                              : ''}
                            <TruncateMiddle text={token.contract_address} />
                            {token.contract_address != undefined && token.contract_address != ''
                              ? ')'
                              : ''}
                          </span>
                        </Option>
                      ))
                    : ''}
                </Select>
              </Item>
              <TitleContainer>
                <ContentTitle>
                  Amount
                  <span style={{ color: 'red' }}>
                    {this.state.maxamount === undefined
                      ? ''
                      : ' (' +
                        new Intl.NumberFormat('ja-JP', { maximumSignificantDigits: 20 }).format(
                          this.state.maxamount / this.state.powdecimal
                        ) +
                        ')'}
                  </span>
                  {this.state.loadingmaxamount ? (
                    <Spin size="small" style={{ marginLeft: '5px' }} />
                  ) : (
                    ''
                  )}
                </ContentTitle>
              </TitleContainer>
              <Item
                name="amount"
                rules={[
                  {
                    validator: (_, value) => {
                      if (this.state.maxamount === '0') {
                        return Promise.reject(new Error("You don't have wallet balance!"))
                      }
                      if (this.state.maxamount === undefined) {
                        return Promise.reject(new Error("You don't have wallet balance!"))
                      }
                      if (value !== undefined && value !== null) {
                        if (String(value).length > 17) {
                          return Promise.reject(new Error('Amount has to be 17 characters long!'))
                        }
                        if (value <= 0) {
                          return Promise.reject(new Error('Amount must be more than 0!'))
                        }
                        if (value > this.state.maxamount / this.state.powdecimal) {
                          return Promise.reject(
                            new Error(
                              'Amount must be less than ' +
                                new Intl.NumberFormat('ja-JP', {
                                  maximumSignificantDigits: 20,
                                }).format(this.state.maxamount / this.state.powdecimal)
                            )
                          )
                        }

                        var regex = new RegExp(
                          `^[?!0\\d]{0,1}\\d*(\.\\d{0,${this.state.decimal}})?$`
                        )
                        if (String(value).split('e-').length == 2) {
                          if (String(value).split('e-')[1] > this.state.decimal) {
                            return Promise.reject(
                              new Error(
                                'Amount is only ' +
                                  this.state.decimal +
                                  ' charaters after the comma!'
                              )
                            )
                          }
                        } else {
                          if (!regex.test(value)) {
                            return Promise.reject(
                              new Error(
                                'Amount is only ' +
                                  this.state.decimal +
                                  ' charaters after the comma!'
                              )
                            )
                          }
                        }

                        return Promise.resolve()
                      } else {
                        return Promise.reject(new Error('Amount cannot be empty!'))
                      }
                    },
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} min={0} />
              </Item>
              <p style={{ color: 'red' }}>{this.state.error.msg}</p>
              <ButtonSubmit type="primary" htmlType="submit">
                Send
              </ButtonSubmit>
            </StyledForm>
          )}
        </Spin>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    datawrc20: state.transferWRC20,
    prikeys: state.prikeyManagement,
    login: state.login,
    transferLoadRC10RC20: state.transferLoadRC10RC20,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    transferWRC20Func: (privs, address, to, contractAddress, amount) => {
      dispatch(transferWRC20(privs, address, to, contractAddress, amount))
    },
    resetTransferAsset: () => {
      dispatch(reset())
    },
    loadTokens: (address) => {
      dispatch(loadTransferRCApi(address, false))
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TransferWRC20)
