import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Spin, Table, Pagination, Tooltip } from 'antd'
import { addAddrFromPrvkey } from '../../actions/assetManagement'
import { loadAccountDetails } from '../../actions/account'
import PageHeader from '../partials/pageHeader'
import { getDefaulteyFromToken } from '../../common/commonTransaction'
import {
  loadAddressTokenUser,
  nullAddressToken,
  updatePageAddressToken,
  updatePageAddressTokenLimit,
} from '../../actions/addressToken'
import TruncateMiddle from 'react-truncate-string'
import NumberFormat from 'react-number-format'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'

const StyledLink = styled(Link)`
  &:link,
  &:visited {
    color: #c23631;
  }
`
const Wrapper = styled.div`
  margin: 1% 0%;
  text-align: left;
`
const Token = styled.p`
  color: #c23631;
  font-size: 14px;
`
const Pagin = styled.div`
  margin-top: 15px;
  text-align: right !important;
`
class AssetManagement extends React.Component {
  constructor() {
    super()
    this.onChange = this.onChange.bind(this)
    this.state = {
      defaultPriv: getDefaulteyFromToken(),
    }
  }
  componentDidMount() {
    this.props.urlAtNotPrivFunc()
    var { pageAddressToken } = this.props

    if (this.state.defaultPriv.address !== undefined) {
      this.props.loadData(
        this.state.defaultPriv.address,
        parseInt(pageAddressToken.current_page),
        parseInt(pageAddressToken.page_limit)
      )
    } else {
      this.props.loadDefaultData()
    }
  }
  onChange = (pageNumber, pageLimit) => {
    var { pageAddressToken } = this.props
    if (pageNumber !== pageAddressToken.current_page) {
      this.props.updatePageAddressToken(pageNumber)
    }
    if (pageLimit !== pageAddressToken.page_limit) {
      this.props.updatePageAddressTokenLimit(pageLimit)
    }
    if (this.state.defaultPriv.address !== undefined) {
      this.props.loadData(this.state.defaultPriv.address, pageAddressToken.current_page, pageLimit)
    } else {
      this.props.loadDefaultData()
    }
  }

  render() {
    var { tokens, total, status, pageAddressToken } = this.props
    const columns = [
      {
        title: 'Token',
        key: 'token',
        render: (record) =>
          record.name !== 'WIN' ? (
            <Link
              key={record.contract_address}
              from="*"
              to={
                (record.token_type === 'WRC10' ? '/token/' : '/token20/') + record.contract_address
              }
            >
              <Token>
                {record.name === undefined
                  ? '–'
                  : record.name
                      .trim()
                      .replace(/[^\x0-\x7]/g, '')
                      .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                    (record.symbol === undefined
                      ? ''
                      : ' (' +
                        record.symbol
                          .trim()
                          .replace(/[^\x0-\x7]/g, '')
                          .replace(/[\u{0080}-\u{FFFF}]/gu, '') +
                        ')')}
              </Token>
            </Link>
          ) : (
            <p key={record.contract_address}>
              <Token>{record.name}</Token>
            </p>
          ),
        width: '25%',
      },
      {
        title: 'ID (Contract)',
        key: 'id',
        render: (record) =>
          record.name !== 'WIN' ? (
            <StyledLink
              key={record.contract_address}
              to={
                (record.token_type === 'WRC10' ? '/token/' : '/contract/') + record.contract_address
              }
            >
              <Tooltip placement="top" title={record.contract_address}>
                <TruncateMiddle text={record.contract_address} />
              </Tooltip>
            </StyledLink>
          ) : (
            <p style={{ color: '#c23631' }}>
              <Tooltip placement="top" title={record.contract_address}>
                <TruncateMiddle text={record.contract_address} />
              </Tooltip>
            </p>
          ),
        width: '15%',
      },
      {
        title: 'Token Type',
        key: 'tokentype',
        render: (record) => {
          return record.token_type
        },
        width: '15%',
      },

      {
        title: 'Precision',
        key: 'precision',
        render: (record) => {
          return record.precision === -1 ? '–' : record.precision
        },
        width: '10%',
      },
      {
        title: 'Amount',
        key: 'amount',
        render: (record) => (
          <NumberFormat
            displayType={'text'}
            thousandSeparator={true}
            value={
              record.balanceOf === undefined
                ? record.amount === undefined
                  ? '–'
                  : record.amount / Math.pow(10, record.precision == -1 ? 0 : record.precision)
                : record.balanceOf / (record.precision == -1 ? 1 : record.pow_decimal)
            }
          />
        ),
        width: '20%',
      },
    ]
    return (
      <Wrapper>
        <PageHeader>Tokens</PageHeader>
        {status === '' ? (
          <div style={{ paddingTop: '20px', textAlign: 'center' }}>
            <Spin size="large" />
            <p>Loading...</p>
          </div>
        ) : (
          <>
            <Table
              columns={columns}
              dataSource={tokens}
              rowKey="token"
              scroll={{ x: 900 }}
              sticky
              pagination={false}
              loading={tokens === undefined ? true : false}
              className="account-list"
            />
            <Pagin>
              <Pagination
                current={pageAddressToken.start_page}
                total={pageAddressToken.total_items}
                onChange={this.onChange}
                showSizeChanger
                showQuickJumper
                className="account-pagi"
                pageSize={pageAddressToken.page_limit}
                pageSizeOptions={[10, 15, 20, 25]}
              />
            </Pagin>
          </>
        )}
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.addressTokens.status,
    tokens: state.addressTokens.result,
    total: state.addressTokens.total,
    pageAddressToken: state.pageAddressTokens,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadAccountDetails: (addr) => {
      dispatch(loadAccountDetails(addr))
    },
    addAddrFromPrvkey: (id, token, privkey) => {
      dispatch(addAddrFromPrvkey(id, token, privkey))
    },
    updatePageAddressToken: (page) => {
      dispatch(updatePageAddressToken(page))
    },
    updatePageAddressTokenLimit: (limit) => {
      dispatch(updatePageAddressTokenLimit(limit))
    },
    loadData: (address, offset, limit) => {
      dispatch(loadAddressTokenUser(address, offset, limit))
    },
    loadDefaultData: () => {
      dispatch(nullAddressToken())
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  AssetManagement
)
