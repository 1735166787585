import React, { Component } from 'react'
import { connect } from 'react-redux'
import Card from './card'
import styled from 'styled-components'
import { initTokenDetail, loadTokenDetail } from '../../actions/token'
import PageHeader from '../partials/pageHeader'
import CardShadow from '../partials/cardShadow'
import EmptySpace from '../partials/emptySpace'
import { Redirect } from 'react-router'
import { Skeleton, Spin } from 'antd'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'

const Container = styled.div`
  margin: 5px;
`
const CardTitle = styled.div`
  border-bottom: 5px solid #c23631;
  font-size: 20px;
  text-align: left;
`
const CardDes = styled.div`
  width: 100%;
  background-color: #f3f3f3;
  flex-direction: column;
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
  padding-bottom: 5px;
  padding-top: 5px;
`
const StyledName = styled.span`
  font-size: 25px;
  font-weight: 500;
`
const StyledDes = styled.span`
  font-size: 15px;
  font-weight: 300;
`
class TokenDetails extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: true }
  }
  async componentDidMount() {
    this.props.urlAtNotPrivFunc()
    var id = this.props.match.params.id
    this.props.loadTokenDetail(id)
    setTimeout(() => {
      if (this.props.token.data != undefined) {
        this.setState({
          loading: false,
        })
      }
      if (this.props.token.status === 'error') {
        this.props.history.replace('/notfound')
      }
    }, 100)
  }

  render() {
    var { token } = this.props
    return (
      <>
        {this.state.loading ? (
          <div style={{ width: '-webkit-fill-available', textAlign: 'center', marginTop: '15px' }}>
            <Spin size="large" />
            <p>Loading...</p>
          </div>
        ) : token.status === 'error' ? (
          <Redirect from="*" to="/notfound" />
        ) : (
          <>
            <PageHeader
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {token.data.abbr}
              <StyledDes>{token.data.description}</StyledDes>
            </PageHeader>
            <CardShadow>
              <Card />
            </CardShadow>
            <EmptySpace />
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  }
}
const mapDispatchToProps = (dispatch, props) => {
  return {
    loadTokenDetail: (id) => {
      dispatch(loadTokenDetail(id))
    },
    initTokenDetail: () => {
      dispatch(initTokenDetail())
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  TokenDetails
)
