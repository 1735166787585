import React from 'react'
import { Table, Pagination, Badge, Skeleton, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  loadContractTransaction,
  updatePageContractTransaction,
  updatePageContractTransactionLimit,
} from '../../actions/contractTransaction'
import styled from 'styled-components'
import { toTimeAgo } from '../../utils/utils'
import { DatePicker } from 'antd'
import TruncateMiddle from 'react-truncate-string'
import NumberFormat from 'react-number-format'
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo'

const { RangePicker } = DatePicker

const TranCount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const ContainChooseDate = styled.div`
  display: flex;
  justify-content: flex-end;
  width: -webkit-fill-available;
  position: absolute;
  padding: 9px;
`
const StyledLink = styled(Link)``

const Pagin = styled.div`
  margin-top: 15px;
  text-align: right !important;
`
const BadgeGreen = styled(Badge)`
  .ant-badge-count {
    background-color: #e1f3e0;
    color: black;
  }
`

const BadgeRed = styled(Badge)`
  .ant-badge-count {
    background-color: #c23631 !important;
    color: black;
  }
`

const ChooseDate = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  position: absolute;
  width: -webkit-fill-available;
`

class TransTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: [],
    }
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    var { pageContractTransactions } = this.props

    this.props.loadData(
      this.props.address,
      pageContractTransactions.current_page,
      pageContractTransactions.page_limit,
      this.state.date
    )
  }
  onChange = (pageNumber, pageLimit) => {
    var { pageContractTransactions } = this.props
    if (pageNumber !== pageContractTransactions.current_page) {
      this.props.updatePageContractTransaction(pageNumber)
    }
    if (pageLimit !== pageContractTransactions.page_limit) {
      this.props.updatePageContractTransactionLimit(pageLimit)
    }
    this.props.loadData(
      this.props.address,
      pageContractTransactions.current_page,
      pageLimit,
      this.state.date
    )
  }
  render() {
    var { transactions, status, total, pageContractTransactions } = this.props
    const columns = [
      {
        title: 'Hash',
        key: 'hash',
        dataIndex: 'hash',
        render: (text) => (
          <StyledLink key={text} to={'/transaction/' + text}>
            <Tooltip placement="top" title={text}>
              <TruncateMiddle text={text} />
            </Tooltip>
          </StyledLink>
        ),
        width: '20%',
      },
      {
        title: 'Block',
        dataIndex: 'block_number',
        key: 'block_number',
        render: (text) => (
          <StyledLink key={text} to={'/block/' + text}>
            {text}
          </StyledLink>
        ),
        width: '9%',
      },
      {
        title: 'Age',
        key: 'timestamp',
        dataIndex: 'timestamp',
        render: (text) =>
          text ? <ReactTimeAgo date={new Date(text)} locale="en-US" /> : 'unknown',
        width: '10%',
      },
      {
        title: 'Amount',
        key: 'amount',
        render: (record) => (
          <NumberFormat
            displayType={'text'}
            thousandSeparator={true}
            value={
              record.amount === undefined
                ? '–'
                : record.pow_decimal === undefined
                ? record.amount
                : record.amount / record.pow_decimal
            }
          />
        ),
        width: '20%',
      },
      {
        title: 'From',
        key: 'from_address',
        dataIndex: 'from_address',
        render: (text) =>
          text != undefined ? (
            <StyledLink
              key={text}
              to={'/accounts/' + text}
              onClick={(e) => (text === this.props.address ? e.preventDefault() : '')}
            >
              <Tooltip placement="top" title={text}>
                <TruncateMiddle text={text} />
              </Tooltip>
            </StyledLink>
          ) : (
            <StyledLink
              key={record.contract_address}
              to={'/contract/' + record.contract_address}
              onClick={(e) =>
                record.contract_address === this.props.address ? e.preventDefault() : ''
              }
            >
              <Tooltip placement="top" title={record.contract_address}>
                <TruncateMiddle text={record.contract_address} />
              </Tooltip>
            </StyledLink>
          ),
        width: '15%',
      },
      {
        title: 'To',
        key: 'to_address',
        dataIndex: 'to_address',
        render: (text) =>
          text != undefined ? (
            <StyledLink
              key={text}
              to={'/accounts/' + text}
              onClick={(e) => (text === this.props.address ? e.preventDefault() : '')}
            >
              <Tooltip placement="top" title={text}>
                <TruncateMiddle text={text} />
              </Tooltip>
            </StyledLink>
          ) : (
            <StyledLink
              key={record.contract_address}
              to={'/contract/' + record.contract_address}
              onClick={(e) =>
                record.contract_address === this.props.address ? e.preventDefault() : ''
              }
            >
              <Tooltip placement="top" title={record.contract_address}>
                <TruncateMiddle text={record.contract_address} />
              </Tooltip>
            </StyledLink>
          ),
        width: '15%',
      },
      {
        title: 'Result',
        key: 'ret',
        align: 'center',
        dataIndex: 'ret',
        render: (text) => {
          return text === 'SUCESS' ? <BadgeGreen count="SUCCESS" /> : <BadgeRed count="UNSUCCESS" />
        },
        width: '10%',
      },
    ]

    return (
      <div className="contract-transaction-table">
        <div className="ant-col-sm-24">
          <div className="ant-col-xl-8 ant-col-lg-8 ant-col-md-8 ant-col-sm-24 ant-col-xs-24 contract-transaction-table-total">
            <TranCount>
              <p>
                A Total of {total + ' '}
                Transactions
              </p>
            </TranCount>
          </div>
          <div className="ant-col-xl-16 ant-col-lg-16 ant-col-md-16 ant-col-sm-24 ant-col-xs-24 contract-transaction-table-date">
            <RangePicker
              value={this.state.date}
              onChange={(val) => {
                this.setState({
                  date: val,
                })
                this.props.loadData(this.props.address, 1, pageContractTransactions.page_limit, val)
              }}
            />
          </div>
        </div>

        <Skeleton loading={status != 'success'}>
          <div style={{ paddingBottom: '55px' }}>
            <Table
              columns={columns}
              dataSource={transactions}
              rowKey="hash"
              scroll={{ x: 1300 }}
              sticky
              pagination={false}
              loading={transactions && transactions.length === 0 ? true : false}
              className="account-list"
            />
            <Pagin>
              <Pagination
                current={pageContractTransactions.start_page}
                total={pageContractTransactions.total_items}
                onChange={this.onChange}
                showSizeChanger
                showQuickJumper
                className="account-pagi"
                pageSize={pageContractTransactions.page_limit}
              />
            </Pagin>
          </div>
        </Skeleton>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.contractTransactions.status,
    transactions: state.contractTransactions.result,
    total: state.contractTransactions.total,
    pageContractTransactions: state.pageContractTransactions,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updatePageContractTransaction: (page) => {
      dispatch(updatePageContractTransaction(page))
    },
    updatePageContractTransactionLimit: (limit) => {
      dispatch(updatePageContractTransactionLimit(limit))
    },
    loadData: (address, offset, limit, date) => {
      dispatch(loadContractTransaction(address, offset, limit, date))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TransTable)
