import {
  TOKEN_RECORD_INIT,
  TOKEN_RECORD_UPDATE,
  TOKEN_RECORD_REQUESTING,
  TOKEN_RECORD_FAIL,
  TOKEN_RECORD_SUCCESS,
  TOKEN_RECORD_UPDATE_FAIL,
} from '../actions/tokenRecord'

const initTokenRecord = {
  status: '',
  data: {
    contract_address: '',
    contract_name: '',
    contract_creation_date: 0,
    contract_creator: '',
    token_name: '',
    token_introduction: '',
    token_abbreviation: '',
    token_supply: '',
    token_supply_without_precision: '',
    precision: '',
    issuer: '',
    email: '',
    token_icon: '',
    project_offical_website: '',
    link_for_github: '',
    link_for_white_paper: '',
    social_media_link: {
      twitter: '',
      facebook: '',
      telegram: '',
      weibo: '',
      reddit: '',
      medium: '',
      steem: '',
      instagram: '',
      wechat: '',
      group: '',
      discord: '',
    },
    created_time: 0,
    price_usd: '',
    price_win: '',
    reputation: '',
    token_record_type: '',
    position_win_first: false,
    file_logo: null,
  },
  message: '',
  current_url: 1,
  type_choose: 1,
}
export function tokenRecordReducer(state = initTokenRecord, action) {
  switch (action.type) {
    case TOKEN_RECORD_UPDATE:
      state = {
        ...state,
        status: action.type,
        data: action.payload.data,
        current_url: action.payload.current_url,
        type_choose: action.payload.type_choose,
        message: '',
      }
      break
    case TOKEN_RECORD_INIT:
      state = initTokenRecord
      break
    case TOKEN_RECORD_REQUESTING:
      state = {
        ...state,
        status: action.type,
        message: '',
      }
      break
    case TOKEN_RECORD_SUCCESS:
      state = {
        ...state,
        status: action.type,
        message: 'Record succeeded',
        data: action.payload.data,
        current_url: action.payload.current_url,
        type_choose: action.payload.type_choose,
      }
      break
    case TOKEN_RECORD_FAIL:
      state = {
        ...state,
        status: action.type,
        message: action.payload.message,
        current_url: 4,
        type_choose: action.payload.type_token,
        data: action.payload.data == undefined ? initTokenRecord.data : action.payload.data,
      }
      break
    case TOKEN_RECORD_UPDATE_FAIL:
      state = {
        ...state,
        status: action.type,
        message: action.payload.message,
        current_url: 4,
        type_choose: action.payload.type_token,
        data: action.payload.data == undefined ? initTokenRecord.data : action.payload.data,
      }
      break
    default:
      break
  }
  return state
}
