import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addToStorage, deletePriKey } from '../../actions/prikeyManagement'
import { Row, Col, Table, notification } from 'antd'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { AppstoreAddOutlined } from '@ant-design/icons'
import EditButton from './editButton'
import PageHeader from './../partials/pageHeader'
import { setDefaultPrivateKey } from '../../actions/defaultPrivateKey'
import DeleteButton from './deleteButton'
import AddKeyFormModal from './AddKeyFormModal'
import TruncateMiddle from 'react-truncate-string'
import './prikeyManagement.css'

import { NOEXISTED, PRIKEYEXISTED, PRIKEYNAMEEXISTED } from './constants'
import { urlAtPriv } from '../../actions/checkUrlPriv'

const Wrapper = styled.div`
  margin: 1% 0%;
  text-align: left;
`
const Header = styled.div`
  text-align: left;
  @media (min-width: 280px) {
    font-size: 15px !important;
  }
  @media (min-width: 768px) {
    font-size: 20px !important;
  }
`
const AddIcon = styled.div`
  float: right;
`

class PriKeyManagement extends Component {
  constructor(props) {
    super(props)
    this.state = { current: 1, pageSize: 20, loading: false, visible: false }
  }
  componentDidMount() {
    var { prikeys, login } = this.props
    if (
      (!prikeys.prikeys && login.token !== '') ||
      (prikeys.prikeys.length === 0 && login.token !== '')
    ) {
      notification.warning({
        message: 'Warning!',
        description:
          'You have no private key, please add somes in private key management to perform transaction!',
      })
    }
    this.props.urlAtPrivFunc()
  }

  setDefault = (address, priv) => {
    this.props.setDefaultPrivateKey(this.props.login.email, address, priv)
  }

  _isDefaultPriKeyAddress = (addressA, addressB) => addressA === addressB

  columns = [
    {
      title: 'No.',
      key: 'no',
      render: (_, __, index) => index + 1 + (this.state.current - 1) * this.state.pageSize,
      fixed: 'left',
      width: '5%',
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: '20%',
      render: (name) => (
        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {name}
        </div>
      ),
    },
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address',
      width: '30%',
      render: (address) => <TruncateMiddle text={address} />,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '25%',
      render: (value, item, index) => {
        return (
          <Row>
            <Col>
              <DeleteButton
                index={index + (this.state.current - 1) * this.state.pageSize}
                address={value.address}
                login={this.props.login}
                pageSize={this.state.pageSize}
                setCurrentPage={this.setCurrentPage}
              />
            </Col>
            <Col style={{ marginRight: '10px' }}>
              <EditButton
                ind={index + (this.state.current - 1) * this.state.pageSize}
                val={value}
                email={this.props.login.email}
              />
            </Col>
            <Col style={{ alignSelf: 'center' }}>
              {this._isDefaultPriKeyAddress(this.props.defaultPrivateKey.address, value.address) ? (
                <span>Default</span>
              ) : (
                <Link
                  key="setdefault"
                  onClick={() => this.setDefault(value.address, value.prikey)}
                  to="#"
                >
                  Set default
                </Link>
              )}
            </Col>
          </Row>
        )
      },
    },
  ]

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (values) => {
    var { login, prikeys } = this.props

    let isExisted = NOEXISTED

    const privateKeyList = JSON.parse(localStorage.getItem(login.email)) || []

    this.props.addToStorage(values, login.email)

    if (privateKeyList.length === 0) {
      this.setDefault(values.address, values.prikey)
      this.setState({ visible: false, current: 1 })
    }
    this.setState({ visible: false })
    return isExisted
  }

  setCurrentPage = (current_page) => {
    this.setState({
      current: current_page,
    })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  render() {
    const { visible, loading } = this.state
    var { prikeys } = this.props
    if (!localStorage.getItem('token')) {
      return <Redirect to="/login" />
    }

    return (
      <Wrapper>
        <Header>
          <Row>
            <Col span={20}>
              <PageHeader>PriKey Management</PageHeader>
            </Col>
            <Col span={4}>
              <AddIcon>
                <AppstoreAddOutlined onClick={this.showModal} />
                <AddKeyFormModal
                  handleCancel={this.handleCancel}
                  handleOk={this.handleOk}
                  visible={visible}
                  loading={loading}
                  email={this.props.login.email}
                />
              </AddIcon>
            </Col>
          </Row>
        </Header>
        <Table
          columns={this.columns}
          dataSource={prikeys.prikeys}
          rowKey="prikey"
          scroll={{ x: 680 }}
          sticky
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            total: prikeys.prikeys.length,
            current: this.state.current,
            pageSize: this.state.pageSize,
            onChange: (page, pageSize) => {
              this.setState({ current: page, pageSize })
            },
          }}
        />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    prikeys: state.prikeyManagement,
    login: state.login,
    defaultPrivateKey: state.defaultPrivateKey,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    addToStorage: (prikey, email) => {
      dispatch(addToStorage(prikey, email))
    },
    deletePriKey: (index, email) => {
      dispatch(deletePriKey(index, email))
    },
    setDefaultPrivateKey: (email, address, priv) => {
      dispatch(setDefaultPrivateKey(email, address, priv))
    },
    urlAtPrivFunc: () => {
      dispatch(urlAtPriv())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  PriKeyManagement
)
