import React from 'react'
import { Table, Tag, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadWitnesses } from '../../actions/witnesses'
import styled from 'styled-components'
import PageHeader from '../partials/pageHeader'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import TruncateMiddle from 'react-truncate-string'

const StyleOutLink = styled.a``
const StyledLink = styled(Link)``
const Wrapper = styled.div``

class WitnessTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = { current: 1, pageSize: 20 }
  }

  componentDidMount() {
    this.props.loadWitnesses()
    this.props.urlAtNotPrivFunc()
  }

  columns = [
    {
      title: 'No.',
      key: 'no',
      render: (_, __, index) => index + 1 + (this.state.current - 1) * this.state.pageSize,
      width: 60,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '65px',
      render: (text) => (
        <StyledLink key={text} to={`/accounts/${text}`}>
          <Tooltip placement="top" title={text}>
            <TruncateMiddle text={text.substring(0, 5) + '...' + text.substring(29, text.length)} />
          </Tooltip>
        </StyledLink>
      ),
    },
    {
      title: 'Vote Count',
      dataIndex: 'vote_count',
      key: 'vote_count',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: (text) => (
        <StyleOutLink key={text} href={text}>
          {text}
        </StyleOutLink>
      ),
    },
    {
      title: 'Total Produced',
      key: 'total_produced',
      dataIndex: 'total_produced',
      width: 100,
    },
    {
      title: 'Total Missed',
      key: 'total_missed',
      dataIndex: 'total_missed',
      width: 80,
      render: (text) => <span>{text ? text : 0}</span>,
    },
    {
      title: 'Lastest block num',
      key: 'lastest_block_num',
      dataIndex: 'lastest_block_num',
      width: 110,
      render: (text) => (
        <StyledLink key={text} to={`/block/${text}`}>
          {text}
        </StyledLink>
      ),
    },
    {
      title: 'Lastest Slot Num',
      key: 'lastest_slot_num',
      dataIndex: 'lastest_slot_num',
    },
    {
      title: 'Running',
      key: 'is_jobs',
      dataIndex: 'is_jobs',
      width: 90,
      render: (text) => (
        <Tag color={text ? 'green' : 'red'}>{text ? 'RUNNING' : 'NOT RUNNING'}</Tag>
      ),
    },
  ]

  render() {
    const witnesses = this.props.witnesses
    return (
      <Wrapper>
        <PageHeader>List of Witness</PageHeader>
        <Table
          columns={this.columns}
          dataSource={witnesses}
          rowKey="address"
          scroll={{ x: 1000 }}
          loading={witnesses !== null ? (witnesses.length === 0 ? true : false) : false}
          locale={{ emptyText: 'Loading' }}
          pagination={{
            current: this.state.current,
            pageSize: this.state.pageSize,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              this.setState({ current: page, pageSize })
            },
          }}
        />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    witnesses: state.witnesses,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadWitnesses: () => {
      dispatch(loadWitnesses())
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  WitnessTable
)
