import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Skeleton } from 'antd'
import { Redirect } from 'react-router'
import { urlAtNotPriv } from '../../actions/checkUrlPriv'
import {
  getTokenRecord,
  TOKEN_RECORD_FAIL,
  TOKEN_RECORD_REQUESTING,
} from '../../actions/tokenRecord'
import { Table, BorderRed, Div, FontFamily, Title, TableRow, Th } from './style'

import Overview from './overview'
import More from './more'
import MenuTab from './menu'
import Header from './header'
import SwitchMenu from './switchMenu'
import PageHeader from '../partials/pageHeader'
import CardShadow from '../partials/cardShadow'
import EmptySpace from '../partials/emptySpace'
import { loadContractApi } from '../../actions/contract'
import { API_ADDR } from '../../config/config'

class TokenRecordRC20 extends Component {
  constructor(props) {
    super(props)
    this.inputFile = React.createRef()
    this.state = {
      contract_address: undefined,
    }
  }
  async componentDidMount() {
    this.props.urlAtNotPrivFunc()
    var id = this.props.match.params.id
    this.props.getTokenRecord(id)
    this.props.loadContract(id)
  }

  render() {
    var { tokenRecord } = this.props

    return (
      <Skeleton loading={tokenRecord.status === TOKEN_RECORD_REQUESTING}>
        {tokenRecord.status !== TOKEN_RECORD_REQUESTING ? (
          tokenRecord.status == TOKEN_RECORD_FAIL ? (
            this.props.match.params.id.length == 34 ? (
              <Redirect to={`/contract/${this.props.match.params.id}`} />
            ) : (
              <Redirect to={`/token/${this.props.match.params.id}`} />
            )
          ) : (
            <>
              <Header id={this.props.match.params.id} />
              <CardShadow className="contract-detail-content">
                <Div className="contract-detail-general">
                  <Col className="ant-col-xl-11 ant-col-lg-11 ant-col-md-12 ant-col-sm-24 ant-col-xs-24">
                    <Title className="contract-detail-general-title">Overview</Title>
                    <Table>
                      <Overview />
                    </Table>
                  </Col>
                  <Col className="ant-col-xl-11 ant-col-lg-11 ant-col-md-12 ant-col-sm-24 ant-col-xs-24">
                    <Title className="contract-detail-general-title">More</Title>
                    <Table>
                      <More />
                    </Table>
                  </Col>
                </Div>
              </CardShadow>
              <EmptySpace />
              <CardShadow>
                <MenuTab address={this.props.match.params.id} />
                <SwitchMenu
                  address={this.props.match.params.id}
                  totalSupply={tokenRecord.data.token_supply_without_precision}
                />
              </CardShadow>
              <EmptySpace />
            </>
          )
        ) : (
          <></>
        )}
      </Skeleton>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tokenRecord: state.tokenRecord,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getTokenRecord: (contract_addr) => {
      dispatch(getTokenRecord(contract_addr))
    },
    loadContract: (addr) => {
      dispatch(loadContractApi(addr))
    },
    urlAtNotPrivFunc: () => {
      dispatch(urlAtNotPriv())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  TokenRecordRC20
)
