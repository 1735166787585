import { TRANSACTION_DETAIL_INIT, TRANSACTION_DETAIL_UDPATE } from '../actions/transaction'

const initTran = {
  hash: '',
  blockNum: 0,
  contractAddr: '',
  result: '',
  timestamp: 0,
  numOfBlocks: 0,
  confirmed: '',
  contract: {
    type: '',
    parameter: {
      type_url: '',
      value: '',
      raw: {},
    },
  },
  fee_limit: 0,
  loading: true,
}
export function transactionReducer(state = initTran, action) {
  switch (action.type) {
    case TRANSACTION_DETAIL_UDPATE:
      return action.payload
    case TRANSACTION_DETAIL_INIT:
      return initTran
    default:
      return state
  }
}
