// import { LineProgressBar } from '@frogress/line'
import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import {
  Table,
  BorderRed,
  Div,
  DivContain,
  FontFamily,
  TableRow,
  Th,
  Td,
  Span,
  Remaining,
  Used,
} from './style'
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import './style.css'

class AddressEnergy extends Component {
  render() {
    let { energytotal, energyused } = this.props

    return (
      <DivContain>
        <Span>
          Energy
          <Remaining>
            Remaining:
            <Used>
              <NumberFormat
                displayType={'text'}
                thousandSeparator={true}
                value={energytotal - energyused}
              />
            </Used>
            /
            <NumberFormat displayType={'text'} thousandSeparator={true} value={energytotal} />
          </Remaining>
        </Span>
        <Progress
          status="success"
          theme={{
            success: {
              symbol: '‍',
              color: '#FCB040',
            },
          }}
          percent={energytotal ? ((energytotal - energyused) / energytotal) * 100 : 0}
        />
        {/* <LineProgressBar
          percent={energytotal ? ((energytotal - energyused) / energytotal) * 100 : 0}
          progressColor="linear-gradient(to right, #78abe9, #74dad8, #ec7cac)"
        /> */}
      </DivContain>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    energytotal: state.address.energy_total === undefined ? 0 : state.address.energy_total,
    energyused: state.address.energy_used === undefined ? 0 : state.address.energy_used,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AddressEnergy)
