import React from 'react'
import styled from 'styled-components'
import { Row, Col, Grid, Divider } from 'antd'
import FooterLink from './footerLink'

const Copyright = styled.p`
  text-align: center;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.56);
  margin-bottom: 25px;
`

const FooterWrapper = styled.footer`
  background-color: black;
`

const FooterDivider = styled(Divider)`
  border-color: rgba(255, 255, 255, 0.1);
`

const generalLinks = [
  {
    href: '/',
    anchor: 'About',
  },
  {
    href: '/',
    anchor: 'Partner',
  },
  {
    href: '/',
    anchor: 'Ecosystem',
  },
]

const developerLinks = [
  {
    href: '/',
    anchor: 'Whitepaper',
  },
  {
    href: '/',
    anchor: 'Roadmap',
  },
  {
    href: '/',
    anchor: 'Token',
  },
]

const communityLinks = [
  {
    href: '/',
    anchor: 'News',
  },
  {
    href: '/',
    anchor: 'Announcement',
  },
  {
    href: '/',
    anchor: 'Media',
  },
]

const supportLinks = [
  {
    href: '/',
    anchor: 'Contact us',
  },
  {
    href: '/',
    anchor: 'FAQ',
  },
]

export default function FooterComponent() {
  const { md } = Grid.useBreakpoint()

  return (
    <FooterWrapper>
      <Row>
        <Divider dashed />
      </Row>
      <Row justify="center" gutter={[0, 0]}>
        <Col xs={20} sm={20} md={20} lg={19} xl={18}>
          <Row>
            <Col span={12} lg={6}>
              <FooterLink title="General" links={generalLinks} />
            </Col>
            <Col span={12} lg={6}>
              <FooterLink title="Developer" links={developerLinks} />
            </Col>
            <Col span={12} lg={6}>
              <FooterLink title="Community" links={communityLinks} />
            </Col>
            <Col span={12} lg={6}>
              <FooterLink title="Support" links={supportLinks} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <FooterDivider />
      </Row>
      <Row justify="center">
        <Col xs={20} sm={20} md={20} lg={19} xl={18}>
          <Copyright>
            Copyright © All right reserved 2022. Comartek – Blockchain for Digital Identity
          </Copyright>
        </Col>
      </Row>
    </FooterWrapper>
  )
}
